import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Footer_SPR_SPH_V10 = () => {
  return (
    <div>
      <footer className="py-4 text-center">
        <div className="container">
          <div className="offset-lg-1 col-lg-10">
            <div className="row mb-3">
              <div className=" py-2">
                <p>
                  No estamos afiliados ni respaldados por ninguna entidad gubernamental. CMS no ha revisado ni aprobado esta información. Los beneficios pueden estar limitados a planes y regiones específicas. Los beneficios enumerados se ofrecen como parte de algunos Planes de Salud Seguro para Latinos y Planes de Medicare Advantage, la inscripción reemplazará su cobertura de salud actual. No hay obligación de inscribirse.
                </p>
              </div>
              <div className=" py-2">
                <p>
                  *No todos los planes o beneficios están disponibles en todas las áreas. Este beneficio solo está disponible para solicitantes elegibles en los siguientes estados: AL, AZ, FL, GA, MS, NC, SC, TX, UT, VA. No todos los que llaman de estos estados calificarán para el beneficio indicado.
                </p>
              </div>
              <div>
                <p>3245 SW 122ND AVE APT 103 MIRAMAR, FL 33025</p>
              </div>
              <div>
                <p>Javier Eduardo Polo</p>
              </div>
              <div>
                <p>
                  © 2013-2022 |{" "}
                  <Link target="_blank" to="Política-de-Privacidad"> Política de Privacidad</Link>{" "}
                  |{" "}
                  <Link target="_blank" to="Terminos-de-Uso">Términos de Uso</Link>{" "}
                  |{" "}
                  <Link target="_blank" to="Política-de-Cookies" >Política de Cookies</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer_SPR_SPH_V10