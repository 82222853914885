export const questions = [
  {
    questionId: 8,
    question: "Ya tienes un Seguro Medico, Medicare o Medicaid?",
    answers: [
      {
        label: "No",
        value: 17,
      },
      {
        label: "Si",
        value: 16,
        modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas. Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
      }
    ]
  },
  {
    questionId: 10,
    question: "Tienes un permiso para trabajar en EE.UU?",
    answers: [
      {
        label: "Si",
        value: 24,
      },
      {
        label: "No",
        value: 23
      }
    ]
  },
  {
    questionId: 15,
    question: "¿Cuál de los siguientes tienes?",
    answers: [
      {
        label: "Medicare",
        value: 30,
        modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas. Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
      },
      {
        label: "Medicaid",
        value: 31,
        modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas. Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
      },
      {
        label: "Medicare & Medicaid",
        value: 32,
        modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas. Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
      },
      {
        label: "VA",
        value: 33,
        modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas. Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
      }
    ]
  }
];