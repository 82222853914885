import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import head from "../../assets/img/SPR_V2/bg-pre2.jpg";
import {
  questinnare1,
  questinnare2,
  questinnare3,
  questinnare4,
} from "../../Constants/QuestionsSpanish";
import QuestionnaireRadioSlideSpanish from "../Includes/Layouts/Questionnaire/SPR_V2/QuestionnaireRadioSlideSpanish";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import useJourneyStats from "../../Hooks/useJourneyStats";
import { useGetState } from "../../Hooks/useGetState";

const FormSplitSPR_V2 = (props) => {
  const { register, errors, trigger, getValues } =
    useForm({ 
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const initialState = {
    showQuestionSlide1: "d-block",
    showQuestionSlide2: "d-none",
    showQuestionSlide3: "d-none",
    showQuestionSlide4: "d-none",
  };
  const QuestionReducer = (state, action) => {
    switch (action.type) {
      case "nextQuestionSlide": {
        if (action.payload.clickedSlide.slide === "question_1") {
          return {
            ...state,
            showQuestionSlide1: "d-none",
            showQuestionSlide2: "d-block",
          };
        } else if (action.payload.clickedSlide.slide === "question_4") {
          return {
            ...state,
            showQuestionSlide2: "d-none",
            showQuestionSlide3: "d-block",
          };
        } else if (action.payload.clickedSlide.slide === "question_2") {
          return {
            ...state,
            showQuestionSlide3: "d-none",
            showQuestionSlide4: "d-block",
          };
        } else if (action.payload.clickedSlide.slide === "question_3") {
          return {
            ...state,

            showQuestionSlide4: "d-none",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case "backQuestionSlide": {
        if (action.payload.prevSlide.slide == "back4") {
          return {
            ...state,
            showQuestionSlide1: "d-block",
            showQuestionSlide2: "d-none",
          };
        } else if (action.payload.prevSlide.slide == "back2") {
          return {
            ...state,
            showQuestionSlide3: "d-none",
            showQuestionSlide2: "d-block",
          };
        } else if (action.payload.prevSlide.slide == "back3") {
          return {
            ...state,
            showQuestionSlide4: "d-none",
            showQuestionSlide3: "d-block",
          };
        } else {
          return {
            ...state,
          };
        }
      }
    }
  };

  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState();
  const [prevSlide, setPrevSlide] = useState();
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion } = useDataIngestion();
  const splitForm = useRef(null);
  const saveJournyStats = useJourneyStats();
  const query = new URLSearchParams(window.location.search);
  const atp_source = (query.get("atp_source")) ? query.get("atp_source") : '';
  const uuid = (query.get("uuid")) ? query.get("uuid") : '';
  const facebook_cid = (query.get("facebook_cid")) ? query.get("facebook_cid") : '';
  const crvid =(query.get("crvid")) ? query.get("crvid") : '' ;
  const utm_campaign = (query.get("utm_campaign")) ? query.get("utm_campaign") : '' ;
  const locUUID = localStorage.getItem("uuid");
  const {getUserState} = useGetState();
  const [getState,setGetstate] = useState();
  
  
  let user_state = "";
  useEffect(
    ()=>{
     ( async()=>{
    const response =   await getUserState( user_state );
    user_state = response.data.state_name;
    setGetstate(user_state);
      })()
  },[]);


  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (state.showQuestionSlide1 == "d-block") {
      if (locUUID) {
        (async () => {
          await saveJournyStats(
            uuid,
            "question",
            "1",
            "",
            "view",
            props.splitName
          );
        })();
      }
    }
  }, [locUUID]);

  useEffect(() => {
    if (clickedSlide) {
      if (clickedSlide.slide == "question_1") {
        (async () => {
          await saveJournyStats(
            uuid,
            "question",
            "4",
            "",
            "view",
            props.splitName
          );
        })();
      } else if (clickedSlide.slide == "question_4") {
        (async () => {
          await saveJournyStats(
            uuid,
            "question",
            "2",
            "",
            "view",
            props.splitName
          );
        })();
      } else if (clickedSlide.slide == "question_2") {
        (async () => {
          await saveJournyStats(
            uuid,
            "question",
            "3",
            "",
            "view",
            props.splitName
          );
        })();
      } else if (clickedSlide.slide == "question_3") {
        (async () => {
          await saveJournyStats(
            uuid, 
            "button",
            "call_click",
            "",
            "view",
            props.splitName
            );
        })();
      }
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (prevSlide) {
      dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
    }
  }, [prevSlide]);

  const saveJournyfun=async(e, radioName)=>{
    // const form = splitForm.current;
    let radio_id = e.target.dataset.question_id;
    var option_id = e.target.value;
    if (radioName == "question_1" || radioName == "question_2" || radioName == "question_3"){
        // setClickButton("click");
         await saveJournyStats(
           uuid,
           "question",
           radio_id,
           option_id,
           "click",
           props.splitName
          );
       }
}
  const slideChange = async (e, radioName) => {
    const form = splitForm.current;
    const values = getValues();
    setClickedSlide({ slide: radioName });
    let question_id = radioName;
    let optionId = form[radioName].value;
    let radio_id = e.target.dataset.question_id;
    var option_id = e.target.value;
    var existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (existingEntries == null) existingEntries = {};
    var newEntries =
      existingEntries == null ? (existingEntries = {}) : existingEntries;
    Object.assign(newEntries, {
      [question_id]: optionId,
    });
    localStorage.setItem("questionData", JSON.stringify(newEntries));

    if (
      radioName == "question_1" ||
      radioName == "question_2" ||
      radioName == "question_3" ||
      radioName == "question_4"
    ) {
      // setClickButton("click");
      await saveJournyStats(
        uuid,
        "question",
        radio_id,
        option_id,
        "click",
        props.splitName
      );
    }

    if (radioName == "question_3") {
      const formData = values;
      formData.lstSalutation = "";
      formData.txtFName = "";
      formData.txtLName = "";
      formData.txtEmail = "";
      formData.txtPhone = "";
      formData.page_name = "SPR_V2";
      formSubmit(formData);
    }
  };
  const previousSlide = (e) => {
    var previous_slide = e.target.id;
    setPrevSlide({ slide: previous_slide });
  };

  const formSubmit = async (formData) => {
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      "split_form_submit",
      "SPR_V2",
      queryString,
      visitorParameters.data
    );
    if (formSUbmitResult.data.status === "Success") {
      history.push(
        "/thank-you?atp_source=" + atp_source +"&facebook_cid="+ facebook_cid +"&crvid="+ crvid +"&uuid="+ visitorParameters.visitor_parameters.uuid +"&utm_campaign="+utm_campaign
      );
    }
  };

  return (
    <div>
      <form className="QuesFill_sec" ref={splitForm}>
        <div className="OXen_blxi text-center">
          <div className="main_contSec">
            <h1>
              Latinos en {getState} pueden reclamar MILES de
              dólares para el costoso cuidado médico y utilizar los ahorros en
              necesidades cotidianas. ¡Comprueba en 2 minutos abajo!
            </h1>
            <div>
              <img src={head} className="image-fluid" alt="" />
            </div>
            <p className="txt_block">
              Los latinos sin seguro médico pueden reclamar Créditos Fiscales
              del Gobierno esta semana para pagar el costoso cuidado médico.
              ¡Pueden utilizar los ahorros para necesidades como comestibles,
              gasolina y alquiler! Es fácil y completamente gratuito verificar
              la elegibilidad. Precalifica respondiendo algunas preguntas
              rápidas a continuación y luego habla con un experto para reclamar
              tus beneficios!
            </p>
            {/* ============= SLIDE 1 ================= */}
            <QuestionnaireRadioSlideSpanish
              showQuestionSlide={state.showQuestionSlide1}
              questionnaire={questinnare1}
              previousSlide={previousSlide}
              slideChange={slideChange}
              backBtn={false}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />
            {/* ============= SLIDE 1 END ================= */}
            {/* ============= SLIDE 2 ================= */}

            <QuestionnaireRadioSlideSpanish
              showQuestionSlide={state.showQuestionSlide2}
              questionnaire={questinnare4}
              previousSlide={previousSlide}
              slideChange={slideChange}
              backBtn={false}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />

            {/* ============= SLIDE 2 END ================= */}
            {/* ============= SLIDE 3 ================= */}
            <QuestionnaireRadioSlideSpanish
              showQuestionSlide={state.showQuestionSlide3}
              questionnaire={questinnare2}
              previousSlide={previousSlide}
              slideChange={slideChange}
              backBtn={false}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              setModalBody={props.setModalBody}
              saveJournyfun={saveJournyfun}
            />

            {/* Modal */}
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">Lo siento, pero no calificas</div>
                  <div className="modal-footer text-center justify-content-center">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Atrás
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ============= SLIDE 3 END ================= */}
            {/* ============= SLIDE 4 ================= */}
            <QuestionnaireRadioSlideSpanish
              showQuestionSlide={state.showQuestionSlide4}
              questionnaire={questinnare3}
              previousSlide={previousSlide}
              slideChange={slideChange}
              backBtn={false}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />
            {/* ============= SLIDE 4 END ================= */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormSplitSPR_V2;
