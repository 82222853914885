import React, { useEffect } from 'react'

import arrow from "../../../assets/img/SPR_WA_V11/arrow.png";
import trustpilot from "../../../assets/img/SPR_WA_V11/trustpilot.png";
import checked from "../../../assets/img/SPR_WA_V11/checked.png";
import "../../../../src/assets/css/SPR_WA_V11/chat.scss";
import Header from '../../Includes/Layouts/SPR_WA_V11/Header';
import Footer from '../../Includes/Layouts/SPR_WA_V11/Footer';

import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import RingbaApi from "../../../Utility/RingbaApi";
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import FbViewPixel from '../../../Utility/FbViewPixel';
import useJourneyStats from '../../../Hooks/useJourneyStats';
import { useUserClicks } from '../../../Hooks/useUserClicks';
import Accordion_V10_v11 from '../../Includes/Layouts/Common/Accordion_V10_v11';

const SPR_WA_V11 = () => {
  const splitName = 'SPR_WA_V11';
  const saveJournyStats = useJourneyStats();
  const { saveUserClicks } = useUserClicks();
  const campaign_name = "spanish";
  // const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const uuid = query.get("uuid");

  useEffect(() => {
    if (uuid) {
      (async () => {
        await saveJournyStats(uuid, 'subsidy_button', 'Claim_Subsidy', '', 'view', splitName);
      })();
      (async () => {
        await saveJournyStats(uuid, 'button', 'CALL_CLICK', '', 'view', splitName);
      })();
    }
  }, [uuid]);

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const handleclick = () => {
    journeyStatus({
      slide_type: 'subsidy_button',
      slide_question: 'Claim_Subsidy',
      slide_value: '',
      type: 'click',
    });
    window.location.href = 'https://wa.me/12059842649?text=Hola';
  }

  return (
    <>

      <div className="SPR_WA_V11">
        <div className="__SEGUROhome">
          <AdtopiaLoadLP pageType="LP" splitName="SPR_WA_V11" />
          <Header
            // response={response}
            utmName={utmName}
            journeyStatus={journeyStatus}
            saveUser={saveUser}
            uuid={uuid}
          />
          <FbViewPixel />
          <section className="cs_land_paGe">
            <div className="container-fluid p-0">
              <div className="bg_Br_vw">
                <div className="row tke_txT_Dwn">
                  <div className="col-lg-8 col-md-8 col-12 txt_notice">
                    <div className="content">
                      <h2 className="lts_put_HeD">
                        ¿Ya ha obtenido su subsidio de $7200 para cubrir los costos del seguro de salud para el 2024?
                      </h2>
                      <p className="sub_bttm">
                        Muchos latinos que viven en Estados Unidos no se dan cuenta de que pueden calificar para una cobertura de salud de $0 al mes, incluso si aún no han obtenido su ciudadanía. Únete a más de 35 millones de estadounidenses que están reclamando este beneficio en el 2024.
                      </p>
                      <div className="dont_frgt_BTn">
                        <button
                          id="myButton"
                          className="btn button btn_trnOn efct_filed"
                          onClick={handleclick}
                        >
                          Reclamar Subsidio <img src={arrow} alt="" />
                        </button>
                      </div>
                    </div>
                    <div className="nite_kTm_hv">
                      <img src={trustpilot} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-12 ">
                    <div className="hr_tips_menu px-3">
                      Podría ser elegible para:
                    </div>
                  </div>
                  <div className="col-lg-9 col-12 pt-4 pb-4">
                    <ul className="pnts_By_PntS px-3">
                      <li className="pin_Here">
                        <img src={checked} alt="" />
                        <p>
                          <span>Atención primaria</span>
                          <br />
                          <span>ilimitada por $0</span>
                        </p>
                      </li>
                      <li className="pin_Here">
                        <img src={checked} alt="" />
                        <p>
                          <span>Atención urgente virtual</span>
                          <br />
                          <span>ilimitada por $0</span>
                        </p>
                      </li>
                      <li className="pin_Here">
                        <img src={checked} alt="" />
                        <p>
                          <span>Recetas médicas por</span>
                          <br />
                          <span>$3 en Walgreens</span>
                        </p>
                      </li>
                      <li className="pin_Here">
                        <img src={checked} alt="" />
                        <p>
                          <span>Gane recompensas por cuidar</span>
                          <br />
                          <span>de su salud, hasta $100.</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Accordion_V10_v11 />
          <Footer />
        </div>
        <div className="loader_full_Pg" style={{ display: "none" }}>
          <div id="wifi-loader">
            <svg className="circle-outer" viewBox="0 0 86 86">
              <circle className="back" cx={43} cy={43} r={40} />
              <circle className="front" cx={43} cy={43} r={40} />
              <circle className="new" cx={43} cy={43} r={40} />
            </svg>
            <svg className="circle-middle" viewBox="0 0 60 60">
              <circle className="back" cx={30} cy={30} r={27} />
              <circle className="front" cx={30} cy={30} r={27} />
            </svg>
            <svg className="circle-inner" viewBox="0 0 34 34">
              <circle className="back" cx={17} cy={17} r={14} />
              <circle className="front" cx={17} cy={17} r={14} />
            </svg>
            <div
              className="text"
              data-text="Comprobando la disponibilidad del agente..."
              style={{ display: "block" }}
            />
            <div
              className="text_2"
              data-text="Vamos a Ver si Calificas"
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </>

  )
}

export default SPR_WA_V11