  const questinnare1   = {
      questionId:1,
      question : "¿Quieres ver si calificas para obtener hasta $6,480 en subsidios fiscales?",
      answers: [
              {
                  label: "Sí",
                  value: "1"
              },
              {
                  label: "No",
                  value: "2",
                  
              }
      ]
    };
    const questinnare2   = {
      questionId:2,
      question : "¿Ya tienes seguro medico, Medicare o Medicaid?",
      answers: [
              {
                  label: "No",
                  value: "3"
              },
              {
                label: "Sí",
                value: "4",
                modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
            },
      ]
    };
    const questinnare3   = {
      questionId:3,
      question : "Tienes un trabajo?",
      answers: [
              {
                  label: "Sí",
                  value: "5"
              },
              {
                  label: "No",
                  value: "6"
              }
      ]
    };

    const questinnare4   = {
      questionId:4,
      question : "Ya tienes seguro medico?",
      answers: [
              {
                  label: "Sí",
                  value: "7",
                 
              },
              {
                  label: "No",
                  value: "8"
              }
      ]
    };

    const questinnare5   = {
      questionId:10,
      question : "¿Tienes Green Card, eres Ciudano, eres Residente, o tienes un Permiso para Trabajar en EE.UU?",
      answers: [
              {
                  label: "Sí",
                  value: "22"
              },
              {
                  label: "No",
                  value: "23"
              }
      ]
    };
   

    export { 
      questinnare1,
      questinnare2,
      questinnare3,
      questinnare4,
      questinnare5
    };
    