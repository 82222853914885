import React, { useState, useContext, useEffect } from "react";
import Footer from "../../Includes/Layouts/SPR_V10/Footer";
import ChatBotBubble_SPR_V10 from "./ChatBotBubble_SPR_V10";
import Landing_page from "./Landing_page";
import PageLoader from "./PageLoader";
import "../../../../src/assets/css/SPR_V10/chat.scss";

import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from "../../../Utility/UUID";
import RingbaApi from "../../../Utility/RingbaApi";

import OfflineSpanishButtonLink from "../../../Utility/OfflineSpanishButtonLink";
import RingbaPhoneNumberSpanish from "../../../Utility/RingbaPhoneNumberSpanish";

const SPR_V10 = () => {
  const campaign_name = "spanish";
  const splitName = 'SPR_V10';

  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const [buttonRedirect, setButtonRedirect] = useState();
  let is_online = response.is_online;
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName);

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  const [pageJourney, setPageJourney] = useState(0)
  useEffect(() => {
    const pageName = query.get("currentPage");
    if (pageName === "chatbot") {
      setPageJourney(3)
    } else {
      setPageJourney(1)
    }
  }, []);

  return (
    <>
      <AdtopiaLoadLP PageType="LP" splitName={splitName} />
      <FbViewPixel />
      <div className="SPR_V10">
        {pageJourney === 1 &&
          <Landing_page
            setPageJourney={setPageJourney}
            splitName={splitName}
            journeyStatus={journeyStatus}
            saveUser={saveUser}
            uuid={uuid}
            response={response}
            pageJourney={pageJourney}

            is_online={is_online}
            buttonRedirect={buttonRedirect}
            phoneNumber={phoneNumber}
            formattedPhoneNumber={formattedPhoneNumber}
          />
        }
        {pageJourney === 2 &&
          <PageLoader
            setPageJourney={setPageJourney}
          />
        }
        {pageJourney === 3 &&
          <>
            <div className="body-inner">
              <section className="chat-section">
                <div className="container">
                  <div className=" mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                    <div className="chat-wrap">
                      <div className="mb-1 support-online py-3 text-center">
                        <span className="circle pulse green me-1" /> Veronica esta
                        disponible
                      </div>
                      <div className="chat-box-inner">
                        <div className="row-chart-message mb-4 ">
                          <ChatBotBubble_SPR_V10
                            splitName={splitName}
                            formSubmit={formSubmit}
                            journeyStatus={journeyStatus}
                            saveUser={saveUser}
                            uuid={uuid}

                            is_online={is_online}
                            buttonRedirect={buttonRedirect}
                            phoneNumber={phoneNumber}
                            formattedPhoneNumber={formattedPhoneNumber}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        }
        {pageJourney === 1 || pageJourney === 3 ?
          <Footer /> : null
        }
      </div>
    </>
  );
};

export default SPR_V10;
