import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import NotFound from "./Components/Pages/NotFound";
import Privacy_policy from "./Components/Includes/Layouts/Common/Privacy_policy";
import Terms_of_use from "./Components/Includes/Layouts/Common/Terms_of_use";
import Cookies_policy from "./Components/Includes/Layouts/Common/Cookies_policy";
import ACA_SPH_V3 from "./Components/Pages/ACA_SPH_V3/ACA_SPH_V3";
import SPR_V1 from "./Components/Pages/SPR_V1/SPR_V1";
import SPR_V3 from "./Components/Pages/SPR_V3/SPR_V3";
import SPR_V2 from "./Components/Pages/SPR_V2/SPR_V2";
import SPR_V2_Thankyou from "./Components/Pages/SPR_V2/SPR_V2_Thankyou";
import SPR_V4 from "./Components/Pages/SPR_V4/SPR_V4";
import AdtopiaLoadValue from "./Components/Pages/AdtopiaLoadValue";
import SPR_V5 from "./Components/Pages/SPR_V5/SPR_V5";
import SPR_V5MVT from "./Components/Pages/SPR_V5MVT/SPR_V5MVT";
import SPR_V5_1 from "./Components/Pages/SPR_V5_1/SPR_V5_1";
import SPR_V6B_MS from "./Components/Pages/SPR_V6B_MS/SPR_V6B_MS";
import SPR_V7 from "./Components/Pages/SPR_V7/SPR_V7";
import SPR_V8_1 from "./Components/Pages/SPR_V8_1/SPR_V8_1";
import SPR_V8_4 from "./Components/Pages/SPR_V8_4/SPR_V8_4";
import SPR_V8_4B from "./Components/Pages/SPR_V8_4B/SPR_V8_4B";
import SPR_V8_2 from "./Components/Pages/SPR_V8_2/SPR_V8_2";
import SPR_V8_3 from "./Components/Pages/SPR_V8_3/SPR_V8_3";
import SPR_V8_5 from "./Components/Pages/SPR_V8_5/SPR_V8_5";
import SPR_V8_6 from "./Components/Pages/SPR_V8_6/SPR_V8_6";
import SPR_V8_7 from "./Components/Pages/SPR_V8_7/SPR_V8_7";
import SPR_V8_8 from "./Components/Pages/SPR_V8_8/SPR_V8_8";
import SPR_V8_9 from "./Components/Pages/SPR_V8_9/SPR_V8_9";
import SPR_V5B from "./Components/Pages/SPR_V5B/SPR_V5B";
import SPR_V8_9B from "./Components/Pages/SPR_V8_9B/SPR_V8_9B";
import SPR_V8_4C from "./Components/Pages/SPR_V8_4C/SPR_V8_4C";
import SPR_FF_V2_1 from "./Components/Pages/SPR_FF_V2_1/SPR_FF_V2_1";
import SPR_V8_42 from "./Components/Pages/SPR_V8_42/SPR_V8_42";
import SPR_VD_V9 from "./Components/Pages/SPR_VD_V9/SPR_VD_V9";
import SPR_WA_V11 from "./Components/Pages/SPR_WA_V11/SPR_WA_V11";
import SPR_V10 from "./Components/Pages/SPR_V10/SPR_V10";
import SPR_FB_V_8_4_1 from "./Components/Pages/SPR_FB_V_8_4_1/SPR_FB_V_8_4_1";
import SPR_VD_V9_1 from "./Components/Pages/SPR_VD_V9_1/SPR_VD_V9_1";
import Terminos_de_Uso from "./Components/Includes/Layouts/Common/Terminos_de_Uso";
import Política_de_Privacidad from "./Components/Includes/Layouts/Common/Política_de_Privacidad";
import Política_de_Cookies from "./Components/Includes/Layouts/Common/Política_de_Cookies";
import SPR_VD_V9B from "./Components/Pages/SPR_VD_V9B/SPR_VD_V9B";
import SPR_VD_FBV9B_1 from "./Components/Pages/SPR_VD_FBV9B_1/SPR_VD_FBV9B_1";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import SSPR_ADV1 from "./Components/Pages/Advertorials/SSPR_ADV1";
import SPR_V8_S41 from "./Components/Pages/SPR_V8_S41/SPR_V8_S41";
import MedicareThankYou from "./Components/Pages/MedicareThankYou";
import GreencardThankyou from "./Components/Pages/GreencardThankyou";
import SSPR_ADV2 from "./Components/Pages/Advertorials/SSPR_ADV2";
import SPR_SPH_V10 from "./Components/Pages/SPR_SPH_V10/SPR_SPH_V10";
import SPR_V9 from "./Components/Pages/SPR_V9/SPR_V9";
import MedicareThankYouV2 from "./Components/Pages/MedicareThankYouV2";
import SPR_SPH_V11 from "./Components/Pages/SPR_SPH_V11/SPR_SPH_V11";
import SPR_SPH_V7_1 from "./Components/Pages/SPR_SPH_V7_1/SPR_SPH_V7_1";


const App = () => {

  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/SPR_V1"]} component={HomePage} />
                <Route exact path={["/ACA_SPH_V3"]} component={ACA_SPH_V3} />
                <Route exact path={["/Privacy-policy"]} component={Privacy_policy} />
                <Route exact path={["/Política-de-Privacidad"]} component={Política_de_Privacidad} />
                <Route exact path={["/Terms-of-use"]} component={Terms_of_use} />
                <Route exact path={["/Cookies-policy"]} component={Cookies_policy} />
                <Route exact path={["/Política-de-Cookies"]} component={Política_de_Cookies} />
                <Route exact path={["/Terminos-de-Uso"]} component={Terminos_de_Uso} />
                <Route exact path={["/SPR_V2"]} component={SPR_V2} />
                <Route exact path={["/thank-you"]} component={SPR_V2_Thankyou} />
                <Route exact path={["/SPR_V3"]} component={SPR_V3} />
                <Route exact path={["/SPR_V6B"]} component={HomePage} />
                <Route exact path={["/SPR_V4"]} component={SPR_V4} />
                <Route exact path={["/SPR_V5"]} component={SPR_V5} />
                <Route exact path={["/SPR_V5_1"]} component={SPR_V5_1} />
                <Route exact path={["/SPR_V5MVT"]} component={SPR_V5MVT} ></Route>
                <Route exact path={["/SPR_V6B_MS"]} component={SPR_V6B_MS} />
                <Route exact path={["/SPR_V7"]} component={SPR_V7} />
                <Route exact path={["/SPR_V8_1"]} component={SPR_V8_1} />
                <Route exact path={["/SPR_V8_4"]} component={SPR_V8_4} />
                <Route exact path={["/SPR_V8_4B"]} component={SPR_V8_4B} />
                <Route exact path={["/SPR_V8_2"]} component={SPR_V8_2} />
                <Route exact path={["/SPR_V8_3"]} component={SPR_V8_3} />
                <Route exact path={["/SPR_V8_5"]} component={SPR_V8_5} />
                <Route exact path={["/SPR_V8_6"]} component={SPR_V8_6} />
                <Route exact path={["/SPR_V8_7"]} component={SPR_V8_7} />
                <Route exact path={["/SPR_V8_8"]} component={SPR_V8_8} />
                <Route exact path={["/SPR_V8_9"]} component={SPR_V8_9} />
                <Route exact path={["/SPR_V5B"]} component={SPR_V5B} />
                <Route exact path={["/SPR_V8_9B"]} component={SPR_V8_9B} />
                <Route exact path={["/SPR_V8_4C"]} component={SPR_V8_4C} />
                <Route exact path={["/SPR_FF_V2_1"]} component={SPR_FF_V2_1}/>
                <Route exact path={["/SPR_V8_42"]} component={SPR_V8_42}/>
                <Route exact path={["/SPR_VD_V9"]} component={SPR_VD_V9}/>
                <Route exact path={["/SPR_WA_V11"]} component={SPR_WA_V11}/>
                <Route exact path={["/SPR_V10"]} component={SPR_V10}/>
                <Route exact path={["/SPR_FB_V_8_4_1"]} component={SPR_FB_V_8_4_1} />
                <Route exact path={["/SPR_VD_V9_1"]} component={SPR_VD_V9_1} />
                <Route exact path={["/SPR_VD_V9B"]} component={SPR_VD_V9B}/>
                <Route exact path={["/SPR_VD_FBV9B_1"]} component={SPR_VD_FBV9B_1}/>
                <Route exact path={["/SSPR_ADV1"]} component={SSPR_ADV1}/>
                <Route exact path={["/SSPR_ADV2"]} component={SSPR_ADV2}/>
                <Route exact path={["/SPR_V8_S4_1"]} component={SPR_V8_S41}/>
                <Route exact path={["/SPR_V9"]} component={SPR_V9}/>
                <Route exact path={"/medicare-thankyou"} component={MedicareThankYou}/>
                <Route exact path={"/greencard-thankyou"} component={GreencardThankyou}/>
                <Route path="/value/:dynamicValue" component={AdtopiaLoadValue} />
                <Route exact path={["/google","/testing","/index"]} component={AdtopiaLoadDynamic}/>
                <Route exact path={["/SPR_SPH_V10"]} component={SPR_SPH_V10}/>
                <Route exact path={"/medicare-thankyou-v2"} component={MedicareThankYouV2}/>
                <Route exact path={["/SPR_SPH_V11"]} component={SPR_SPH_V11}/>
                <Route exact path={["/SPR_SPH_V7_1"]} component={SPR_SPH_V7_1}/>
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
