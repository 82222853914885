import React from "react";
const QuestionnaireRadioSlideSpanish_SPR_V5_1 = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const answerBlock = props.questionnaire.answers.map((item, index) => {
    return (
      <React.Fragment key={index}>
      <label className="icon_option text-white" htmlFor={'question_' + question_id + '_' + item.value} key={index}>
        <input tabIndex="1"
          type="radio"
          className="sctop1 next01"
          id={'question_' + question_id + '_' + item.value}
          data-direction="next"
          name={radioName}
          data-question_id={question_id}
          value={item.value} data-answertext={item.label}
          autoComplete="off"
          ref={props.validation({ required: "Please Select" })}
          // onClick={(e) => props.slideChange(e, radioName)}
          onClick={(e) => {
            if (item.modalShow) {
              props.setModalBody(item.modalShow);
              props.saveJournyfun(e, radioName);
            } else {
              props.slideChange(e, radioName);
            }
          }}
        />
        <span>{item.label}</span>
      </label>
      </React.Fragment>
    )
  })
  return (
    <>
      <div
        id={'slide_1' + question_id}
        className={`slide_1st animate fadeIn five ${props.showQuestionSlide}`}
      >
        <div className="form_sec">
          <h2 className="retroshadow">{props.questionnaire.question}</h2>
          {answerBlock}
        </div>
      </div>
    </>
  )
}
export default QuestionnaireRadioSlideSpanish_SPR_V5_1;