import React, { useState, useContext, useEffect } from "react";
import Header from "../../Includes/Layouts/SPR_V4/Header";
// import Footer from "../../Includes/Layouts/SPR_V6B/Footer";
import Footer from "../../Includes/Layouts/Common/Footer";
import ChatBotBubble_V6B_MS from "./ChatBotBubble_V6B_MS";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from "../../../Utility/UUID";
import RingbaApi from "../../../Utility/RingbaApi";
import "../../../../src/assets/css/SPR_V6B/chat.scss"

const SPR_V6B_MS = () => {
  const splitName = 'SPR_V6B_MS';

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();
  const campaign_name = "spanish";
  const { response } = RingbaApi(campaign_name);


  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  useEffect(() => {
    if (uuid) {
      (async () => {
        await saveJournyStats(uuid, 'question', '7', '', 'view', splitName);
      })();
    }
  }, [uuid]);

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  return (
    <>

      <AdtopiaLoadLP PageType="LP" splitName="SPR_V6B_MS" />
      <FbViewPixel />
      <div className="SPR_V6B">
        <Header/>
        {/* HEADER  */}
        <section>
          <div className="container text-center">
            <h5 className="mt-lg-4 mt-md-4 mt-sm-2 mt-2   fw-bold text2">
            ¡Obtén hasta $6800 en Subsidios Fiscales para tu Seguro Médico!
            </h5>
            <div className="row mt-3">
              <div className="col-lg-10 offset-lg-1">
                <h1 className=" text1">
                ¡Aprovecha ahora y asegura tus beneficios!
                ¡No pierdas esta oportunidad para mejorar tu estilo de vida y ahorrar dinero!
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section className="chat-section mb-5 mrgn-btm">
          <div className="container">
            <div className=" mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3">
              <div className="chat-wrap">
                <div className="mb-1 support-online py-3 text-center">
                  {" "}
                  <span className="circle pulse green me-1" /> Veronica esta
                  disponible{" "}
                </div>
                <div className="chat-box-inner">
                  {/* Chat rows */}
                  <div className="row-chart-message mb-4 ">
                    <ChatBotBubble_V6B_MS
                      formSubmit={formSubmit}
                      journeyStatus={journeyStatus}
                      saveUser={saveUser}
                      uuid={uuid}
                      response={response}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default SPR_V6B_MS;
