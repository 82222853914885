import React, { useEffect, useRef, useState } from 'react';
import QandA from '../../../../Constants/ChatbotConstant';

import loader from '../../../../assets/img/SPR_V3/loader.gif';
import avatar2 from '../../../../assets/img/SPR_V3/avatar2.png';
import avatar_1 from '../../../../assets/img/SPR_V3/avatar_1.png';
import { PhoneNumberConstants } from '../../../../Constants/PhoneNumberConstants';
import Modal_SPR_V3 from './Modal_SPR_V3';
import { useDataIngestion } from '../../../../Hooks/useDataIngestion';
import useJourneyStats from "../../../../Hooks/useJourneyStats";
import RingbaPhoneNumberSpanish from "../../../../Utility/RingbaPhoneNumberSpanish";
import UnqualifiedRedirectionLinkSpanish from "../../../../Utility/UnqualifiedRedirectionLinkSpanish";
import OfflineSpanishButtonLink from '../../../../Utility/OfflineSpanishButtonLink';

const ChatBotBubble = ({ formSubmit, journeyStatus, saveUser, uuid, splitName, response }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [renderOption, setRenderOption] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [displayDelay, setDisplayDelay] = useState(2000); // Delay for questions

  const [modalBody, setModalBody] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const autofocus = useRef();

  const query = new URLSearchParams(window.location.search);
  const utmName = query.get('utm_campaign');
  const { saveDataIngestion } = useDataIngestion();
  const [buttonRedirect, setButtonRedirect] = useState();
 
  let is_online = response.is_online;
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  const saveJournyStats = useJourneyStats();
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName);
  const questions = QandA;

  const ModalViewers = async (click_status) => {
    setModalLoader(true);
    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      splitName,
    );
    window.location = UnqualifiedRedirectionLinkSpanish('').redirectionLink;
  }
  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      splitName
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        splitName
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };


  const handleOptionSelect = (option) => {
    // Create a copy of the chat history with the user's response and the selected option.
    const updatedChatHistory = [...chatHistory];
    // Pass question id and option id to a function for sending to the backend.
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = selectedQuestion.options.find((opt) => opt.text === option).id;

    // conditons can be set here based on questions or options
    const isQuestion6_Option12or13 = questionId === '6' && (optionId === '12' || optionId === '13');
    const isQuestion4_Option7 = questionId === '4' && optionId === '7';

    if (questionId === 'CALL_CLICK') {
      journeyStatus({
        slide_type: 'button',
        slide_question: 'CALL_CLICK',
        slide_value: '',
        type: 'click',
      });
      saveUser('CALL_CLICK', 1);
    } else if (isQuestion4_Option7 || isQuestion6_Option12or13) {
      setModalBody(true)
      updatedChatHistory.push({
        role: 'user',
        text: option,
      });
      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      journeyStatus({
        slide_type: 'question',
        slide_question: questionId,
        slide_value: optionId,
        type: 'click',
      });

      return false;
    } else {
      updatedChatHistory.push({
        role: 'user',
        text: option,
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      const storedOptions = localStorage.getItem('selectedOptions');
      const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
      parsedOptions['question_' + questionId] = optionId;
      localStorage.setItem('selectedOptions', JSON.stringify(parsedOptions));

      // focus the question
      toggleFocus(questionId);

      journeyStatus({
        slide_type: questionId === 'CALL_CLICK' ? 'button' : 'question',
        slide_question: questionId,
        slide_value: optionId,
        type: 'click',
      });
      // Here, you can call a function to send questionId and optionId to the backend.
      // sendToBackend(questionId, optionId);
      if (questions[currentQuestionIndex].final_question === true) {
        formSubmit(JSON.parse(localStorage.getItem('selectedOptions')));
      }
    }

    // Move to the next question or end the conversation when all questions are answered.
    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setRenderOption(false);
      setSelectedOption(''); // Clear the selected option for the next question.
    } else {
    
    }
  };
  useEffect(() => {
    localStorage.removeItem('selectedOptions');
  }, []);

  useEffect(() => {
    const questionDelay = displayDelay; // Always apply the delay.
    const segment = questions[currentQuestionIndex];
    let currentIndex = 0; // Index for the current question within the segment.

    let qno = 0;

    // Function to display the next question within the segment.
    const displayNextQuestion = () => {
      if (currentIndex < segment.question.length) {
        const currentQuestion = segment.question[currentIndex];
        if (currentQuestion) {
          setChatHistory((prevChat) => [...prevChat, { role: 'bot', text: 'Loading' }]);
          setTimeout(() => {
            setChatHistory((prevChat) => {
              const updatedChat = [...prevChat];
              updatedChat.pop(); // Remove the last message, which is "Loading..."

              // Check if it's the last question in the group
              qno++;
              const isLastQuestion = qno === segment.question.length ? segment.id : false;

              const newMessage = {
                role: 'bot',
                text: currentQuestion,
                last_question: isLastQuestion,
              };

              if (isLastQuestion !== false && uuid !== '' && segment.id !== '5') {
                journeyStatus({
                  slide_type:
                    questions[currentQuestionIndex].id === 'CALL_CLICK' ? 'button' : 'question',
                  slide_question: segment.id,
                  slide_value: '',
                  type: 'view',
                });
              }
              return [...updatedChat, newMessage];
            });

            if (currentIndex === segment.question.length) {
              // Display options after all questions in the segment.
              if (segment.options && segment.options.length > 0) {
                setRenderOption(true);
              }
            }
          }, 2000);
        }
        currentIndex++; // Move to the next question.
        setTimeout(
          displayNextQuestion,
          currentIndex === segment.question.length ? 0 : questionDelay
        ); // Apply the delay only after the last question.
      }
    };

    // Automatically initiate the conversation with the first question.
    const timer = setTimeout(displayNextQuestion, questionDelay);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex, displayDelay, questions]);

  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -445;

      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    } else {
      window.scroll(0, 0);
    }
  };

  return (
    <div>
      {chatHistory.map((message, index) =>
        message.role === 'bot' ? (
          <div
            key={index}
            className='chater-prof'>
            <div onClick={toggleFocus()}></div>
            <div>
              {message.text === 'Loading' || message.last_question === true ? (
                <div className='supporter-dp'>
                  <img
                    src={avatar_1}
                    alt=''
                  />
                </div>
              ) : (
                <div
                  className='supporter-dp'
                  style={{ backgroundColor: 'transparent', border: 'none' }}></div>
              )}
            </div>
            <div>
              {message.text === 'Loading' ? (
                <div className='msg-loader loader1'>
                  <div className='text-msg'>
                    <img
                      src={loader}
                      alt=''
                    />
                  </div>
                </div>
              ) : (
                <div className='mesg1'>
                  <span
                    // id={questions[currentQuestionIndex].id + '_' + index}
                    className={`text-msg text-message-item slide-top ${message.unique ? message.unique : ''
                      }`}>
                    {message.text}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            key={index}
            className='client-replay my-3'>
            <div onClick={toggleFocus()}></div>
            <div className='client-replay-vpx '>
              <div className='dp-client'>
                <img
                  src={avatar2}
                  alt=''
                />
              </div>
              <div className='sel-option'>
                <span
                  // id={questions[currentQuestionIndex].id + '_' + index}
                  className='action-btn text-message-item rounded answ-bnt'>
                  {message.text}
                </span>
              </div>
            </div>
          </div>
        )
      )}
      <div ref={autofocus}></div>
      {!selectedOption && (
        <>
          {questions[currentQuestionIndex].options &&
            questions[currentQuestionIndex].options.length > 0 &&
            renderOption === true ? (
            <>
             <div className="chater-prof">
                <div className="supporter-dp">
                  <img src={avatar_1} alt="" />
                </div>
                <div className="mesg4 sel-option">
                  <div className="text-msg btn-spc text-center">
                    {questions[currentQuestionIndex].options.map(
                      (option, index) =>
                        questions[currentQuestionIndex].specialcss === 'verticleButton' ? (
                          <div className="mb-1" key={index}>
                            <span
                              className={`action-btn  text-message-item  medibutton  w-100 d-block`}
                              onClick={() => handleOptionSelect(option.text)}
                            >
                              {option.text}
                            </span>
                          </div>
                        ) : questions[currentQuestionIndex].id ==
                          "CALL_CLICK" ? (
                          is_online == "online" ? (
                            <a
                              key={index}
                              className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                              onClick={() => { handleOptionSelect(option.text) }}
                              href={`tel: ${phoneNumber}`}
                            >
                              <span className='call icon_option_num icon_option_num_a text-white'>{formattedPhoneNumber}</span>
                            </a>
                          ) : (
                            <div>
                              <a
                                key={index}
                                className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleRedirect(1, "call_offline");
                                }}
                                target="blank"
                              >
                                <span className="call icon_option_num icon_option_num_a text-white">
                                  Reclamar Beneficios
                                </span>
                              </a>
                            </div>
                          )
                        ) : (
                          <span
                            key={index}
                            className={`action-btn text-message-item msg01 me-1`}
                            onClick={() => handleOptionSelect(option.text)}
                          >
                            {option.text}
                          </span>
                        )
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
      <Modal_SPR_V3
        modalBody={modalBody}
        setModalLoader={setModalLoader}
        ModalViewers={ModalViewers}
        modalLoader={modalLoader}
      />
    </div>
  );
};

export default ChatBotBubble;
