import React from 'react'
import { Helmet } from 'react-helmet'
import * as EnvConstants from "../../../../Constants/EnvConstants";
const EnvConstant = EnvConstants.AppEnv;
const Header = () => {
  return (
    <>
      {(EnvConstant == "prod")?<Helmet>
        <script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script>
      </Helmet>:<Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
      <header className="py-2 d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <a href="">
                <img
                  height={50}
                  src="https://yousted.org/lfdesigns/Sass-Projects/ACA_benefit_quiz/v3/dist/img/logo.png"
                  alt=""
                />
              </a>
            </div>
            <div className="col-lg-6" />
          </div>
        </div>
      </header>
      {/* HEADER  */}
      <section>
        <div className="container text-center">
          <h5 className="mt-lg-4 mt-md-4 mt-sm-2 mt-2   text1">
            <span className="text-danger">Atención: </span>Los agentes están extremadamente ocupados. Por favor, no llames a
            menos que tengas de 3 a 5 minutos para reclamar tus beneficios.
          </h5>
          <div className="row mt-2">
            <div className="col-lg-10 offset-lg-1">
              <h1 className="fs-2  fw-bold text2">
                Desbloquea hasta $6200 en subsidios fiscales para el seguro médico. ¡Los latinos están utilizando los
                ahorros para comestibles, gasolina, alquiler y más!

              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header