import React, { useEffect, useState } from "react";

const Loader = (props) => {
  const [loader, setLoader] = useState(null);
  const [show, setShow] = useState("d-none");
  const [currentIndex, setCurrentIndex] = useState(0);
  const loaderArr = ["Revisando tus respuestas", "Conexión al portal del programa", "Comprobación de programas locales"];

  useEffect(() => {
    if (currentIndex < loaderArr.length) {
      const showTimeout = setTimeout(() => {
        setLoader(loaderArr[currentIndex]);
        setShow("d-block");
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 2000);

      const hideTimeout = setTimeout(() => {
        setShow("d-none");
      }, 3000);

      return () => {
        clearTimeout(showTimeout);
        clearTimeout(hideTimeout);
      };
    } else {
      props.slideChange("", "call_button");
    }
  }, [currentIndex, loaderArr, props]);

  return (
    <>
      <div
        id="slide_load1"
        className={`slide_thk boxme animate fadeIn five mb-5 ${show}`}
      >
        <div className="form_sec text-center  mb-5">
          <p className="m-0">
            <strong>{loader}</strong>
          </p>
        </div>
      </div>
    </>
  )
}
export default Loader;