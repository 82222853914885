import React, { useState, useContext, useEffect } from 'react';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import useJourneyStats from '../../../Hooks/useJourneyStats';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import VisitorParamsContext from '../../../Contexts/VisitorParams';
import { queryString } from '../../../Utility/QueryString';
import FbViewPixel from '../../../Utility/FbViewPixel';
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from '../../../Utility/UUID';
import RingbaApi from '../../../Utility/RingbaApi';
import '../../../assets/css/SPR_VD_V9/style.scss';
import vdo from '../../../assets/img/SPR_VD_V9/img/Vdo_1.mp4';
import vdobg from '../../../assets/img/SPR_VD_V9/img/video-bg.jpg';
import Footer from '../../Includes/Layouts/Common/Footer';
import Header from '../../Includes/Layouts/SPR_VD_V9/Header';
import ChatBubble_SPR_VD_V9B from './ChatBubble_SPR_VD_V9B';

const SPR_VD_V9B = () => {
  const splitName = 'SPR_VD_V9B';

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();
  const campaign_name = 'spanish';
  const { response } = RingbaApi(campaign_name);
  const [muted, setMuted] = useState(true);

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  return (
    <>
      <AdtopiaLoadLP
        PageType='LP'
        splitName={splitName}
      />
      <FbViewPixel />
      <div className='SPR_VD_V9'>
        <div className='wrap-content'>
          <Header />
          <div className='video-post'>
            <div
              className='volume'
              id='muteBtn '
              onClick={() => setMuted(!muted)}>
              <i className={`bi bi-volume-${muted ? 'down' : 'mute'}-fill vlicon`} />{' '}
            </div>
            <video
              poster={vdobg}
              id='mVideo'
              autoPlay
              muted={muted === true ? true : false}
              loop
              onClick={() => setMuted(!muted)}>
              <source
                src={vdo}
                type='video/mp4'
              />
            </video>
          </div>
          <ChatBubble_SPR_VD_V9B
            formSubmit={formSubmit}
            journeyStatus={journeyStatus}
            saveUser={saveUser}
            uuid={uuid}
            response={response}
            splitName={splitName}
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SPR_VD_V9B;
