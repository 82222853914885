import React, { useState, useEffect } from 'react';

const Loader1 = (props) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    const showNextSlide = () => {
      if (currentSlide < 1) {
        setCurrentSlide(currentSlide + 1);
      } else {
        props.slideChange(null, "chatloader");
        setCurrentSlide(0);
      }
    };

    const timeout = setTimeout(() => {
      showNextSlide();
    }, 2000);

    return () => clearTimeout(timeout);
  }, [currentSlide]);
  // console.log("ss"+currentSlide);
  return (
    <>
      {currentSlide !== 0 ?
        <div
          key={`slide_load${currentSlide}`}
          className={`slide_thk boxme animate fadeIn five mb-5`}
        >
          <div className="form_sec text-center mb-5">
            <p className="m-0">
              <strong>
                {currentSlide === 1 ? 'Comprobando la disponibilidad del agente...'

                  : null
                }
              </strong>
            </p>
          </div>
        </div> : null
      }
    </>
  )
}

export default Loader1