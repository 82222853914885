const GTMDataLayer = ({ question, answer }) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "slideclick",
      question: question,
      answer: answer,
    });
    
    return <></>;
  };
  
  export default GTMDataLayer;