import { Api } from "../api/Api";

export const useGetState = () => {
    
  const getUserState = async (user_state) => {
    const response = await Api.get("v1/get-state", {
      user_state,
    });
    return response;
  };
  return { getUserState};
};
