import React from 'react';
import { DobYearSpanish, DobMonthSpanish, DobDaySpanish } from '../../Constants/Constants';
import SelectBox from '../../Components/UI/SelectBox';
import { useForm } from 'react-hook-form';


const DateOfBirth = ({ textDob, validation, validationMsg,clearErrors }) => {

    return (
        <div className="form-group col-12">
            <fieldset className="scheduler-border">
                <legend className="scheduler-border">{textDob}</legend>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-3">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobDaySpanish}
                                onChange={() => clearErrors("DobDay")}
                                name="DobDay"
                                id="DobDay"
                                myRef={validation({
                                    required: "por favor seleccione el día"
                                })}
                                validationMsg={validationMsg.DobDay && validationMsg.DobDay.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobDay_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12  mb-3">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobMonthSpanish}
                                onChange={() => clearErrors("DobMonth")}

                                name="DobMonth"
                                id="DobMonth"
                                myRef={validation({
                                    required: "Por favor seleccione mes"
                                })}
                                validationMsg={validationMsg.DobMonth && validationMsg.DobMonth.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobMonth_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12  mb-3">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobYearSpanish}
                                onChange={() => clearErrors("DobYear")}

                                name="DobYear"
                                id="DobYear"
                                myRef={validation({
                                    required: "Por favor seleccione año"
                                })}
                                validationMsg={validationMsg.DobYear && validationMsg.DobYear.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobYear_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <span id="dob_final_err" className="error_msg"></span>
                </div>
            </fieldset>
        </div>
    )
}

const JointDateOfBirth = ({ textDob, validation, validationMsg }) => {

    return (
        <div className="form-group col-12">
            <fieldset className="scheduler-border">
                <legend className="scheduler-border">{textDob}</legend>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobDaySpanish}
                                name="JointDobDay"
                                myRef={validation({
                                    required: "Please Select Date"
                                })}
                                validationMsg={validationMsg.JointDobDay && validationMsg.JointDobDay.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobDay_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobMonthSpanish}
                                name="JointDobMonth"
                                myRef={validation({
                                    required: "Please Select Month"
                                })}
                                validationMsg={validationMsg.JointDobMonth && validationMsg.JointDobMonth.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobMonth_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="form-group">
                            <SelectBox
                                className="form-control"
                                OptionValue={DobYearSpanish}
                                name="JointDobYear"
                                myRef={validation({
                                    required: "Please Select Year"
                                })}
                                validationMsg={validationMsg.JointDobYear && validationMsg.JointDobYear.message}
                            />
                            <i className="tick fa" style={{ display: 'none' }}></i>
                            {/* <span id="dobYear_err" className="error_msg"></span> */}
                        </div>
                    </div>
                    <span id="dob_final_err" className="error_msg v4_dobfinal"></span>
                </div>
            </fieldset>
        </div>
    )
}

export { DateOfBirth, JointDateOfBirth };