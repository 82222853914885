import React from "react";

const Questionnaire = (props) => {
  const radioName = "question_" + props.question.questionId;
  const question_id = props.question.questionId;
  const answerBlock = props.question.answers.map((item, index) => {
    return (
      <label
        className="icon_option text-white"
        htmlFor={'question_' + question_id + '_' + item.value}
        key={index}
      >
        <input
          type="radio"
          className="sctop1 next01"
          id={'question_' + question_id + '_' + item.value}
          value={item.value}
          name={radioName}
          ref={props.validation({ required: "Please Select" })}
          data-answertext="No"
          autoComplete="off"
          onClick={(e) => {
            if (item.modalShow) {
              props.setModalBody(item.modalShow);
              props.saveJounrney(e, question_id);
            }
            else {
              props.slideChange(e, radioName)
            }
          }}
        />
        <span>{item.label}</span>
      </label>
    )
  })
  console.log(props);
  return (
    <div
      id="slide_1"
      className={`slide_1st animate fadeIn five ${props.showQuestionSlide}`}
    >
      <div className="form_sec">
        <h2 className="retroshadow">
          {props.question.question}
        </h2>
        {answerBlock}
      </div>
    </div>
  )
}
export default Questionnaire;