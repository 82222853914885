import React, { useState } from 'react'

const Accordion_V10_v11 = () => {
	const accordionData = [
		{
			question: '¿Qué es el Obamacare?',
			answer: (
				<div>
					<p>
						Es la Ley del Cuidado de Salud a Bajo Precio (Patient Protection
						and Affordable Care Act- ACA), implementada por el expresidente
						de los Estados Unidos Barack Obama, el 23 de marzo de 2010.
					</p>
					<p>
						Esta ley incluye unos componentes claves que cambiaron la manera
						en que se obtenían los seguros médicos. Ahora las personas
						pueden calificar a un Crédito fiscal o subsidio otorgado por el
						gobierno, para que disminuya el pago mensual o pago el total del
						valor de la póliza de seguro.{" "}
						<strong>
							“
							<span style={{ color: "#1250fa" }}>
								<a
									style={{ color: "#1250fa" }}
									href="https://obamacareinsurancecenter.com/formulario-calcular-subsidio-obamacare/"
									role="link"
								>
									Clic
								</a>
							</span>
							”
						</strong>{" "}
						para calcular subsidio
					</p>
					<p>
						Otro componente importante es la reducción de costos
						compartidos, siendo este un crédito adicional que disminuye la
						cantidad que se debe pagar por los deducibles, copagos y
						coseguros; denominados “ahorros adicionales”.
					</p>
				</div>
			),
		},
		{
			question: '¿Qué requisitos se necesitan para Obamacare?',
			answer: (
				<div>
					<p>
						Otro componente importante es la reducción de costos
						compartidos, siendo este un crédito adicional que disminuye la
						cantidad que se debe pagar por los deducibles, copagos y
						coseguros; denominados “ahorros adicionales”.
					</p>
					<p>&nbsp;</p>
					<p>
						1. Tener un estatus migratorio en Estados Unidos, o estar en
						proceso de alguno.
					</p>
					<p>
						<strong>
							<span style={{ color: "#1250fa" }}>
								<a
									style={{ color: "#1250fa" }}
									href="https://nuevo.obamacareinsurancecenter.com/requisitos/"
									role="link"
								>
									“Clic”
								</a>
							</span>
						</strong>{" "}
						para ver el listado completo de los estatus migratorios.
					</p>
					<ol start={2}>
						<li>No ser beneficiario de Medicare, Medicaid o CHIP.</li>
						<li>
							Cumplir con al menos el mínimo de ingresos establecidos. El
							Gobierno Federal establece los parámetros para el Índice
							Federal de Pobreza, según la composición del núcleo familiar.
							<strong>
								<span style={{ color: "#1250fa" }}>
									{" "}
									<a
										style={{ color: "#1250fa" }}
										href="https://nuevo.obamacareinsurancecenter.com/requisitos/"
										role="link"
									>
										“Clic”
									</a>
								</span>
							</strong>{" "}
							para ver las tablas del Índice Federal de Pobreza para la
							cobertura médica en el 2022.
						</li>
						<li>No estar recluido en una cárcel.</li>
					</ol>
				</div>
			),
		},
		{
			question: '¿Cuáles son los beneficios del Obamacare?',
			answer: (
				<div>
					<p></p>
					<ol>
						<li>Cuidados preventivos </li>
						<li>Doctores primarios y especialistas</li>
						<li>Medicamentos recetados</li>
						<li>Diagnóstico a tiempo </li>
						<li>Atención ambulatoria</li>
						<li>Enfermedades preexistentes</li>
						<li>Maternidad y neonatos</li>
						<li>Salud mental</li>
						<li>Enfermedades crónicas</li>
						<li>Hospitalización</li>
					</ol>
				</div>
			),
		},
		{
			question: '¿Cómo realizar una inscripción al Seguro de Salud Obamacare?',
			answer: (
				<div>
					<p>
						Para realizar la inscripción al seguro Obamacare, puede ingresar
						a la página web{" "}
						<a
							href="https://obamacareinsurancecenter.com/cotizar-seguro-de-salud/"
							role="link"
						>
							<strong>
								<span style={{ color: "#1250fa" }}>
									https://obamacareinsurancecenter.com/cotizar-seguro-de-salud/
								</span>
							</strong>
						</a>{" "}
						y llenar el formulario que se encuentra en la página de inicio o
						comunicarse directamente con uno de nuestros asesores al
						WhatsApp{" "}
						<strong>
							<span style={{ color: "#1250fa" }}>
								<a
									style={{ color: "#1250fa" }}
									href="https://api.whatsapp.com/send?phone=18445040627"
									role="link"
								>
									(844) 504-0627
								</a>
							</span>
						</strong>
					</p>
				</div>
			),
		},
		{
			question: '¿Qué es un Crédito fiscal o subsidio y cómo calificar a uno?',
			answer: (
				<div>
					<p>
						Cuando se califica a un plan del Mercado de Seguros Médicos, el
						gobierno otorga un crédito fiscal o subsidio, el cual tendrá
						como beneficio, disminuir una parte del pago mensual o la
						totalidad de la “Prima mensual” de la póliza de seguro médico.
						El valor del crédito fiscal será determinado por:&nbsp;
					</p>
					<ol>
						<li>
							La composición del núcleo familiar según la declaración de
							impuestos.
						</li>
						<li>
							La edad de cada miembro que aplique para la cobertura médica.
						</li>
						<li>
							El ingreso anual de los integrantes del núcleo familiar.
						</li>
						<li>Código postal.</li>
					</ol>
					<p>
						<b>¿</b>
						<b>Cómo calificar a uno</b>
						<b>?</b>
					</p>
					<p>
						“Clic” para calcular el subsidio{" "}
						<a
							href="https://obamacareinsurancecenter.com/formulario-calcular-subsidio-obamacare/"
							role="link"
						>
							<strong>
								<span style={{ color: "#1250fa" }}>
									https://obamacareinsurancecenter.com/formulario-calcular-subsidio-obamacare/
								</span>
							</strong>
						</a>
					</p>
				</div>
			),
		},
		{
			question: '¿Se puede negar un Seguro de Salud por una enfermedad preexistente?',
			answer: (
				<div>
					<p>
						Esto dependerá del año de inscripción del plan de seguro médico.
						Si la inscripción es a partir del 2010, la aseguradora no podrá
						negar legalmente la cobertura o cobrar primas mayores por tener
						una condición preexistente. Sin embargo, si la persona se
						inscribió en un plan que comenzó antes de 2010, tiene un plan de
						«derechos adquiridos» («grandfathered plan»). Estos planes
						pueden cancelar la cobertura o cobrar tarifas más elevadas,
						debido a una condición preexistente.
					</p>
				</div>
			),
		},
		{
			question: '¿Qué documentos puede pedir el Mercado de Seguros Médicos?',
			answer: (
				<div>
					<p>
						En ocasiones, el Mercado de Seguros Médicos puede solicitar los
						siguientes documentos para la obtención de la póliza de seguro:
					</p>
					<p>&nbsp;</p>
					<ol>
						<li>
							Declaración de ingresos{" "}
							<strong>
								<span style={{ color: "#1250fa" }}>
									<a
										style={{ color: "#1250fa" }}
										href="https://nuevo.obamacareinsurancecenter.com/wp-content/uploads/2022/03/DECLARACION-DE-INGRESOS.pdf"
										role="link"
									>
										“Clic”
									</a>
								</span>
							</strong>{" "}
							para descargar la declaración de ingresos
						</li>
						<li>
							Prueba de estatus migratorio
							<strong>
								<span style={{ color: "#1250fa" }}>
									<a
										style={{ color: "#1250fa" }}
										href="https://nuevo.obamacareinsurancecenter.com/estatus-migratorio/"
										role="link"
									>
										{" "}
										“Clic”
									</a>
								</span>
							</strong>{" "}
							para ver los estatus migratorios
						</li>
					</ol>
				</div>
			),
		},
		{
			question: '¿Cómo enviar documentos requeridos al Mercado de Seguros Médicos?',
			answer: (
				<div>
					<p>
						Para enviar documentos al Mercado de Seguros Médicos, a
						continuación puede diligenciar el siguiente formulario y
						adjuntar la verificación de declaración de ingresos y/o
						verificación de su estatus migratorio. “Clic” para enviar
						documentos
						<span style={{ color: "#1250fa" }}>
							<strong>
								<a
									style={{ color: "#1250fa" }}
									href="https://obamacareinsurancecenter.com/documentos/"
									role="link"
								>
									{" "}
									https://obamacareinsurancecenter.com/documentos/
								</a>
							</strong>
						</span>
					</p>
				</div>
			),
		},
		{
			question: '¿Cómo agendar una cita al doctor primario y especialista?',
			answer: (
				<div>
					<p>
						Obamacare Insurance Center orienta y brinda apoyo al momento de
						localizar un doctor y/o agendar una cita médica. “Clic” para
						completar el formulario y localizar o solicitar una cita médica{" "}
						<strong>
							<span style={{ color: "#1250fa" }}>
								<a
									style={{ color: "#1250fa" }}
									href="https://obamacareinsurancecenter.com/agendar-cita-medica/"
									role="link"
								>
									https://obamacareinsurancecenter.com/agendar-cita-medica/
								</a>
							</span>
						</strong>
					</p>
				</div>
			),
		},
	];

	const [activeAccordion, setActiveAccordion] = useState(null);

	const handleAccordionChange = (id) => {
		setActiveAccordion(id === activeAccordion ? null : id);
	};
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-12 acco_desin">
						<section className="accordion">
							<ul className="accordion-list">
								{accordionData.map((item, index) => (
									<li key={index} className="accordion-item">
										<input
											type="checkbox"
											id={`accordion_id_${index}`}
											className="accordion-button"
											checked={`accordion_id_${index}` === activeAccordion}
											onChange={() => handleAccordionChange(`accordion_id_${index}`)}
										/>
										<label htmlFor={`accordion_id_${index}`} className="accordion-header">
											<p>{item.question}</p>
											<p className={`accordion_id_${index}` === activeAccordion ? 'opened' : 'closed'}>{`accordion_id_${index}` === activeAccordion ? '-' : '+'}</p>
										</label>
										<section className={`content ${`accordion_id_${index}` === activeAccordion ? 'show' : ''}`}>{item.answer}</section>
									</li>
								))}
							</ul>
						</section>
					</div>
				</div>
			</div>
		</>
	)
}

export default Accordion_V10_v11