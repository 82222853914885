import React, { useReducer, useState, useEffect, useRef, useContext } from "react";
import arrow_down from "../../assets/img/SPR_V5/arrow-down.png";
import { useForm } from "react-hook-form";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { queryString } from "../../Utility/QueryString"
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { questionnaire1, questionnaire2, questionnaire3 } from "../../Constants/QuestionsSpanish_SPR_V5";
import CallButtonSpanishSprV5 from "../Includes/Layouts/SPR_V5/CallButtonSpanish_SPR_V5";
import GTMDataLayer from "../Includes/Layouts/Common/GTMDataLayer";
import useJourneyStats from "../../Hooks/useJourneyStats";
import QuestionnaireRadioSlideSpanishSprV5 from "../Includes/Layouts/Questionnaire/QuestionnaireRadioSlideSpanish_SPR_V5";
import Loader from "../Includes/Layouts/SPR_V5/Loader";
import Loader1 from "../Includes/Layouts/SPR_V5/Loader1";
import Header from "../Includes/Layouts/SPR_V5/Header";
import OfflineSpanishButtonLink from "../../Utility/OfflineSpanishButtonLink";
import { useUserClicks } from '../../Hooks/useUserClicks';
import ChatBotBubble_SPR_V5_B from "../Pages/SPR_V5B/ChatBotBubble_SPR_V5_B";

const FormSplitSPR_V5B = ({splitName,setModalBody,response}) => {
  const [hideHeader, setHideHeader] = useState("d-block");
  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const { register, errors, trigger, getValues } =

    useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const initialState = {
    showQuestionSlide1: "d-block",
    showQuestionSlide2: "d-none",
    showQuestionSlide3: "d-none",
    loader: "d-none",
    chatloader:"d-none",
    thank_you: "d-none",
  };

  const QuestionReducer = (state, action) => {
    const { clickedSlide } = action.payload;
    switch (action.type) {
      case "nextQuestionSlide": {
        const { slide, value } = clickedSlide;
        const updatedState = {
          showQuestionSlide1: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide3: "d-none",
          loader: "d-none",
          chatloader:"d-none",
          thank_you: "d-none",
        };

        if (slide === "question_12" && value === "26") updatedState.showQuestionSlide3 = "d-block";
        else if (slide === "question_12" && value === "27") updatedState.showQuestionSlide2 = "d-block";
        else if (slide === "question_9") updatedState.loader = "d-block";
        // else if (slide === "question_24" && value === "54") {
        //   updatedState.loader = "d-block";
        //   setHideHeader("d-none")
        // }
        else if (slide === "question_24" && (value == "53" || value == "54")) {
          updatedState.chatloader = "d-block";
          setTimeout(() => {

          }, 2000);
        }
        else if (slide === "loader") updatedState.thank_you = "d-block";

        return { ...state, ...updatedState };
      }

      default: {
        return state;
      }
    }
  };
  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const splitForm = useRef(null);
  const { saveDataIngestion } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const locUUID = localStorage.getItem("uuid");
  const [buttonRedirect, setButtonRedirect] = useState();
  let is_online = response.is_online;
  let redirect = response.redirect_path;
  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };


  useEffect(() => {
    if (state.showQuestionSlide1 == "d-block") {
      if (locUUID) {
        (async () => {
          await saveJournyStats(
            uuid,
            "question",
            "12",
            "",
            "view",
            splitName
          );
        })();
      }
    }
  }, [locUUID])

  useEffect(() => {
    let val = null;
    if (clickedSlide) {
      if (clickedSlide.slide === "question_12" && clickedSlide.value === "26") {
        val = { type: "question", id: "24" }
      } else if (clickedSlide.slide === "question_12" && clickedSlide.value === "27") {
        val = { type: "question", id: "9" }
      }

    }

    const slideActions = {
      question_12: val,
      question_9: { type: "button", id: "call_click" },
     // question_10: { type: "button", id: "call_click" },
    };

    if (clickedSlide && slideActions[clickedSlide.slide]) {
      const { type, id } = slideActions[clickedSlide.slide];

      (async () => {
        await saveJournyStats(uuid, type, id, "", "view", splitName);
      })();
    }
  }, [clickedSlide]);

  const gtmDataLayer = (current_question, element = null) => {
    let question = "";
    let answer = "";
    switch (current_question) {
      case "question_12":
        question = "Ya tienes Medicare o Medicaid?";
        break;
      case "question_9":
        question = "¿Cuál de los siguientes tienes?";
        break;
      case "question_24":
        question = "¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?";
        break;
    }
    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  const saveJournyfun = async (e, radioName) => {
    //const form = splitForm.current;
    let radio_id = e.target.dataset.question_id;
    var option_id = e.target.value;
    if (radioName == "question_12" || radioName == "question_9" || radioName == "question_24") {
      await saveJournyStats(
        uuid,
        "question",
        radio_id,
        option_id,
        "click",
        splitName
      );
    }
  }

  const slideChange = async (e, radioName) => {
    if (e === null) {
      setClickedSlide({ slide: radioName, value: "" });
    } else {
      gtmDataLayer(e.target.name, e.target);
      const form = splitForm.current;
      const values = getValues();
      window.scrollTo(0, 145);
      setClickedSlide({ slide: radioName, value: e.target.value });
      let question_id = radioName;
      let optionId = form[radioName].value;
      let radio_id = e.target.dataset.question_id;
      var option_id = e.target.value;
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = {};
      var newEntries =
        existingEntries == null ? (existingEntries = {}) : existingEntries;
      Object.assign(newEntries, {
        [question_id]: optionId,
      });
      localStorage.setItem("questionData", JSON.stringify(newEntries));
      if (radioName == "question_12" || radioName == "question_9" || radioName == "question_24") {
        await saveJournyStats(
          uuid,
          "question",
          radio_id,
          option_id,
          "click",
          splitName
        );
      }

      // if (radioName == "question_24" && option_id=="54") {
      //   await saveJournyStats(
      //     uuid,
      //     "button",
      //     "call_click",
      //     "",
      //     "view",
      //     splitName
      //   );

      //   const formData = values;
      //   formData.lstSalutation = '';
      //   formData.txtFName = '';
      //   formData.txtLName = '';
      //   formData.txtEmail = '';
      //   formData.txtPhone = '';
      //   formData.page_name = splitName;
      //   formSubmit(formData);
      // }
    }
  }

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };
  
  const handleRedirect = async (click_status, click_value) => {
    await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      splitName
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        splitName
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };
  return (
    <>
      <Header
        hideHeader={hideHeader}
      />
      <section className="container-fluid form-bg">
        <form ref={splitForm} className="form_slides">
          <div className="col-lg-6 col-12">
            <div className={`animated-arrow col-6 ${hideHeader}`}>
              <img
                src={arrow_down}
                alt=""
                className="animated-arrow-img animg"
              />
            </div>
            <QuestionnaireRadioSlideSpanishSprV5
              showQuestionSlide={state.showQuestionSlide1}
              questionnaire={questionnaire1}
              setModalBody={setModalBody}
              saveJournyfun={saveJournyfun}
              slideChange={slideChange}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />
            <QuestionnaireRadioSlideSpanishSprV5
              showQuestionSlide={state.showQuestionSlide2}
              questionnaire={questionnaire2}
              setModalBody={setModalBody}
              saveJournyfun={saveJournyfun}
              slideChange={slideChange}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />
            <QuestionnaireRadioSlideSpanishSprV5
              showQuestionSlide={state.showQuestionSlide3}
              questionnaire={questionnaire3}
              setModalBody={setModalBody}
              saveJournyfun={saveJournyfun}
              slideChange={slideChange}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />
            {state.chatloader === "d-block" ?
              <Loader1
                slideChange={slideChange}
              />
              : null}
            {getValues().question_24 && clickedSlide.slide === "chatloader" && (
              <section className="chat-section mb-5 mrgn-btm mt-3">
                <div className="container">
                  <div className="mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                    <div className="chat-wrap">
                      <div className="mb-1 support-online py-3 text-center">
                        <span className="circle pulse green me-1" /> Veronica está disponible
                      </div>
                      <div className="chat-box-inner">
                        <div className="row-chart-message mb-4">
                          <ChatBotBubble_SPR_V5_B
                            formSubmit={formSubmit}
                            journeyStatus={journeyStatus}
                            saveUser={saveUser}
                            uuid={uuid}
                            response={response}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {state.loader === "d-block" ? <Loader slideChange={slideChange} /> : null}
            <div
              id="slide_thank"
              className={`slide_thk boxme animate fadeIn five ${state.thank_you}`}
            >
              <div className="form_sec text-center mb-5">
                <p>
                  <span className="tnk_head fw-bold">
                    ¡Felicidades! 🤩🤩🥳🥳🥳
                  </span>
                </p>
                <p className="attribute">
                  Estás precalificado para recibir hasta $7200 en subsidios
                  fiscales para el seguro médico.
                </p>
                <p>
                  Toca el botón de abajo para llamar ahora y reclamar tus
                  beneficios, solo te llevará de 2 a 3 minutitos.
                </p>
                {is_online === "online" && (
                  <CallButtonSpanishSprV5 splitName={splitName} />
                )}
                {is_online === "offline" && (
                  <div>
                    <a className="t2c_btn" onClick={(event) => {
                      event.preventDefault();
                      handleRedirect(1, "call_offline");
                    }}>Reclamar Beneficios
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  )
}

export default FormSplitSPR_V5B