import React from 'react'
import { Helmet } from 'react-helmet'
import * as EnvConstants from "../../../../../src/Constants/EnvConstants";
const EnvConstant = EnvConstants.AppEnv;

const Header = () => {
  return (
    <>
     {(EnvConstant == "prod") ? <Helmet>
        <script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script>
      </Helmet>:<Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
      <header className="py-2 d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <a href="">
                <img
                  height={50}
                  src="https://yousted.org/lfdesigns/Sass-Projects/ACA_benefit_quiz/v3/dist/img/logo.png"
                  alt=""
                />
              </a>
            </div>
            <div className="col-lg-6" />
          </div>
        </div>
      </header>
    </>
  )
}

export default Header