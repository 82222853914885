import React, { useEffect, useState, useRef } from "react";
import { QandA_v10 } from "../../../Constants/ChatbotConstant_V6B";

import loader from "../../../assets/img/SPR_SPH_V11/loader.gif";
import avatar2 from "../../../assets/img/SPR_SPH_V11/avatar2.png";
import avatar from "../../../assets/img/SPR_SPH_V11/avatar.png";

import { PhoneNumberConstants } from "../../../Constants/PhoneNumberConstants";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import { useHistory } from 'react-router-dom';
import SimpleModal from "./SimpleModal";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import OfflineSpanishButtonLinkSpanish from "../../../Utility/OfflineSpanishButtonLinkSpanish";
import * as EnvConstants from "../../../Constants/EnvConstants";
import ChatBotTimer from "../../Includes/Layouts/Common/ChatBotTimer_SPR_SPH_V11";
import DobModal from "./DobModal";


const ChatBotBubble_V11 = ({ formSubmit,showTime, setshowTime,journeyStatus, saveUser, uuid, response }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [renderOption, setRenderOption] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [showDob,setShowdob] = useState('hide');
  const [displayDelay, setDisplayDelay] = useState(2000); // Delay for questions
  const [phoneNumber, setPhoneNumber] = useState("");
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const autofocus = useRef();
  const [modalBody, setModalBody] = useState('');
  const { saveDataIngestion } = useDataIngestion();
  const [showLoader, setShowLoader] = useState(false);
  const saveJournyStats = useJourneyStats();
  const history = useHistory();
  const [buttonRedirect, setButtonRedirect] = useState();
  // undoInitiated is only require when moving to previous question
  const [undoInitiated, setUndoInitiated] = useState(false);
  let is_online = response.is_online;
  let redirect = response.redirect_path;
  const unqualifiedLink = UnqualifiedRedirectionLinkSpanish();
  const EnvConstant = EnvConstants.AppEnv;
  const decodedQueryString = decodeURIComponent(query.toString());

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLinkSpanish(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  useEffect(() => {
    if(EnvConstant == "prod"){
      if (
        PhoneNumberConstants[utmName] == undefined ||
        PhoneNumberConstants[utmName] == ""
      ) {
        setPhoneNumber(PhoneNumberConstants["default_SPH"]);
      } else {
        setPhoneNumber(PhoneNumberConstants[utmName]);
      }
    }else{
      setPhoneNumber(PhoneNumberConstants['default_local_NO']);
    }

    const query = new URLSearchParams(window.location.search);
    const devcommand = query.get("devcommand");
    if (devcommand === "fast") {
      setDisplayDelay(0);
    }
  }, []);

  console.log(phoneNumber);

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');

  useEffect(() => {
    if (phoneNumber) {
      const digits = phoneNumber.replace(/\D/g, '').slice(-10);
      if (digits.length === 10) {
        const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        setFormattedPhoneNumber(formattedNumber);
      }
    }
  }, [phoneNumber]);

  const questions = QandA_v10;

  const ModalViewers = async (click_status) => {
    setShowLoader(true);
    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      "SPR_SPH_V11",
    );
    // window.location = unqualifiedLink.redirectionLink;
    window.location = UnqualifiedRedirectionLinkSpanish(questions[currentQuestionIndex].options.find((opt) => opt.text === selectedOption).id).redirectionLink;

  }
  const handleRedirect = async(click_status,click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status,click_value],
      "offline",
      "SPR_SPH_V11"
    );
    (async () => {
      await saveJournyStats(
          uuid,
          "BUTTON",
          "CALL_CLICK",
          "",

          "click",
          "SPR_SPH_V11"
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;

  };

  const handleOptionSelect = (option) => {
    // Create a copy of the chat history with the user's response and the selected option.
    const updatedChatHistory = [...chatHistory];
    // Pass question id and option id to a function for sending to the backend.
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = selectedQuestion.options.find((opt) => opt.text === option).id;

    // conditons can be set here based on questions or options
    const isQuestion15 = questionId === '9' && ( optionId === '67'  || optionId === '19');

    const isQuestion16 = questionId === '10' && optionId === '23';
    const medic = questionId === '9' && (optionId === '18');
console.log(questionId,"      ",optionId)
    if (questionId === "CALL_CLICK") {
      // window.location.href = `tel:${phoneNumber}`;
      journeyStatus({
        slide_type: 'button',
        slide_question: 'CALL_CLICK',
        slide_value: '',
        type: 'click',
      });
      saveUser('CALL_CLICK', 1);
    } else if ( isQuestion15) {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      journeyStatus({
        slide_type: 'question',
        slide_question: questionId,
        slide_value: optionId,
        type: 'click',
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      setModalBody("¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳");

      return false;
    } else {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      const storedOptions = localStorage.getItem('selectedOptions');
      const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
      parsedOptions['question_' + questionId] = optionId;
      localStorage.setItem('selectedOptions', JSON.stringify(parsedOptions));

      toggleFocus(questionId);

      journeyStatus({
        slide_type: questionId === 'CALL_CLICK' ? 'button' : 'question',
        slide_question: questionId,
        slide_value: optionId,
        type: 'click',
      });
      // Here, you can call a function to send questionId and optionId to the backend.
      // sendToBackend(questionId, optionId);
      if (questions[currentQuestionIndex].final_question === true) {
        formSubmit(JSON.parse(localStorage.getItem('selectedOptions')));
      }
    }

    // console.log("qid: " + questionId, "  oid: " + optionId);

    // Move to the next question or end the conversation when all questions are answered.
    if (currentQuestionIndex + 1 < questions.length) {
      if(questionId == 'CALL_CLICK') return;
      if ( questionId === '10' && optionId === '23') {
        var redirectUrl = `${window.location.origin}/greencard-thankyou?${decodedQueryString}`;
        window.location.href = redirectUrl;
        return false;
        // setCurrentQuestionIndex(currentQuestionIndex + 2);
      }
      else if (questionId === '29' && optionId === '69') {
        setCurrentQuestionIndex(currentQuestionIndex + 2);
      }
      else if(medic){
        var redirectUrl = `${window.location.origin}/medicare-thankyou-v2?${decodedQueryString}`;
        window.location.href = redirectUrl;
        return false;
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      // if(questionId === '22' && optionId === '52')
      // {
      //   setshowTime(true);
      // }
      setRenderOption(false);
      setSelectedOption(""); // Clear the selected option for the next question.
    } else {
      // Conversation ended. You can handle this as needed.
      // For example, display a closing message.
    }
  };

  const undoChat = () => {
    const updatedChatHistory = [...chatHistory];
    updatedChatHistory.pop();
    setChatHistory(updatedChatHistory);
    setRenderOption(true);
    setSelectedOption('');

    const currQuestion = questions[currentQuestionIndex].id;

    // for going back to previous questions
    if (currQuestion === '9') {
      setUndoInitiated(true);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      const updatedChatHistory = chatHistory.slice(0, -3);
      setChatHistory(updatedChatHistory);
      // Retrieve storedOptions from localStorage
      const storedOptions = localStorage.getItem('selectedOptions');

      // Parse storedOptions or initialize an empty object
      const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};

      // Remove the last object from parsedOptions
      const keys = Object.keys(parsedOptions);
      if (keys.length > 0) {
        const lastKey = keys[keys.length - 1];
        delete parsedOptions[lastKey];
      }

      // Save the updated parsedOptions to localStorage
      localStorage.setItem('selectedOptions', JSON.stringify(parsedOptions));
    }

    setModalBody("")
  }

  useEffect(() => {
    localStorage.removeItem("selectedOptions");
  }, []);


  useEffect(() => {
    if (!undoInitiated) {
      const questionDelay = displayDelay; // Always apply the delay.
      const segment = questions[currentQuestionIndex];
      let currentIndex = 0; // Index for the current question within the segment.

      let qno = 0;

      // Function to display the next question within the segment.
      const displayNextQuestion = () => {
        if (currentIndex < segment.question.length) {
          const currentQuestion = segment.question[currentIndex];
          if (currentQuestion) {
            setChatHistory((prevChat) => [
              ...prevChat,
              { role: "bot", text: "Loading" },
            ]);
            setTimeout(() => {
              setChatHistory((prevChat) => {
                const updatedChat = [...prevChat];
                updatedChat.pop(); // Remove the last message, which is "Loading..."

                // Check if it's the last question in the group
                qno++;
                const isLastQuestion =
                  qno === segment.question.length ? segment.id : false;

                const newMessage = {
                  role: "bot",
                  text: currentQuestion,
                  last_question: isLastQuestion,
                };


                if (isLastQuestion !== false && uuid !== '' && segment.first_question !== true) {
                  // setshowTime(true);

                  journeyStatus({
                    slide_type:
                      questions[currentQuestionIndex].id === 'CALL_CLICK' ? 'button' : 'question',
                    slide_question: segment.id,
                    slide_value: '',
                    type: 'view',
                  });
                }else if(isLastQuestion == "CALL_CLICK"){
                  // setshowTime(true);
                }

                // // redirect to thankyou page when at call_click
                // if (segment.id === 'CALL_CLICK') {
                //   setTimeout(() => {
                //     history.push('/thankyou');
                //   }, 60000);
                // }
                return [...updatedChat, newMessage];
              });

              if (currentIndex === segment.question.length) {
                console.log(segment.options,segment.id,'4522');
                // Display options after all questions in the segment.
                if (segment.options && segment.options.length > 0  ) {
                  setRenderOption(true);
                }
                if(segment.options && segment.options.length > 0 && segment.timer == true){
                  setshowTime(true);
                }
              }
            }, displayDelay);
          }

          currentIndex++; // Move to the next question.
          setTimeout(
            displayNextQuestion,
            currentIndex === segment.question.length ? 0 : questionDelay
          ); // Apply the delay only after the last question.
        }
      }
      // Automatically initiate the conversation with the first question.
      const timer = setTimeout(displayNextQuestion, questionDelay);

      return () => clearTimeout(timer);
    };
    setUndoInitiated(false);
  }, [currentQuestionIndex, displayDelay, questions]);
  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -350;

      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });

    }
    else {
      window.scroll(0, 0);
    }
  }

  return (
    <div>
      {chatHistory.map((message, index) =>
        message.role === "bot" ? (
          <div key={index} className="chater-prof">
            <div onClick={toggleFocus()}></div>
            <div>
              {message.text === "Loading" || message.last_question === true ? (

                renderOption === true && questions[currentQuestionIndex].question.find((opt) => opt === message.text) ? (
                  <div
                    className="supporter-dp"
                    style={{ backgroundColor: "transparent", border: "none" }}
                  ></div>
                ) : (
                  <div className="supporter-dp">
                    <img src={avatar} alt="" />
                  </div>
                )
              ) : (
                <div
                  className="supporter-dp"
                  style={{ backgroundColor: "transparent", border: "none" }}
                ></div>
              )}
            </div>
            <div>
              {message.text === "Loading" ? (
                <div className="msg-loader loader1">
                  <div className="text-msg">
                    <img src={loader} alt="" />
                  </div>
                </div>
              ) : (
                <div className="mesg1">
                  <span
                    className={`text-msg-1 text-message-item slide-top display-block ${message.unique ? message.unique : ""
                      }`}
                  >
                    {message.text}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div key={index} className="client-replay my-3">
            <div onClick={toggleFocus()}></div>
            <div className="client-replay-vpx ">
              <div className="dp-client">
                <img src={avatar2} alt="" />
              </div>
              <div className="sel-option">
                <span className="action-btn text-message-item rounded answ-bnt">
                  {message.text}
                </span>
              </div>
            </div>
          </div>
        )
      )}
      <div ref={autofocus}></div>
      {!selectedOption && (
        <>
          {questions[currentQuestionIndex].options &&
            questions[currentQuestionIndex].options.length > 0 &&
            renderOption === true ? (
            <>
              <div className="chater-prof">
                <div className="supporter-dp" style={{}}>
                  <img src={avatar} alt="" />
                </div>
                <div className="mesg4 sel-option">
                  <div className={`text-msg-1 btn-spc text-center`}>
                    {questions[currentQuestionIndex].options.map(
                      (option, index) =>
                      questions[currentQuestionIndex].specialcss === 'QstNo22' ? (
                        <div className="mb-1" key={index}>
                          <span
                            className={`action-btn  text-message-item  medibutton  w-100 d-block`}
                            onClick={() => handleOptionSelect(option.text)}
                          >
                            {option.text}
                          </span>
                        </div>
                      ):questions[currentQuestionIndex].specialcss === 'verticleButton' ? (
                          <div className={index === 0 ? "mt-2 mb-1" : index === questions[currentQuestionIndex].options.length - 1 ? "" : "mb-1"} key={index}>
                            <span
                              className={`action-btn  text-message-item  medibutton  w-100 d-block`}
                              onClick={() => handleOptionSelect(option.text)}
                            >
                              {option.text}
                            </span>
                          </div>
                        ) : questions[currentQuestionIndex].id ==
                          "CALL_CLICK" || questions[currentQuestionIndex].id ==
                          "QstNo22" ? (
                            is_online == "online" ? (

                          <a
                            key={index}
                            className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                            onClick={() => { handleOptionSelect(option.text) }}
                            href={`tel: ${phoneNumber}`}
                          >
                            <span className='call icon_option_num icon_option_num_a text-white'>{formattedPhoneNumber}</span>
                          </a>
                            ):(
                              <div>
                                <a
                                  key={index}
                                  className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    handleRedirect(1,"call_offline");
                                  }}
                                  target="blank"
                                >
                                  <span className="call icon_option_num  icon_option_num_a text-white">
                                    Reclamar Beneficios
                                  </span>
                                </a>
                              </div>
                            )
                        ) : (
                          <span
                            key={index}
                            className={`action-btn text-message-item msg01 me-1`}
                            onClick={() => handleOptionSelect(option.text)}
                          >
                            {option.text}
                          </span>
                        )
                    )}
                  </div>
                  {showTime == true &&<div className="text-msg"><ChatBotTimer
                    timeLoad={showTime} minuteLoad="1" seconds="30"
                  /></div> }
                </div>

              </div>
            </>
          ) : null}
        </>
      )}
      <SimpleModal
        splitName="Spanish"
        modalBody={modalBody}
        setModalBody={setModalBody}
        ModalViewers={ModalViewers}
        showLoader={showLoader}
        buttonText="Reclamar Beneficios"
        undoChat={undoChat}
      />
      <DobModal
      dobModal={showDob}
      setShowdob={setShowdob}
      queryString = {decodedQueryString}
      uuid={uuid}
      />
    </div>
  );
};

export default ChatBotBubble_V11;
