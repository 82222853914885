import React, { useState } from "react";
import HeaderACA_SPH_V3 from "../../Includes/Layouts/Common/ACA_BQ_1/HeaderACA_SPH_V3";
import Footer from "../../Includes/Layouts/Common/Footer";
import FormSplit_ACA_SPH_V3 from "../../Forms/FormSplit_ACA_SPH_V3";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import SimpleModal from '../SimpleModal';
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import Spin from "../../../assets/img/Spin.gif";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import RingbaApi from "../../../Utility/RingbaApi";
import { UUID } from '../../../Utility/UUID';
import "../../../../src/assets/css/ACA_BQ_2.scss";

const ACA_SPH_V3 = () => {
    const [modalBody, setModalBody] = useState('');
    const { saveDataIngestion } = useDataIngestion();
    const [showLoader, setShowLoader] = useState("d-none");
    const query = new URLSearchParams(window.location.search);
    const { fetchUuid } = UUID();
    const uuid = fetchUuid();
    const campaign_name = "spanish";
    const { response } = RingbaApi(campaign_name);

    const ModalViewers = async (click_status) => {
        setShowLoader("d-block");
        const viewersSubmit = await saveDataIngestion(
            uuid,
            [click_status],
            "unqualify_modal",
            "ACA_SPH_V3",
        );
        window.location = UnqualifiedRedirectionLinkSpanish('').redirectionLink;
    }
    return (
        <>
            {showLoader == 'd-none' ? (
                <div className="ACA_BQ_2">
                    <AdtopiaLoadLP pageType="LP" splitName="ACA_SPH_V3" />
                    <HeaderACA_SPH_V3 />
                    <FormSplit_ACA_SPH_V3 splitName="ACA_SPH_V3" setModalBody={setModalBody} response={response} />
                    <Footer />
                    <SimpleModal
                        modalBody={modalBody}
                        setModalBody={setModalBody}
                        ModalViewers={ModalViewers}
                        showLoader={showLoader}
                    />
                </div>
            ) : (<div id="myDiv" className={`space text-center ${showLoader}`}>
                <img src={Spin} alt="" style={{ width: "150px", margintop: "30px" }} />
                <h3 className="p-0 text11"></h3>
            </div>)
            }

        </>
    )
}
export default ACA_SPH_V3;