import React, { useState, useEffect, useRef } from "react";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import OfflineSpanishButtonLinkSpanish from "../../../Utility/OfflineSpanishButtonLink";
import { QandA_SPR_V9 as QandA } from "../../../Constants/ChatbotConstant_V6B_MS";
import loaderreview2 from "../../../assets/img/SPR_V9/loader-review2.svg";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import { useHistory } from "react-router-dom";
import SimpleModal from "./SimpleModal";
import DobModal from "./DobModal";
import womensupport from "../../../assets/img/SPR_V9/women-support.gif";
import { PhoneNumberConstants } from "../../../Constants/PhoneNumberConstants";

const ChatBotBubble_SPR_V9 = ({
  formSubmit,
  splitName,
  uuid,
  journeyStatus,
  saveUser,
  is_online,
  buttonRedirect,
  phoneNumber,
  formattedPhoneNumber,
  showModal,
  setShowModal,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [modalBody, setModalBody] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [unqlifiedModalBody, setUnqlifiedModalBody] = useState("");
  const { saveDataIngestion } = useDataIngestion();
  const [optionvalue, setoptionvalue] = useState("");
  const autofocus = useRef();
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const decodedQueryString = decodeURIComponent(query.toString());
  const [showDob, setShowdob] = useState("hide");
  const [selectedOption, setSelectedOption] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const questions = QandA;

  useEffect(() => {
    localStorage.removeItem("questionData");
    const query = new URLSearchParams(window.location.search);
  }, []);

  // for journeyStatus view
  useEffect(() => {

    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;

    if (questionId && uuid) {
      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: "",
        type: "view",
      });
      if (selectedQuestion.has_call_button) {
        journeyStatus({
          slide_type: "button",
          slide_question: "CALL_CLICK",
          slide_value: "",
          type: "view",
        });
      }
    }
  }, [currentQuestionIndex]);
  useEffect(() => {
    if (optionvalue) {
      journeyStatus({
        slide_type: "question",
        slide_question: "basic_info",
        slide_value: "",
        type: "view",
      });
    }
  }, [optionvalue]);

  const ModalViewers = async (click_status) => {
    setShowLoader(true);
    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      "SPR_V9"
    );
    // window.location = unqualifiedLink.redirectionLink;
    window.location = UnqualifiedRedirectionLinkSpanish(
      questions[currentQuestionIndex].options.find(
        (opt) => opt.id === selectedOption
      ).id
    ).redirectionLink;
  };

  


  const handleOptionClick = (option) => {
    const updatedChatHistory = [...chatHistory];
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = option;
    const isQuestion15 =
      questionId === "9" && (optionId === "67" || optionId === "19");
    const medic = questionId === "9" && optionId === "18";

    if (questionId == 28 && optionId == 64) {
      if (is_online == "offline")
        return (window.location.href = buttonRedirect.buttonRedirectionLink);

      if (is_online === "online") {
        journeyStatus({
          slide_type: 'button',
          slide_question: 'CALL_CLICK',
          slide_value: '',
          type: 'click',
        });
      } 
      saveUser('CALL_CLICK', 1);

      window.location.href = `tel: ${phoneNumber}`;
    }


    const storedOptions = localStorage.getItem("questionData");
    const parsedOptions = storedOptions ? JSON.parse(storedOptions) : {};
    parsedOptions["question_" + questionId] = optionId;
    localStorage.setItem("questionData", JSON.stringify(parsedOptions));

    if (selectedQuestion.has_call_button) {
      // Find the selected option based on optionId
      const selectedOption = selectedQuestion.options.find(
        (option) => option.id === optionId
      );

      if (selectedOption.specialcss === "CALL_CLICK") {
        journeyStatus({
          slide_type: "button",
          slide_question: "CALL_CLICK",
          slide_value: "",
          type: "click",
        });
      }
    }
    journeyStatus({
      slide_type: "question",
      slide_question: questionId,
      slide_value: optionId,
      type: "click",
    });

    if (isQuestion15) {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      setModalBody(
        "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳"
      );

      return false;
    }

    if (questions[currentQuestionIndex].final_question === true) {
      if (optionId == "10") {
        setoptionvalue(optionId);
      } else {
        formSubmit(JSON.parse(localStorage.getItem("questionData")));
      }
    }
    if (questionId === "28" && optionId === "65") {
      var redirectUrl = `${window.location.origin}/greencard-thankyou?${decodedQueryString}`;
      window.location.href = redirectUrl;
      return false;
      // setCurrentQuestionIndex(currentQuestionIndex + 2);
    }

    if (currentQuestionIndex + 1 < questions.length) {
      if (medic) {
        setShowdob("show");
        return false;
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      if (
        !questions[currentQuestionIndex].multiple ||
        option === "multiple_continue"
        // && !questions[currentQuestionIndex].final_question
      ) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setAnsweredQuestions([...answeredQuestions, questionId]);
      }
    }
    setChatHistory(updatedChatHistory);
    setSelectedOption(option);
  };

  const [loaderIndex, setLoaderIndex] = useState(0);
  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        if (loaderIndex < 2) {
          setLoaderIndex((prevIndex) => {
            if (prevIndex) {
              journeyStatus({
                slide_type: "question",
                slide_question: 9,
                slide_value: "",
                type: "view",
              });
            }
            return prevIndex + 1;
          });
        }
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [loaderIndex, showModal]);

  const [hoverEffect, setHoverEffect] = useState("");

  return (
    <>
      {showModal && (
        <div
          className="modal fade show"
          id="pupup1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                />
              </div>
              <div className="modal-body text-center pt-0">
                <div className="qspart d-flex align-items-center justify-content-center p-3">
                  <div>
                    {loaderIndex === 0 && (
                      <img
                        id="loader1"
                        height={50}
                        src={loaderreview2}
                        alt=""
                      />
                    )}
                    {loaderIndex === 1 && (
                      <div className="agent-text">
                        <img height={150} src={womensupport} alt="" />
                        <h3 className="text1  mb-4">
                          {" "}
                          Estamos buscando un agente disponible...
                        </h3>
                      </div>
                    )}
                    {loaderIndex === 2 && (
                      <>
                        <div className="carousel-container slider">
                          <div className="carousel-wrapper">
                            <div className="carousel-slide">
                              {/*  */}
                              <div className="qs__item ">
                                <h2>
                                  {questions[currentQuestionIndex].question}
                                </h2>
                                {questions[currentQuestionIndex].dummyTest && (
                                  <p>
                                    {questions[currentQuestionIndex].dummyTest}
                                  </p>
                                )}
                                <div className="row">
                                  <div className="col-lg-12  ">
                                    <div className="row mt-2">
                                      {questions[
                                        currentQuestionIndex
                                      ].options.map((option, index) =>
                                        option.specialcss === "CALL_CLICK" ? (
                                          <div className="col-6" key={index}>

                                            <a
                                              key={index}

                                              
                                              className="btn-comm"
                                              onClick={() =>
                                                handleOptionClick(option.id)
                                              }
                                              href={`tel: ${phoneNumber}`}
                                            >
                                              {formattedPhoneNumber}
                                            </a>
                                          </div>
                                        ) : (
                                          <div
                                            key={index}
                                            className={`${
                                              questions[currentQuestionIndex]
                                                .specialcss === "verticleButton"
                                                ? "col-sm-12 mb-2"
                                                : "col-6"
                                            }`}
                                          >
                                            <button
                                              className={`btn-comm w-100 ${
                                                hoverEffect == option.id
                                                  ? "hover"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                handleOptionClick(option.id)
                                              }
                                              onMouseEnter={() =>
                                                setHoverEffect(`${option.id}`)
                                              }
                                              onMouseLeave={() =>
                                                setHoverEffect("")
                                              }
                                            >
                                              {option.text}
                                            </button>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {loaderIndex === 3 && (
                      <div className="qs__item discl-msg">
                        <h2 className="h2">
                          ¡Buenas noticias! Calificas para algunas ofertas
                          fantásticas. Toca el botón de abajo para
                          desbloquearlas, ¡y asegúrate de revisar cada
                          oferta!🤩🤩🥳🥳🥳
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SimpleModal
            splitName="Spanish"
            modalBody={modalBody}
            setModalBody={setModalBody}
            ModalViewers={ModalViewers}
            showLoader={showLoader}
            buttonText="Reclamar Beneficios"
          />
          <DobModal
            dobModal={showDob}
            setShowdob={setShowdob}
            queryString={decodedQueryString}
            uuid={uuid}
          />
        </div>
      )}
    </>
  );
};
export default ChatBotBubble_SPR_V9;
