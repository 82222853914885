import React, { useState, useContext, useEffect } from "react";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { useUserClicks } from "../../../Hooks/useUserClicks";
import { UUID } from "../../../Utility/UUID";
import RingbaApi from "../../../Utility/RingbaApi";
import "../../../assets/css/SPR_VD_V9/style.scss";
import vdo from "../../../assets/img/SPR_VD_V9/img/Vdo_1.mp4";
import vdobg from "../../../assets/img/SPR_VD_V9/img/video-bg.jpg";
import Footer from "../../Includes/Layouts/SPR_VD_V9_1/Footer";
import Header from "../../Includes/Layouts/SPR_VD_V9_1/Header";
import ChatBubble_SPR_VD_V9_1 from "./ChatBubble_SPR_VD_V9_1";
import ContentModal from "./ContentModal";

const SPR_VD_V9_1 = () => {
  const splitName = "SPR_VD_V9_1";

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();
  const campaign_name = "spanish";
  const { response } = RingbaApi(campaign_name);
  // const [muted,setMuted] =useState(true);
  const [videoControl, setVideoControl] = useState({
    muted: true,
    play: true
  });
  const [showContentModal, setShowContentModal] = useState(false);

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = "";
    values.lstSalutation = "";
    values.txtFName = "";
    values.txtLName = "";
    values.txtEmail = "";
    values.txtPhone = "";
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      "split_form_submit",
      splitName,
      queryString,
      visitorParameters.data
    );
  };



  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(
      uuid,
      slide_type,
      slide_question,
      slide_value,
      type,
      splitName
    );
  };

  const handleVideo = (control) => {
    switch (control) {
      case 'muted':
        setVideoControl(prevState => ({
          ...prevState,
          muted: !prevState.muted
        }));
        break;
      case 'play':
        const video = document.getElementById('mVideo');
        if (video.paused) {
          video.play();
          setVideoControl(prevState => ({
            ...prevState,
            play: true
          }));
        } else {
          video.pause();
          setVideoControl(prevState => ({
            ...prevState,
            play: false
          }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <AdtopiaLoadLP PageType="LP" splitName={splitName} />
      <FbViewPixel />
      <div className="SPR_VD_V9">
        <div className="wrap-content">
          <Header />
          <div className="video-post">
            <div
              className="volume"
              id="muteBtn"
              onClick={() => handleVideo('muted')}
            >
              <i className={`bi bi-volume-${videoControl.muted ? "down" : "mute"}-fill vlicon`} />
            </div>
            <div
              className="play-pause"
              onClick={() => handleVideo('play')}
            >
              <i className={`bi bi-${videoControl.play ? "pause" : "play"}-fill vlicon`} />{" "}
            </div>
            <video
              poster={vdobg}
              id="mVideo"
              autoPlay
              muted={videoControl.muted}
              loop
              onClick={() => handleVideo('muted')}
            >
              <source src={vdo} type="video/mp4" />
            </video>
          </div>
          <div className="text-center mb-2">
            <a className='text-decoration-underline'
              onClick={() => setShowContentModal(true)}>
              Haga clic aquí para ver el texto
            </a>
          </div>
          <ChatBubble_SPR_VD_V9_1
            formSubmit={formSubmit}
            journeyStatus={journeyStatus}
            saveUser={saveUser}
            uuid={uuid}
            response={response}
            splitName={splitName}
          />
        </div>
        <Footer />
        <ContentModal
          showContentModal={showContentModal}
          setShowContentModal={setShowContentModal}
          buttonText="cerrar"
        />
      </div>
    </>
  );
};

export default SPR_VD_V9_1;
