import React, { useState, useContext, useEffect } from 'react';
import Header from '../../Includes/Layouts/SPR_V5/Header';
// import Footer from "../../Includes/Layouts/SPR_V6B/Footer";
import Footer from '../../Includes/Layouts/Common/Footer';
import ChatBotBubble_SPR_V8_4C from '../../Pages/SPR_V8_4C/ChatBotBubble_SPR_V8_4C';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import useJourneyStats from '../../../Hooks/useJourneyStats';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import VisitorParamsContext from '../../../Contexts/VisitorParams';
import { queryString } from '../../../Utility/QueryString';
import FbViewPixel from '../../../Utility/FbViewPixel';
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from '../../../Utility/UUID';
import RingbaApi from '../../../Utility/RingbaApi';
import '../../../../src/assets/css/SPR_V8/chat.scss';

const SPR_V8_4C = () => {
  const splitName = 'SPR_V8_4C';

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const { saveUserClicks } = useUserClicks();
  const campaign_name = 'spanish';
  const { response } = RingbaApi(campaign_name);

  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  useEffect(() => {
    if (uuid) {
      (async () => {
        await saveJournyStats(uuid, 'question', '7', '', 'view', splitName);
      })();
    }
  }, [uuid]);

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  return (
    <>
      <AdtopiaLoadLP
        PageType='LP'
        splitName={splitName}
      />
      <FbViewPixel />
      <div className='SPR_V8_1'>
        <section className='Seguro__Latinos'>
          <div className='main_flic'>
            <Header />
            {/* HEADER  */}
          </div>
        </section>
        <section className='chat-section mb-5 mrgn-btm mt-3'>
          <div className='container'>
            <div className=' mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3'>
              <div className='chat-wrap'>
                <div className='mb-1 support-online pb-3 text-center'>
                  {' '}
                  <span className='circle pulse green me-1' /> Veronica esta disponible{' '}
                </div>
                <div className='chat-box-inner'>
                  {/* Chat rows */}
                  <div className='row-chart-message mb-4 '>
                    <ChatBotBubble_SPR_V8_4C
                      formSubmit={formSubmit}
                      journeyStatus={journeyStatus}
                      saveUser={saveUser}
                      uuid={uuid}
                      response={response}
                      splitName={splitName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default SPR_V8_4C;
