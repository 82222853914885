import React from "react";
import {Helmet} from "react-helmet";
// import logo from "../../../../../assets/img/ACA_BQ/logo.png";
import * as EnvConstants from "../../../../../../src/Constants/EnvConstants";
const EnvConstant = EnvConstants.AppEnv;
const HeaderACA_SPH_V3 = ()=>{
    return(
        
        <>{(EnvConstant == 'prod') ? < Helmet><script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
         <header>
    <div className="container">
      <div className="row">
        <div className="col-12">
          {/* <img src={logo} alt=""/> */}
        </div>
      </div>
    </div>
  </header>
        </>
    )
}
export default HeaderACA_SPH_V3;