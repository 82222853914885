import React, { useState, useEffect, useRef } from "react";
import { QandA_VD_V9 } from "../../../Constants/ChatbotConstant_V6B_MS";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import RingbaPhoneNumberSpanish from "../../../Utility/RingbaPhoneNumberSpanish";
import loader from "../../../assets/img/SPR_VD_V9/img/loader-review2.svg";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import SimpleModal from "./SimpleModal";

const ChatBubble_SPR_VD_V9_1 = ({
  formSubmit,
  journeyStatus,
  saveUser,
  uuid,
  response,
  splitName,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showSlide1, setShowSlide1] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [modalBody, setModalBody] = useState("");
  const { saveDataIngestion } = useDataIngestion();
  const [selectedOption, setSelectedOption] = useState("");
  const saveJournyStats = useJourneyStats();
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const autofocus = useRef();

  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName, splitName);

  const questions = QandA_VD_V9;

  const handleCallBtnClick = async () => {
    setShowSlide1(false);
    setShowLoader(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setShowLoader(false);
    setShowQuestion(true);

    await saveJournyStats(uuid, "question", "12", "", "view", splitName);
  };

  useEffect(() => {
    if (
      questions &&
      questions.length > 0 &&
      currentQuestionIndex >= 0 &&
      currentQuestionIndex < questions.length
    ) {
      const selectedQuestion = questions[currentQuestionIndex];
      const questionId = selectedQuestion.id;
      if (questionId != "12") {
        saveJournyStats(uuid, "question", questionId, "", "view", splitName);
      }
    }
  }, [questions, currentQuestionIndex, saveJournyStats, uuid, splitName]);

  useEffect(() => {
    localStorage.removeItem("questionData");
  }, []);

  const handleOptionClick = (option) => {
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = option;

    setCurrentQuestionIndex(currentQuestionIndex + 1);
    journeyStatus({
      slide_type: "question",
      slide_question: questionId,
      slide_value: optionId,
      type: "click",
    });

    setSelectedOption(optionId);

    if (questionId == "26" && optionId == "58") {
      journeyStatus({
        slide_type: "button",
        slide_question: "CALL_CLICK",
        slide_value: "",
        type: "click",
      });

      saveUser('CALL_CLICK', 1);

    } else if (questionId == "26" && optionId == "59") {
      setModalBody(
        "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳"
      );
    }

    var existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (existingEntries == null) existingEntries = {};
    var newEntries =
      existingEntries == null ? (existingEntries = {}) : existingEntries;
    Object.assign(newEntries, {
      ["question_" + questionId]: optionId,
    });
    localStorage.setItem("questionData", JSON.stringify(newEntries));


    if (questions[currentQuestionIndex].final_question === true) {
      formSubmit(JSON.parse(localStorage.getItem("questionData")));
    }


    setAnsweredQuestions([...answeredQuestions, questionId]);
  };

  const ModalViewers = async (click_status) => {
    setShowLoader(true);
    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      "SPR_VD_V9_1"
    );

    window.location = UnqualifiedRedirectionLinkSpanish("").redirectionLink;
  };

  const toggleFocus = (event) => {
    const scroll = window.screen.availWidth < 768 ? 110 : 200;

    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -250;

      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scroll(0, scroll);

    }
    else {
      window.scroll(0, scroll);
    }
  }
  return (
    <div>
      <div className="bottm-video-content py-5 py-lg-4 py-md-4 py-sm-5 text-center">
        {showSlide1 && (
          <div className="qs-slide1 qs-slide">
            <div className="mb-3">
              <button
                className="call-btn qs-next1"
                onClick={handleCallBtnClick}
              >
                Llama Ahora
              </button>
            </div>
            <h5> {formattedPhoneNumber}</h5>
            <div>TTY 711 | M-F 9AM - 9PM EST</div>
          </div>
        )}
        {showLoader && (
          <div className="qs-slide2 qs-slide">
            <div onClick={toggleFocus()}></div>
            <div className="qs-inner py-lg-4 py-md-4 py-sm-2">
              <div className="qs-loader">
                <img src={loader} alt="" />
                <h2 className="qs-text mt-3">Espere por favor...</h2>
              </div>
            </div>
          </div>
        )}
        {showQuestion && questions[currentQuestionIndex] && !showSlide1 && (
          <div className="qs-slide">
            <div className="qs-inner">
              <ul className="qs-status">
                {questions.map((question, index) => (
                  <li key={index}>
                    <span
                      className={
                        answeredQuestions.includes(question.id) ? "active" : ""
                      }
                    ></span>
                  </li>
                ))}
              </ul>
              <h2 className="qs-text" ref={autofocus}>
                {questions[currentQuestionIndex].question}
              </h2>
              <div className="row mt-4">
                <div className="col-lg-8 offset-lg-2">
                  <div className="row">
                    {questions[currentQuestionIndex].options.map((option) => (
                      questions[currentQuestionIndex].id ==
                        "26" && option.id == "58"
                        // && is_online == "online"
                        ? (
                          <div className="col-6" key={option.id}>
                            <div onClick={toggleFocus()}></div>
                            <a
                              className="btn-comm text-decoration-none text-white"
                              onClick={() => handleOptionClick(option.id)}
                              href={`tel: ${phoneNumber}`}
                            >
                              {option.text}
                            </a>
                          </div>
                        ) : (
                          <div className="col-6" key={option.id}>
                            <button
                              className="btn-comm"
                              onClick={() => handleOptionClick(option.id)}
                            >
                              {option.text}
                            </button>
                          </div>
                        )
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <SimpleModal
        modalBody={modalBody}
        setModalBody={setModalBody}
        ModalViewers={ModalViewers}
        showLoader={showLoader}
        buttonText="Reclamar Beneficios"
      />
    </div>
  );
};

export default ChatBubble_SPR_VD_V9_1;
