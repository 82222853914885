import React, { useState } from 'react'
import Footer from '../../Includes/Layouts/SPR_V5_1/Footer'
import FbViewPixel from '../../../Utility/FbViewPixel';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import FormSplitSPR_V5_1 from '../../Forms/FormSplitSPR_V5_1';
import RingbaApi from '../../../Utility/RingbaApi';
import SimpleModal_SPR_V5_1 from '../../Includes/Layouts/SPR_V5_1/SimpleModal_SPR_V5_1';
import UnqualifiedRedirectionLinkSpanish from '../../../Utility/UnqualifiedRedirectionLinkSpanish';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import "../../../../src/assets/css/SPR_V5/custom.scss"
const SPR_V5_1 = () => {
	const split_name = "SPR_V5_1";
	const query = new URLSearchParams(window.location.search);
	const { saveDataIngestion, isLoading } = useDataIngestion();
	const uuid = query.get("uuid");
	const campaign_name = "spanish";
	const { response } = RingbaApi(campaign_name);
	const [modalBody, setModalBody] = useState('');
	const ModalViewers = async (click_status) => {
		const viewersSubmit = await saveDataIngestion(
			uuid,
			[click_status],
			"unqualify_modal",
			"SPR_V5_1",
		);
		window.location = UnqualifiedRedirectionLinkSpanish('').redirectionLink;
	}
	return (
		<>
			<main className='SPR_V5'>
				<AdtopiaLoadLP pageType="LP" splitName={split_name} />
				<FbViewPixel />
				<section className="Seguro__Latinos">
					<div className="main_flic">
						<FormSplitSPR_V5_1
							splitName={split_name}
							setModalBody={setModalBody}
							response={response}
						/>
						<div className="col-12 mb-5 mt-5 spaced" style={{ display: "none" }}>
							&nbsp;
						</div>
						<Footer />
						<SimpleModal_SPR_V5_1
							modalBody={modalBody}
							setModalBody={setModalBody}
							ModalViewers={ModalViewers}
						/>
					</div>
				</section>
			</main>
		</>
	)
}

export default SPR_V5_1