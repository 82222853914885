import React from 'react';
import { Helmet } from 'react-helmet';
import * as EnvConstants from '../../../../../src/Constants/EnvConstants';
const EnvConstant = EnvConstants.AppEnv;

const Header = () => {
  return (
    <div>
      {/* {EnvConstant == 'prod' ? (
        <Helmet>
          <script
            src='//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab'
            async></script>
        </Helmet>
      ) : ( */}
      <Helmet>
        <script
          src='//b-js.ringba.com/CA13092be8a087445fbd60e0c91613e548'
          async></script>
      </Helmet>
      {/* )} */}

      <header>
        <h1>Llame a la Línea Directa del Seguro Médico</h1>
        <p>( ¿Si tiene Tarjeta Verde, es Ciudadano, es Residente o tiene Permiso de Trabajo? )</p>
      </header>
    </div>
  );
};

export default Header;
