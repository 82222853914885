import React, { useState, useEffect } from "react";
import Footer from "../../Includes/Layouts/SPR_V2/Footer";
import CallButtonSpanish from "../../Includes/Layouts/Common/ACA_BQ_1/CallButtonSpanish";
import Telephone from "../../../assets/img/ACA_BQ/telephone.png";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../Hooks/useJourneyStats";

import Time from "../../Includes/Layouts/SPR_V2/Time";
import RingbaApi from "../../../Utility/RingbaApi";
import OfflineSpanishButtonLink from "../../../Utility/OfflineSpanishButtonLink";

const SPR_V2_Thankyou = (props) => {
  const [showTime, setShowTime] = useState(false);
  const { saveDataIngestion } = useDataIngestion();
  const saveJournyStats = useJourneyStats();

  const campaign_name = "spanish";
  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const [buttonRedirect, setButtonRedirect] = useState();

  let is_online = response.is_online;
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  window.scrollTo(0, 0, { behavior: 'flash' });
  setTimeout(() => {
    setShowTime(true);
  }, 2000);


  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      "SPR_V2"
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        "SPR_V2"
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };


  return (
    <div className="SPR_V2">
      <section className="Thank_ACAques">
        <div className="ContThax_sec container">
          <div className="row">
            <div className="col-12">
              <div className="WeKXr_Bxt">
                <div className="Thank_conSec">
                  <h1>Felicidades, ¡Eres Elegible!</h1>
                  <h3>
                    <b>Último Paso:</b> Debes{" "}
                    <u>
                      <b>Llamar Ahora</b>
                    </u>{" "}
                    para obtener tu Póliza de Seguro Médico por $0. ¡Obtén
                    beneficios médicos para ti y tu familia gratuitos de este
                    Programa Federal!
                  </h3>
                  <h4>Llámanos ahora para obtener tu póliza gratuita</h4>
                  <h2>TOCA PARA LLAMAR</h2>
                  <div>




                    {is_online == 'online' ? (
                      <CallButtonSpanish
                        splitName="SPR_V2"
                        telephone={Telephone}

                      />
                    ) : (

                      <a

                        className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                        onClick={(event) => {
                          event.preventDefault();
                          handleRedirect(1, "call_offline");
                        }}
                        target="blank"
                      >
                        <span className="call icon_option_num icon_option_num_a text-white">
                          Reclamar Beneficios
                        </span>
                      </a>



                    )
                    }
                  </div>
                  <p className="__texNote">
                    ¡Actúa Rápido! La preaprobación termina en :{" "}
                    <span
                      id="countdown"
                      style={{ fontWeight: "bold", color: "red" }}
                    >

                      {showTime && <Time timeLoad={showTime} minuteLoad={2} />}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SPR_V2_Thankyou;
