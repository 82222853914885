import React, { useEffect, useState, useContext } from "react";
import telephone from "../../../../assets/img/SPH_CDV1/telephone.png";
import { PhoneNumberConstants } from "../../../../Constants/PhoneNumberConstants";
import useJourneyStats from "../../../../Hooks/useJourneyStats";
import { useUserClicks } from "../../../../Hooks/useUserClicks";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import { queryString } from "../../../../Utility/QueryString";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Time from "./Time";
import OfflineSpanishButtonLink from '../../../../Utility/OfflineSpanishButtonLink';
import RingbaPhoneNumberSpanish from "../../../../Utility/RingbaPhoneNumberSpanish"

const CallButton = (props) => {
    const saveJournyStats = useJourneyStats();
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const splitName = localStorage.getItem("split_name");
    const utmName = query.get("utm_campaign");
    const { saveDataIngestion } = useDataIngestion();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const { saveUserClicks } = useUserClicks()
    const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName);
    
    const [buttonRedirect, setButtonRedirect] = useState();
    let response = props.response;
    let is_online = response.is_online;
    let redirect = response.redirect_path;

    useEffect(() => {
        if (response) {
            const buttonofflineLink = OfflineSpanishButtonLink(redirect);
            setButtonRedirect(buttonofflineLink);
        }
    }, [response]);
    const handleClick = async (e, name) => {
        const clickSubmit = await saveUserClicks(
            uuid,
            name,
            1
        );
        await saveJournyStats(
            uuid,
            "BUTTON",
            "CALL_CLICK",
            "",
            "click",
            props.splitName
        );
        const formData = [];
        formData.lstSalutation = '';
        formData.txtFName = '';
        formData.txtLName = '';
        formData.txtEmail = '';
        formData.txtPhone = '';
        formData.page_name = props.splitName;
        const pixelSubmit = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "pixel_message",
            props.splitName,
            queryString,
            visitorParameters.data,
        )
    }

    const handleRedirect = async (click_status, click_value) => {
        const ringbaSubmit = await saveDataIngestion(
          uuid,
          [click_status, click_value],
          "offline",
          props.splitName
        );
        (async () => {
          await saveJournyStats(
            uuid,
            "BUTTON",
            "CALL_CLICK",
            "",
            "click",
            props.splitName
          );
        })();
        window.location.href = buttonRedirect.buttonRedirectionLink;
      };
    return (
        <>
            <section
                className={`text-center thankyou_sec ${props.showQuestionSlide}`}
                id="slide_thank"
            >

                <div className="container ">
                    <div className="row">
                        <div className={`col-lg-4 offset-lg-4 col-md-4 offset-md-4 col-sm-12 col-12 mt-3`}>
                            {props.badge == "d-block" && <div className="badge01">
                                <div className="content">

                                    <table className="table_1">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img src={props.contrypic} alt="" />
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>{props.countryRate}/mes*</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h5>Crédito fiscal de prima promedio en {props.getState}</h5>
                                </div>
                            </div>}
                        </div>
                        <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 col-12 mt-3">
                            <div className="Thank_conSec">
                                <b>100% complete</b>
                                <div className="progress-bar mb-4">
                                    <div
                                        className="progress"
                                        style={{ width: "100%", backgroundColor: "#1899D6" }}
                                    />
                                </div>
                                <h2>¡Felicidades!</h2>
                                <h4>¡Calificas para obtener hasta $5800 en Créditos de Salud!</h4>
                                <h4>
                                    Haz clic en el botón de abajo para conseguir tus beneficios.
                                </h4>
                                <br />
                                {is_online === 'online' ? (
                                    <a className="icon_option_num" onClick={(e) => handleClick(e, "call_click")} href={`tel: ${phoneNumber}`}>
                                        <span className="text-decoration-none text-white"><img className="me-3" src={telephone} />CALL : {formattedPhoneNumber}</span>
                                    </a>
                                ) : (
                                    // Render something else when is_online is not 'online'
                                    <a
                                        className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleRedirect(1, "call_offline");
                                        }}
                                        target="blank"
                                    >
                                        <span className="call icon_option_num icon_option_num_a text-white">
                                            Reclamar Beneficios
                                        </span>
                                    </a>
                                )}


                                <p className="txt_clr">
                                    {" "}
                                    <b>
                                        Tu lugar se mantendrá reservado durante: <span id="time" ><Time
                                            timeLoad={props.timeLoad} minuteLoad="1" /></span>
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CallButton;