const questinnare1   = {
    questionId:11,
    question : "¿A quién buscas Cobertura?",
    answers: [
            {
                label: "Mí mismo ",
                value: "24"
            },
            {
                label: "Yo y mi familia",
                value: "25", 
            }
    ]
  };
  const questinnare2   = {
    questionId:12,
    question : "¿Ya tienes seguro medico, Medicare o Medicaid?",
    answers: [
            {
                label: "No",
                value: "26"
            },
            {
              label: "Sí",
              value: "27",
          },
    ]
  };
  const questinnare3   = {
    questionId:9,
    question : "¿Cuál de los siguientes tienes?",
    answers: [
            {
                label: "Medicare",
                value: "18",
                modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
            },
            {
                label: "Medicaid",
                value: "19",
                modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",

            },
            {
                label: "Medicare & Medicaid",
                value: "20"
            },
            {
                label: "VA",
                value: "21"
            }
    ]
  };

  const questinnare4   = {
    questionId:13,
    question : "¿Tienes Green Card, eres Ciudano, eres Residente, o tienes permiso de trabajo?",
    answers: [
            {
                label: "Sí",
                value: "28",
               
            },
            {
                label: "No",
                value: "29"
            }
    ]
  };
 

  export { 
    questinnare1,
    questinnare2,
    questinnare3,
    questinnare4
  };
  