import React, { useState,useEffect } from "react";

import FormSplitSPR_V2 from "../../Forms/FormSplitSPR_V2";
import Footer from "../../Includes/Layouts/SPR_V2/Footer";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import SimpleModal from "../SimpleModal";
import FbViewPixel from '../../../Utility/FbViewPixel';
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import Spin from "../../../assets/img/Spin.gif";
import Header from "../../Includes/Layouts/SPR_V2/Header";
import "../../../../src/assets/css/SPR_V2.scss";

const SPR_V2 = () => {
  const [modalBody, setModalBody] = useState("");
  const {saveDataIngestion} = useDataIngestion();
  const [showLoader,setShowLoader] = useState("d-none");
  const query = new URLSearchParams(window.location.search);
  
  const uuid  = query.get("uuid");


    const ModalViewers =  async(click_status) =>{
      setShowLoader("d-block");
      const viewersSubmit = await saveDataIngestion(
             uuid,
             [click_status],
             "unqualify_modal",
             "SPR_V2",      
      );
      window.location = UnqualifiedRedirectionLinkSpanish('').redirectionLink;
    }                         
  return (
    <>
    {showLoader =='d-none' ? (
    
    <div className="SPR_V2"> 
      <AdtopiaLoadLP pageType="LP" splitName="SPR_V2" />
      <Header/>
      <FbViewPixel/>
      <section className="Spanish_ACAques">
        <div className="elementory_sec container">
          <div className="row">
            <div className="col-12">
              <div className="wrapUp_sec">
                <FormSplitSPR_V2
                  splitName="SPR_V2"
                  setModalBody={setModalBody}
                 
                />
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <SimpleModal
      modalBody={modalBody}
      setModalBody={setModalBody}
      ModalViewers={ModalViewers}
      showLoader = {showLoader}
      ModalButton="Reclamar Beneficios"
                />
    </div>
    ) : ( <div id="myDiv" className={`space text-center ${showLoader}`}>
        <img src={Spin} alt="" style={{ width: "150px", margintop: "30px" }} />
        <h3 className="p-0 text11"></h3>
    </div>)
    }
        
        </> 
  );
};

export default SPR_V2;
