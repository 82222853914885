import React from "react";

const SelectBox = ({
  className,
  OptionValue,
  onChange,
  name,
  id,
  myRef,
  validationMsg,
}) => {
  const OptionData = OptionValue.map((dobData, index) => {
    return (
      <option value={dobData.value} key={index}>
        {dobData.label}
      </option>
    );
  });
  return (
    <>
      <select className={className} name={name} ref={myRef} onChange={onChange} id={id}>
        {OptionData}
      </select>
      {validationMsg && (
        <>
          <span className="error_msg">{validationMsg}</span>
        </>
      )}
    </>
  );
};

export default SelectBox;
