import React, { useState, useContext, useEffect } from 'react';
import '../../../../src/assets/css/SPR_V9/custom.scss';
import li_checked from '../../../assets/img/SPR_V9/li_checked.png';
import bgpre1 from '../../../assets/img/SPR_V9/bg-pre1.jpg';
import Header from '../../Includes/Layouts/SPR_V9/Header';
import Footer from "../../Includes/Layouts/SPR_V9/Footer";
import { Accordion } from 'react-bootstrap';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import FbViewPixel from '../../../Utility/FbViewPixel';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import ChatBotBubble_SPR_V9 from '../SPR_V9/ChatBotBubble_SPR_V9';
import useJourneyStats from '../../../Hooks/useJourneyStats';
import VisitorParamsContext from '../../../Contexts/VisitorParams';
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from '../../../Utility/UUID';
import { useRingbaInfo } from '../../../Hooks/useRingbaInfo';
import RingbaApi from "../../../Utility/RingbaApi";
import OfflineSpanishButtonLink from "../../../Utility/OfflineSpanishButtonLink";
import RingbaPhoneNumberSpanish from "../../../Utility/RingbaPhoneNumberSpanish";

const SPR_V9 = () => {
  const campaign_name = 'spanish';
  const splitName = 'SPR_V9';

  const [showModal, setShowModal] = useState(false);
  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get('utm_campaign');
   const [buttonRedirect, setButtonRedirect] = useState();
   let is_online = response.is_online;
   let redirect = response.redirect_path;


  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName);

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };

  return (
    <>
      <div className='SPR_V9'>
        <div className='__ACAhome'>
          <Header />
          <AdtopiaLoadLP
            PageType='LP'
            splitName={splitName}
          />
          <FbViewPixel />
          <div
            id='preview-container'
            className='container-fluid hl_page-preview--content'>
            <div className='main_bdy'>
              <div
                className='fullSection'
                id='section-pre'>
                <div className='inner col-12'>
                  <div className='pre_contentH1'>
                    <h1>
                      Desbloquea hasta $7200 en Subsidios Fiscales para obtener un Seguro Medico por
                      $0 al mes!
                    </h1>
                    <p>
                      <img
                        src={li_checked}
                        alt=''
                      />
                      <span className='ps-1 sml-note'>Atención primaria ilimitada por $0</span>
                    </p>
                    <p>
                      <img
                        src={li_checked}
                        alt=''
                      />
                      <span className='ps-1 sml-note'>
                        {' '}
                        Atención urgente virtual ilimitada por $0
                      </span>
                    </p>
                    <p>
                      <img
                        src={li_checked}
                        alt=''
                      />
                      <span className='ps-1 sml-note'>
                        Gane recompensas por cuidar de su salud, hasta $100.
                      </span>
                    </p>
                  </div>
                  <div className='pre_btn_dialNum'>
                    <p className='text-center under-line fw-bold'>
                      {' '}
                      <u> Toque para llamar a la línea directa de seguros médicos</u>
                    </p>
                    <a href={`tel: ${phoneNumber}`} onClick={(e)=>{e.preventDefault()}} target=''>
                      <span className='main-heading-group'>
                        <button
                          className='main-heading-button buttonBounce custom_callBtn btn-vp'
                          id='justPopup'
                          onClick={() => setShowModal('d-block')}>
                          {' '}
                          llamar : {formattedPhoneNumber}
                        </button>
                      </span>
                    </a>
                  </div>
                  <div className='img_bgPic pt-3'>
                    <img
                      src={bgpre1}
                      alt=''
                    />
                  </div>
                  <div className='mt-lg-4 mt-md-4 mt-sm-4 mt-4 '>
                    <p>
                      Muchos latinos que viven en Estados Unidos no se dan cuenta de que pueden
                      calificar para una cobertura de salud de $0 al mes, incluso si aún no han
                      obtenido su ciudadanía. Únete a más de 35 millones de estadounidenses que
                      están reclamando este beneficio en el 2024.
                    </p>
                  </div>
                  <div className=' faq__accordion mt-4  mb-lg-5 mb-md-4 mb-sm-4 mb-5'>
                    <h3 className='mt-lg-4 mt-md-4 mt-sm-4 mt-3 mb-lg-4 mb-md-4 mb-sm-4 mb-3'>
                    Preguntas Frecuente
                    </h3>
                    <Accordion className='inner-faq'>
                      <Accordion.Item
                        className='faq__accordion-item'
                        eventKey='0'>
                        <Accordion.Header
                          className='faq__accordion-title'
                          id='headingOne'>
                          ¿Qué es Obamacare? <span className='icon'> </span>{' '}
                        </Accordion.Header>
                        <Accordion.Collapse
                          id='collapseOne'
                          eventKey='0'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'>
                          <Accordion.Body className=''>
                            <p>
                              Obamacare, conocido oficialmente como la Ley de Cuidado de Salud a
                              Bajo Precio (ACA por sus siglas en inglés), es una ley integral de
                              reforma del sistema de salud promulgada en marzo de 2010 durante la
                              presidencia de Barack Obama. Los objetivos principales de la ACA son
                              aumentar la calidad y asequibilidad del seguro de salud, reducir la
                              tasa de personas sin seguro mediante la expansión de la cobertura de
                              seguro y disminuir los costos de la atención médica para los
                              individuos y el gobierno.
                            </p>
                          </Accordion.Body>
                        </Accordion.Collapse>
                      </Accordion.Item>
                      <Accordion.Item
                        className='faq__accordion-item'
                        eventKey='1'>
                        <Accordion.Header
                          className='faq__accordion-title'
                          id='headingTwo'>
                          ¿Cuáles son los requisitos de inscripción de Obamacare?
                          <span className='icon'> </span>
                        </Accordion.Header>
                        <Accordion.Collapse
                          id='collapseTwo'
                          eventKey='1'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'>
                          <Accordion.Body className=''>
                            <p className='mb-0'>
                              {' '}
                              Tener un estatus migratorio en los Estados Unidos, o estar en proceso
                              de obtenerlo.
                            </p>
                            <p className='mb-0'>
                              No ser beneficiario de Medicare, Medicaid o CHIP.
                            </p>
                            <p className='mb-0'>
                              Cumplir al menos con el ingreso mínimo establecido.
                            </p>
                            <p className='mb-0'>No estar detenido en prisión.</p>
                          </Accordion.Body>
                        </Accordion.Collapse>
                      </Accordion.Item>
                      <Accordion.Item
                        className='faq__accordion-item'
                        eventKey='2'>
                        <Accordion.Header
                          className='faq__accordion-title'
                          id='headingThree'>
                          ¿Cuáles son los requisitos de inscripción de Obamacare?
                          <span className='icon'> </span>
                        </Accordion.Header>
                        <Accordion.Collapse
                          id='collapseThree'
                          eventKey='2'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'>
                          <Accordion.Body className=''>
                            <p>
                              Cuando seas elegible para un plan del Mercado de Seguros Médicos, el
                              gobierno ofrece un crédito fiscal o subsidio. Esto ayuda a reducir una
                              parte o la totalidad de la "Prima Mensual" de tu póliza de seguro de
                              salud. El monto del crédito fiscal depende de:
                            </p>
                            <ul className='list-items'>
                              <li>
                                La composición de tu unidad familiar según tu declaración de
                                impuestos.
                              </li>
                              <li>La edad de cada individuo que busca cobertura médica.</li>
                              <li>Los ingresos anuales de todos los miembros de la familia.</li>
                              <li>Tu código postal.</li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Collapse>
                      </Accordion.Item>
                      <Accordion.Item
                        className='faq__accordion-item'
                        eventKey='3'>
                        <Accordion.Header
                          className='faq__accordion-title'
                          id='headingFour'>
                          {' '}
                          ¿Puede negársele el seguro de salud debido a una condición preexistente?
                          <span className='icon'> </span>
                        </Accordion.Header>
                        <Accordion.Collapse
                          id='collapseFour'
                          eventKey='3'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'>
                          <Accordion.Body className=''>
                            <p>
                              {' '}
                              Esto depende del año de inscripción de la póliza de seguro médico. Si
                              la inscripción ocurrió en 2010 o después, por ley, el asegurador está
                              prohibido de negar la cobertura o aumentar las primas debido a
                              condiciones preexistentes. Sin embargo, si la inscripción ocurrió
                              antes de 2010, el individuo puede tener un plan "abuelo". Tales planes
                              podrían suspender la cobertura o aumentar las tarifas debido a
                              condiciones preexistentes.
                            </p>
                          </Accordion.Body>
                        </Accordion.Collapse>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  <div className='pre_btn_dialNum mb-4'>
                    <p className='text-center under-line fw-bold'>
                      {' '}
                      <u> Toque para llamar a la línea directa de seguros médicos</u>
                    </p>
                    <a href={`tel: ${phoneNumber}`} onClick={(e)=>{e.preventDefault()}} target=''>
                      <span className='main-heading-group'>
                        <button
                          className='main-heading-button buttonBounce custom_callBtn btn-vp'
                          id='justPopup'
                          onClick={() => setShowModal('d-block')}>
                          {' '}
                          llamar : {formattedPhoneNumber}
                        </button>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ChatBotBubble_SPR_V9
            splitName={splitName}
            formSubmit={formSubmit}
            journeyStatus={journeyStatus}
            saveUser={saveUser}
            uuid={uuid}
            redirect={redirect}
            is_online={is_online}
             buttonRedirect={buttonRedirect}
            phoneNumber={phoneNumber}
            formattedPhoneNumber={formattedPhoneNumber}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <Footer />
         
        </div>
      </div>
    </>
  );
};

export default SPR_V9;
