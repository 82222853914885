import React, { useState } from 'react'
import Header from "../../Includes/Layouts/SPR_FF_V2_1/Header";
import Footer from '../../Includes/Layouts/SPR_FF_V2_1/Footer';
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP';
import SimpleModal from '../SimpleModal';
import { useDataIngestion } from '../../../Hooks/useDataIngestion';
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import FormSPR_FF_V2_1 from "../../Forms/FormSPR_FF_V2_1";
import "../../../assets/css/SPR_FF_V2_1/custom.scss";


const SPR_FF_V2_1 = () => {
   
    const {saveDataIngestion} = useDataIngestion();
    const [modalBody, setModalBody] = useState('');
    const [optionValue, setOptionValue] = useState('');
    const query = new URLSearchParams(window.location.search);

  
    const uuid  = query.get("uuid");
    const ModalViewers =  async(click_status) =>{
        const viewersSubmit = await saveDataIngestion(
               uuid,
               [click_status],
               "unqualify_modal",
               "SPR_FF_V2_1",      
        );
        window.location = UnqualifiedRedirectionLinkSpanish(optionValue).redirectionLink;
      }

    return (
        <>
            <div className="SPH_CDV1">
            <AdtopiaLoadLP PageType="LP" splitName="SPR_FF_V2_1" />
            <Header />
            <FormSPR_FF_V2_1 splitName="SPR_FF_V2_1" setOptionValue={setOptionValue} setModalBody={setModalBody} />
            <Footer />
                <SimpleModal
                    modalBody={modalBody}
                    setModalBody={setModalBody}
                    ModalViewers={ModalViewers}
                    ModalButton="Reclamar Beneficios"
                />
            <script src="https://cdn.jsdelivr.net/npm/tsparticles-confetti@2.12.0/tsparticles.confetti.bundle.min.js"></script>

            </div>
        </>

    )
}

export default SPR_FF_V2_1;