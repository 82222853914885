import React, { useEffect, useState, useRef } from "react";
import { QandA_v5B } from "../../../Constants/ChatbotConstant_V6B_MS";

import loader from "../../../assets/img/SPR_V6B/loader.gif";
import avatar2 from "../../../assets/img/SPR_V6B/avatar2.png";
import avatar from "../../../assets/img/SPR_V6B/avatar.png";

import { PhoneNumberConstants } from "../../../Constants/PhoneNumberConstants";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import { useHistory } from "react-router-dom";
import SimpleModal from "./SimpleModal";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import RingbaPhoneNumberSpanish from "../../../Utility/RingbaPhoneNumberSpanish";
import OfflineSpanishButtonLink from "../../../Utility/OfflineSpanishButtonLink";
import Time from "../../Includes/Layouts/SPR_V5_1/Time";

const ChatBotBubble_SPR_V5_B = ({
  formSubmit,
  journeyStatus,
  saveUser,
  uuid,
  response,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [renderOption, setRenderOption] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [displayDelay, setDisplayDelay] = useState(2000); // Delay for questions
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const autofocus = useRef();
  const [modalBody, setModalBody] = useState("");
  const { saveDataIngestion } = useDataIngestion();
  const [showLoader, setShowLoader] = useState(false);
  const saveJournyStats = useJourneyStats();
  const history = useHistory();
  const [buttonRedirect, setButtonRedirect] = useState();
  let is_online = response.is_online;
  let redirect = response.redirect_path;
  const [time, setTime] = useState(false);

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } =
    RingbaPhoneNumberSpanish(utmName);

  const questions = QandA_v5B;

  const ModalViewers = async (click_status) => {
    setShowLoader(true);
    const viewersSubmit = await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      "SPR_V5B"
    );
    window.location = UnqualifiedRedirectionLinkSpanish(
      questions[currentQuestionIndex].options.find(
        (opt) => opt.text === selectedOption
      ).id
    ).redirectionLink;
  };
  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      "SPR_V5B"
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        "SPR_V5B"
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };

  const handleOptionSelect = (option) => {
    // Create a copy of the chat history with the user's response and the selected option.
    const updatedChatHistory = [...chatHistory];
    // Pass question id and option id to a function for sending to the backend.
    const selectedQuestion = questions[currentQuestionIndex];
    const questionId = selectedQuestion.id;
    const optionId = selectedQuestion.options.find(
      (opt) => opt.text === option
    ).id;

    // conditons can be set here based on questions or options
    const isQuestion9 =
      questionId === "9" &&
      (optionId === "18" ||
        optionId === "19" ||
        optionId === "20" ||
        optionId === "21");

    const isQuestion10 = questionId === "10" && optionId === "23";

    if (questionId === "CALL_CLICK") {
      journeyStatus({
        slide_type: "button",
        slide_question: "CALL_CLICK",
        slide_value: "",
        type: "click",
      });
      saveUser("CALL_CLICK", 1);
    } else if (isQuestion10 || isQuestion9) {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      journeyStatus({
        slide_type: "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      setModalBody(
        "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳"
      );

      return false;
    } else {
      updatedChatHistory.push({
        role: "user",
        text: option,
      });

      setChatHistory(updatedChatHistory);
      setSelectedOption(option);

      updatedChatHistory.forEach((message) => {
        if (message.last_question === questionId) {
          message.last_question = true;
        }
      });

      toggleFocus(questionId);

      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = {};
      var newEntries =
        existingEntries == null ? (existingEntries = {}) : existingEntries;
      Object.assign(newEntries, {
        ["question_" + questionId]: optionId,
      });
      localStorage.setItem("questionData", JSON.stringify(newEntries));

      journeyStatus({
        slide_type: questionId === "CALL_CLICK" ? "button" : "question",
        slide_question: questionId,
        slide_value: optionId,
        type: "click",
      });
      // Here, you can call a function to send questionId and optionId to the backend.
      // sendToBackend(questionId, optionId);
      if (questions[currentQuestionIndex].final_question === true) {
        setTime(true);
        formSubmit(JSON.parse(localStorage.getItem("questionData")));
      }
    }

    // Move to the next question or end the conversation when all questions are answered.
    if (currentQuestionIndex + 1 < questions.length) {
      if (questionId === "8" && optionId === "17") {
        setCurrentQuestionIndex(currentQuestionIndex + 2);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      setRenderOption(false);
      setSelectedOption(""); // Clear the selected option for the next question.
    } else {
      // Conversation ended. You can handle this as needed.
      // For example, display a closing message.
    }
  };
  useEffect(() => {
    localStorage.removeItem("selectedOptions");
  }, []);

  useEffect(() => {
    const questionDelay = displayDelay; // Always apply the delay.
    const segment = questions[currentQuestionIndex];
    let currentIndex = 0; // Index for the current question within the segment.

    let qno = 0;

    // Function to display the next question within the segment.
    const displayNextQuestion = () => {
      if (currentIndex < segment.question.length) {
        const currentQuestion = segment.question[currentIndex];
        if (currentQuestion) {
          setChatHistory((prevChat) => [
            ...prevChat,
            { role: "bot", text: "Loading" },
          ]);
          setTimeout(() => {
            setChatHistory((prevChat) => {
              const updatedChat = [...prevChat];
              updatedChat.pop(); // Remove the last message, which is "Loading..."

              // Check if it's the last question in the group
              qno++;
              const isLastQuestion =
                qno === segment.question.length ? segment.id : false;

              const newMessage = {
                role: "bot",
                text: currentQuestion,
                last_question: isLastQuestion,
              };

              if (
                isLastQuestion !== false &&
                uuid !== "" &&
                segment.first_question !== true
              ) {
                journeyStatus({
                  slide_type:
                    questions[currentQuestionIndex].id === "CALL_CLICK"
                      ? "button"
                      : "question",
                  slide_question: segment.id,
                  slide_value: "",
                  type: "view",
                });
              }

              return [...updatedChat, newMessage];
            });

            if (currentIndex === segment.question.length) {
              // Display options after all questions in the segment.
              if (segment.options && segment.options.length > 0) {
                setRenderOption(true);
              }
            }
          }, 2000);
        }

        currentIndex++; // Move to the next question.
        setTimeout(
          displayNextQuestion,
          currentIndex === segment.question.length ? 0 : questionDelay
        ); // Apply the delay only after the last question.
      }
    };

    // Automatically initiate the conversation with the first question.
    const timer = setTimeout(displayNextQuestion, questionDelay);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex, displayDelay, questions]);
  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -445;

      const scrollPosition =
        refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      window.scroll(0, 0);
    }
  };

  return (
    <div>
      {chatHistory.map((message, index) =>
        message.role === "bot" ? (
          <div key={index} className="chater-prof">
            <div onClick={toggleFocus()}></div>
            <div>
              {message.text === "Loading" || message.last_question === true ? (
                <div className="supporter-dp">
                  <img src={avatar} alt="" />
                </div>
              ) : (
                <div
                  className="supporter-dp"
                  style={{ backgroundColor: "transparent", border: "none" }}
                ></div>
              )}
            </div>
            <div>
              {message.text === "Loading" ? (
                <div className="msg-loader loader1">
                  <div className="text-msg">
                    <img src={loader} alt="" />
                  </div>
                </div>
              ) : (
                <div className="mesg1">
                  <span
                    className={`text-msg text-message-item slide-top ${
                      message.unique ? message.unique : ""
                    }`}
                  >
                    {message.text}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div key={index} className="client-replay my-3">
            <div onClick={toggleFocus()}></div>
            <div className="client-replay-vpx ">
              <div className="dp-client">
                <img src={avatar2} alt="" />
              </div>
              <div className="sel-option">
                <span className="action-btn text-message-item rounded answ-bnt">
                  {message.text}
                </span>
              </div>
            </div>
          </div>
        )
      )}
      <div ref={autofocus}></div>
      {!selectedOption && (
        <>
          {questions[currentQuestionIndex].options &&
          questions[currentQuestionIndex].options.length > 0 &&
          renderOption === true ? (
            <>
              <div className="chater-prof align-items-center">
                <div className="supporter-dp">
                  <img src={avatar} alt="" />
                </div>
                <div className="mesg4 sel-option d-flex flex-column">
                  <div className="text-msg btn-spc text-center">
                    {questions[currentQuestionIndex].options.map(
                      (option, index) =>
                        questions[currentQuestionIndex].specialcss ===
                        "verticleButton" ? (
                          <div className="mb-1" key={index}>
                            <span
                              className={`action-btn  text-message-item  medibutton  w-100 d-block`}
                              onClick={() => handleOptionSelect(option.text)}
                            >
                              {option.text}
                            </span>
                          </div>
                        ) : questions[currentQuestionIndex].id ==
                          "CALL_CLICK" ? (
                          is_online == "online" ? (
                            <a
                              key={index}
                              className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                              onClick={() => {
                                handleOptionSelect(option.text);
                              }}
                              href={`tel: ${phoneNumber}`}
                            >
                              <span className="call text-white">
                                {formattedPhoneNumber}
                              </span>
                            </a>
                          ) : (
                            <div>
                              <a
                                key={index}
                                className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none w-100 d-block"
                                onClick={(event) => {
                                  event.preventDefault();
                                  handleRedirect(1, "call_offline");
                                }}
                                target="blank"
                              >
                                <span className="call text-white">
                                  Reclamar Beneficios
                                </span>
                              </a>
                            </div>
                          )
                        ) : (
                          <span
                            key={index}
                            className={`action-btn text-message-item msg01 me-1`}
                            onClick={() => handleOptionSelect(option.text)}
                          >
                            {option.text}
                          </span>
                        )
                    )}
                  </div>

                  {time && (
                    <b className="fw-bolder">Tu lugar se mantendrá <br /> reservado durante:
                      {" "}
                      <span className="text-danger" id="time">
                        <Time timeLoad={time} minuteLoad="1" />
                      </span>
                    </b>
                  )}
               
                </div>
               
              </div>
         
            </>
          ) : null}
        </>
      )}
      <SimpleModal
        modalBody={modalBody}
        setModalBody={setModalBody}
        ModalViewers={ModalViewers}
        showLoader={showLoader}
        buttonText="Reclamar Beneficios"
      />
    </div>
  );
};

export default ChatBotBubble_SPR_V5_B;
