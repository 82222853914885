import React from "react";
import { useUserClicks } from "../../../../../Hooks/useUserClicks";
import { useDataIngestion } from "../../../../../Hooks/useDataIngestion";
import { useContext } from "react";
import { queryString } from "../../../../../Utility/QueryString";
import VisitorParamsContext from "../../../../../Contexts/VisitorParams";
import GTMDataLayer from "../../../../../Components/Includes/Layouts/Common/GTMDataLayer";
import useJourneyStats from "../../../../../Hooks/useJourneyStats";
import RingbaPhoneNumberSpanish from "../../../../../Utility/RingbaPhoneNumberSpanish";

const CallButtonSpanish = ({ telephone }, props) => {
    const { saveUserClicks } = useUserClicks();
    const { saveDataIngestion } = useDataIngestion();
    const saveJournyStats = useJourneyStats();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const splitName = localStorage.getItem("split_name");
    const utmName = query.get("utm_campaign");
    const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName);

    const handleClick = async (e, name) => {
        GTMDataLayer({
            question: "outbound click",
            answer: "",
        });
          await saveUserClicks(
            uuid,
            name,
            1
        );
        await saveJournyStats(
            uuid,
            "button",
            "call_click",
            "",
            "click",
            splitName
        );
        const formData = [];
        formData.lstSalutation = '';
        formData.txtFName = '';
        formData.txtLName = '';
        formData.txtEmail = '';
        formData.txtPhone = '';
        formData.page_name = 'ACA_BQ_V1';
           await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "pixel_message",
            "aca_bq",
            queryString,
            visitorParameters.data,
        )
    }
    return (
        <>

          <a className="call icon_option_num icon_option_num_a text-white" onClick={(e) => {handleClick(e, "call_click") }} href={`tel: ${phoneNumber}`}> <img src={telephone} alt="" />{formattedPhoneNumber}</a>

        </>
    )
}
export default CallButtonSpanish;