import React from 'react'
import logo from "../../../../assets/img/SPR_V9/logo.png";
import reviewstar from "../../../../assets/img/SPR_V9/review-star.png";
import { Helmet } from "react-helmet";
import * as EnvConstants from "../../../../../src/Constants/EnvConstants";
const EnvConstant = EnvConstants.AppEnv;

const Header = () => {
  return (
    <div>
    {(EnvConstant == "prod") ? <Helmet><script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}

    <header className="sticky-header">
      <div className="container top_notch">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-4 nav_barACA">
            <div className="img_slot">
              {/* <img src={logo} alt="" /> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-8 nav_btnACA ">
            <div className="btn_clickcall review-img">
              <img height={45} src={reviewstar} alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
    </div>
  )
}

export default Header