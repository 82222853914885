import React from 'react'
import { Helmet } from 'react-helmet'
import * as EnvConstants from "../../../../../src/Constants/EnvConstants";
const EnvConstant = EnvConstants.AppEnv;

const Header = ({ hideHeader }) => {
  return (
    <>
      {(EnvConstant == "prod") ? <Helmet>
        <script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script>
      </Helmet>:<Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
      <div className={`container tsec ${hideHeader}`}>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-11 col-12">
            <div className="inner_content text-center">
            <h2>
                Desbloquea hasta $7200 en Subsidios Fiscales para obtener un
                Seguro Medico por $0 al mes!
                </h2>
            </div>
            <div className="inner_bg">
              <p className="text-center m-0">
                <strong>Imagina lo que puedes hacer con los ahorros</strong>
              </p>
              <ol className="gradient-list">
                <li> Gasolina </li>
                <li> Renta </li>
                <li> Comida </li>
                <li> Disfrutar la vida </li>
              </ol>
              <p className="text-center mb-0">
                <strong>Vamos a Ver si Calificas </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header