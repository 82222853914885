import React, { useEffect, useState } from 'react'

const PageLoader = ({ setPageJourney }) => {
  const [step, setStep] = useState(0);
  const customTimeOut = [
    { timeout: 1500 },
    { timeout: 2700 },
  ];

  useEffect(() => {
    if (step < 2) {
      const timer = setTimeout(() => {
        setStep(step + 1);
      }, customTimeOut[step].timeout);

      return () => clearTimeout(timer);
    } else if (step === 2) {
      setPageJourney((prevPage) => prevPage + 1)
    }
  }, [step]);

  return (
    <>
      <div className="loader_full_Pg">
        <div id="wifi-loader">
          <svg className="circle-outer" viewBox="0 0 86 86">
            <circle className="back" cx={43} cy={43} r={40} />
            <circle className="front" cx={43} cy={43} r={40} />
            <circle className="new" cx={43} cy={43} r={40} />
          </svg>
          <svg className="circle-middle" viewBox="0 0 60 60">
            <circle className="back" cx={30} cy={30} r={27} />
            <circle className="front" cx={30} cy={30} r={27} />
          </svg>
          <svg className="circle-inner" viewBox="0 0 34 34">
            <circle className="back" cx={17} cy={17} r={14} />
            <circle className="front" cx={17} cy={17} r={14} />
          </svg>
          {step === 0 ?
            <div
              className={`text ${step === 0 ? "text_fade" : ""}`}
              data-text="Comprobando la disponibilidad del agente..."
            /> : null}
          {step === 1 ?
            <div
              className="text_2"
              data-text="Vamos a Ver si Calificas"
            /> : null}
        </div>
      </div>
    </>
  )
}

export default PageLoader