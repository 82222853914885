import React, { useState, useEffect } from 'react';
import LoaderImg from "../../../../assets/img/loader-review2.gif";
const Loader = (props) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    const showNextSlide = () => {
      if (currentSlide < 3) {
        setCurrentSlide(currentSlide + 1);
      } else {
        props.slideChange(null, "loader");
      }
    };

    const timeout = setTimeout(() => {
      showNextSlide();
    }, 2000);

    return () => clearTimeout(timeout);
  }, [currentSlide]);
  return (
    <>
      <div className="form_sec text-center  mb-5">
        <img className="form_sec text-center  mb-3 mt-5" src={LoaderImg} height={130}/> 
        </div>
      <div
        key={`slide_load${currentSlide}`}
        className={`slide_thk boxme animate fadeIn five mb-5`}
      >
        <div className="form_sec text-center mb-5">
          <p className="m-0">
            <strong>
              {currentSlide === 1 ? 'Revisando tus respuestas'
                : currentSlide === 2 ? 'Conexión al portal del programa'
                  : currentSlide === 3 ? 'Comprobación de programas locales'
                    : null
              }
            </strong>
          </p>
        </div>
      </div>
    </>
  )
}

export default Loader