import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import logo1 from "../../../../assets/img/SPR_WA_V11/logo-1.jpg";
import callicon from "../../../../assets/img/SPR_WA_V11/call.png";
import RingbaPhoneNumberSpanish from "../../../../Utility/RingbaPhoneNumberSpanish";
import OfflineSpanishButtonLink from "../../../../Utility/OfflineSpanishButtonLink";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";

const Header = (props) => {
    const [buttonRedirect, setButtonRedirect] = useState();

    const { saveDataIngestion } = useDataIngestion();
    // let is_online = props.response.is_online;
    let is_online = "online";
    // let redirect = props.response.redirect_path;
    // useEffect(() => {
    //     if (props.response) {
    //         const buttonofflineLink = OfflineSpanishButtonLink(redirect);
    //         setButtonRedirect(buttonofflineLink);
    //     }
    // }, [props.response]);
    // const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(props.utmName);
    const phoneNumber = "+18775070036";
    const formattedPhoneNumber = "877-507-0036";

    const handleclick = () => {
        props.journeyStatus({
            slide_type: 'button',
            slide_question: 'CALL_CLICK',
            slide_value: '',
            type: 'click',
        });
        props.saveUser('CALL_CLICK', 1);
    }
    const handleRedirect = async (click_status, click_value) => {
        const ringbaSubmit = await saveDataIngestion(
            props.uuid,
            [click_status, click_value],
            "offline",
            "SPR_WA_V11"
        );
        (async () => {
            props.journeyStatus({
                slide_type: 'button',
                slide_question: 'CALL_CLICK',
                slide_value: '',
                type: 'click',
            });
        })();
        window.location.href = buttonRedirect.buttonRedirectionLink;
    };
    return (
        <div>
            {/* <Helmet>
                <script
                    src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab"
                    async
                ></script>
            </Helmet> */}

            <header>
                <div className="container top_notch">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 nav_barACA">
                            <div className="img_slot">
                                <img src={logo1} alt="" />
                            </div>
                            <div className="logo_text">
                                <p>Seguro Para Latinos</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 nav_btnACA ">
                            <div className="btn_clickcall">
                                {is_online === "online" ? (
                                      <a
                                      className=""
                                      onClick={() => { handleclick() }}
                                      href={`tel: ${phoneNumber}`}
                                  >
                                      <span className='text-primary'><img className='' src={callicon} alt="" /><u>{formattedPhoneNumber}</u></span>
                                  </a>
                                ) : is_online === "offline" ? (
                                    <div>
                                    <a
                                        className="action-btn text-message-item medibutton jobBtn mx-1 text-decoration-none"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleRedirect(1, "call_offline");
                                        }}
                                        target="blank"
                                    >
                                        <span className="text-white btn btn-primary">
                                            Reclamar Beneficios
                                        </span>
                                    </a>
                                </div>
                                ) : (
                                    <div>
                                        
                                    {/* Add your code for the else condition here */}
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
