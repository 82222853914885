import React from "react";
import Accordion from 'react-bootstrap/Accordion';

const SplitFaq = (props) => {
    const FaqString = props.faq.map((faqs, index) => {
        return (
            <div className="faq__accordion-item" key={index}>
                <Accordion.Item className="accordion-item" eventKey={index}>
                    <Accordion.Header className="faq__accordion-title">
                        {faqs.heading}
                    </Accordion.Header>
                    <Accordion.Collapse eventKey={index} id="accor-1" className="accordion-collapse collapse">
                        <Accordion.Body className="accordion-body ">
                            <p>
                                <span dangerouslySetInnerHTML={{ __html: faqs.content }}></span>
                            </p>
                        </Accordion.Body>
                    </Accordion.Collapse>
                </Accordion.Item>
            </div>
        );
    });
    return (
        <div className="splitFaq">
            <div className="faq__accordion mt-4  mb-lg-5 mb-md-4 mb-sm-4 mb-5">
                <Accordion className="accordion-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 offset-lg-2">
                                <h4 className="text-center mb-4"><b>{props.accordionTitle}</b></h4>
                                {FaqString}
                            </div>
                        </div>
                    </div>
                </Accordion>
            </div>
        </div>
    );
};

export default SplitFaq;
