//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//////////////////////////////

export const QandA_v6B = [
  {
    id: "7",
    first_question: true,
    question: [`Hola!`, `¿Quieres ver si calificas?`],
    options: [{ id: "14", text: "Si, Vamos!" }],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para las facturas del hogar, el entretenimiento, los viajes y el ocio!`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

export const QandA_v4B = [
  {
    id: "22",
    first_question: true,
    question: [
      `Hola!`,
      `¿Quieres ver si calificas para obtener hasta $7,200 en subsidios fiscales?`,
    ],
    options: [{ id: "48", text: "Si, Vamos!" }],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

export const QandA_v5B = [
  {
    id: "21",

    question: [
      `Excelente, estoy aquí para ayudarte!`,
      `¿Ya pediste acceso a tu subsidio fiscal del 2024?`,
    ],
    specialcss: "verticleButton",
    options: [
      { id: "55", text: "Todavia no. Lo quiero hacer!" },
      { id: "50", text: "Si, ya he reclamado " },
    ],
  },
  {
    id: "23",
    question: [
      `Excelente! ¿También te gustaría obtener para ti y 
      tu familia cobertura médica de $0 al mes?`,
    ],
    final_question: true,
    options: [
      { id: "51", text: "Si" },
      { id: "52", text: "No" },
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      // `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      // `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      // `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`
      `Tengo un agente disponible en los próximos 2 minutos para inscribirlo a usted y a su familia en un seguro médico de $0 al mes y procesar su subsidio fiscal para 2024 hasta $6,800.`,
      `¡Toque el botón a continuación para llamar ahora y comenzar!`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

//// question SPR_V8_5

export const QandA_v85 = [
  {
    id: "8",
    question: [`Hola!`, `¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

/// question SPR_V8_6
export const QandA_v86 = [
  {
    id: "18",
    first_question: true,
    question: [`Hola!`, `Para quien buscas Cobertura?`],
    specialcss: "verticleButton",
    options: [
      { id: "40", text: "Para Mi" },
      { id: "41", text: "Mi Familia y Yo" },
    ],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

//question SPR_V8_7

export const QandA_v8_7 = [
  {
    id: "19",
    first_question: true,
    question: [`Hola!`, `¿Ya reclamaste tu subsidio fiscal de 2024?`],
    options: [
      { id: "42", text: "Si" },
      { id: "43", text: "No" },
    ],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

//question SPR_V8_8

export const QandA_v8_8 = [
  {
    id: "20",
    first_question: true,
    question: [
      `Hola!`,
      `¿Ya solicitaste acceso a tu subsidio fiscal del 2024?`,
    ],
    options: [
      { id: "44", text: "Si" },
      { id: "45", text: "No" },
    ],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

export const QandA_v8_9 = [
  {
    id: "21",
    first_question: true,
    question: [`Hola!`, `¿Ya pediste acceso a tu subsidio fiscal del 2024?`],
    options: [
      { id: "46", text: "Si" },
      { id: "47", text: "No" },
    ],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

export const QandA_v8_9B = [
  {
    id: "21",
    first_question: true,
    specialcss: "verticleButton",
    question: [`Hola!`, `¿Ya pediste acceso a tu subsidio fiscal del 2024?`],
    options: [
      {
        id: "49",
        text: "Aún no, por favor, ayúdeme a calcular a cuánto tengo derecho",
      },
      { id: "50", text: "Si, ya he reclamado" },
    ],
  },
  {
    id: "23",
    question: [
      `Excelente! ¿También te gustaría obtener para ti y tu familia cobertura médica de $0 al mes?`,
    ],
    options: [
      { id: "51", text: "Si" },
      { id: "52", text: "No" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

export const QandA_v8_4c = [
  {
    id: "7",
    first_question: true,
    question: [`Hola!`, `¿Quieres ver si calificas?`],
    options: [{ id: "14", text: "Si, Vamos!" }],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];

export const QandA_v8_42 = [
  {
    id: "7",
    first_question: true,
    question: [`Hola!`, `¿Quieres ver si calificas?`],
    options: [{ id: "14", text: "Si, Vamos!" }],
  },
  {
    id: "8",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "17", text: "No" },
      { id: "16", text: "Si" },
    ],
  },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "9",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "18", text: "Medicare" },
      { id: "19", text: "Medicaid" },
      { id: "20", text: "Medicare & Medicaid" },
      { id: "21", text: "VA" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "25",
    question: [
      `¡Felicidades! 🤩`,
      `Estás precalificado.`,
      `¿Puedes llamar y reclamar tus beneficios? Solo TE tomará de 2 a 3 minutos.`,
    ],
    final_question: true,
    options: [
      { id: "56", text: "Si" },
      { id: "57", text: "No" },
    ],
  },
];

export const QandA_VD_V9 = [
  {
    id: "12",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    specialcss: "verticleButton",
    options: [
      { id: "26", text: "No" },
      { id: "27", text: "Si" },
    ],
  },
  {
    id: "24",
    question: [
      `¿Eres Ciudadano o Residente, 
      tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    options: [
      { id: "53", text: "Si" },
      { id: "54", text: "No" },
    ],
  },
  {
    id: "26",
    question: [`¿Puedes llamar y confirmar tu subsidio?`],
    final_question: true,
    options: [
      { id: "58", text: "Si" },
      { id: "59", text: "No" },
    ],
  },
];


export const QandA_v10 = [
  {
    id: "7",
    question: [`Hola!`, `¿Quieres ver si calificas?`],
    first_question: true,
    options: [
      { id: "14", text: "Si, Vamos!" },
    ],
  },
  {
    id: "2",
    question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
    options: [
      { id: "3", text: "No" },
      { id: "4", text: "Sí" },
    ],
  },
  {
    id: "24",
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: "53", text: "Si" },
      { id: "54", text: "No" },
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  },
];


export const QandA_v8_s41 = [
  {
    id: "7",
    first_question: true,
    question: [`Hola!`, `¿Quieres ver si calificas?`],
    options: [{ id: "14", text: "Si, Vamos!" }],
  },
  // {
  //   id: "27",
  //   question: [`¿Ya tienes Seguro Medico, Medicare o Medicaid?`],
  //   options: [
  //     { id: "60", text: "No" },
  //     { id: "61", text: "Si" },
  //   ],
  // },
  // If Si, then below question else skip to last
  // show popup for every below option
  {
    id: "27",
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: "verticleButton",
    options: [
      { id: "60", text: "No tengo Seguro Medico" },
      { id: "61", text: "Obamacare" },
      { id: "62", text: "Medicare" },
      { id: "63", text: "Medicaid" },
      // { id: '34', text: "Other" },
    ],
  },
  {
    id: "10",
    question: [
      `¿Tienes Green Card, eres Ciudano, eres Residente, o tienes un Permiso para Trabajar en EE.UU?`,
    ],
    final_question: true,
    options: [
      { id: "22", text: "Si" },
      { id: "23", text: "No" },
      // show popup for no
    ],
  },
  {
    id: "CALL_CLICK",
    question: [
      `¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6,800 en subsidios fiscales para tu seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y lo que desees.`,
      `Toca el botón de abajo para llamar ahora y reclama tus beneficios, sólo te llevará de 2 a 3 minutitos.`,
    ],
    options: [{ id: "", text: "833-506-3463" }],
  }
  // ,
  // {
  //   id: "CALL_CLICK",
  //   question: [
  //     `¡Felicidades! 🤩🤩🥳🥳🥳`,
  //     `¡Calificas para una llamada de asesoramiento gratuita para conocer tus opciones y poder obtener un estatus migratorio legal!`,
  //     `Si te gusta lo que escuchas, este socio cuidadosamente seleccionado ofrece sus servicios a precios mucho más bajos que los que obtendrías de un bufete de abogados y estará contigo en cada paso del camino para cambiar tu vida.`,
  //     `Mantendremos la línea abierta durante 60 segundos.`,
  //   ],
  //   options: [{ id: "", text: "833-506-3463" }],
  // },
];

export const QandA_SPR_V9 = [
  
  {
    id: '9',
    question: [`¿Cuál de los siguientes tienes?`],
    specialcss: 'verticleButton',
    options: [
      { id: '66', text: "No tengo Seguro Medico" },
      { id: '67', text: "Obamacare" },
      { id: '18', text: "Medicare" },
      { id: '19', text: "Medicaid" },
    ],
  },

  {
    id: '28',
    question: [
      `¿Eres Ciudadano o Residente, tienes Green Card, tienes
      Permiso para Trabajar o estás en Proceso Migratorio?`,
    ],
    final_question: true,
    options: [
      { id: '64', text: 'Si' },
      { id: '65', text: 'No' },
    ],
  },
  // {
  //   id: '',
  //   question: [
  //     `¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳`,
  //   ],
  //   options: [],
  // },
];