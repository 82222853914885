import React from 'react'
import logo_1 from "../../../../assets/img/SPR_V10/logo-1.jpg"
import call from "../../../../assets/img/SPR_V10/call.png"
import { Helmet } from "react-helmet";
import * as EnvConstants from "../../../../../src/Constants/EnvConstants";

const Header = ({ phoneNumber, formattedPhoneNumber, handleOptionSelect, handleRedirect, is_online }) => {
  const EnvConstant = EnvConstants.AppEnv;
  return (
    <>{(EnvConstant == 'prod') ? <Helmet><script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
      <header>
        <div className="container top_notch">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6 nav_barACA">
              <div className="img_slot">
                <img src={logo_1} alt="" />
              </div>
              <div className="logo_text">
                <p>Seguro Para Latinos</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6 nav_btnACA ">
              <div className="btn_clickcall">
                {is_online == "online" ? (
                  <a
                    onClick={() => { handleOptionSelect("CALL_CLICK") }}
                    href={`tel: ${phoneNumber}`}
                  >
                    <span className='text-primary'><img src={call} alt="" /> <u>{formattedPhoneNumber}</u></span>
                  </a>
                ) : (
                  is_online == "offline" ? (
                    <a
                      className="btn btn-primary btn-sm offline_btn text-decoration-none"
                      onClick={(event) => {
                        event.preventDefault();
                        handleRedirect(1, "call_offline");
                      }}
                      target="blank"
                    >
                      <span className="fs-6 fw-normal text-white">
                        Reclamar Beneficios
                      </span>
                    </a>
                  ) : (null)
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header