const QandA = [
  {
    id: '5',
    question: [
      `Hola! ?`,
      `Soy Verónica con beneficios de salud`,
      `Desbloquea hasta $6200 en Subsidios Fiscales para el Seguro Médico. ¡Los latinos están utilizando los ahorros para Comestibles, Gasolina, Alquiler y más!`
    ],
    options: [
      { id: '9', text: "Si" },
      { id: '10', text: "No" },
    ],
  },
  {
    id: '4',
    question: [
      `Está bien, permíteme hacerte tres preguntas rápidas.`,
      `¿Ya tienes seguro médico?`
    ],
    options: [
      { id: '7', text: "Si" },
      { id: '8', text: "No" },
    ],
  },
  {
    id: '6',
    specialcss: 'verticleButton',
    question: [`¿Tienes Medicare o
    Medicaid?`],
    options: [
      { id: '11', text: "No" },
      { id: '12', text: "Medicaid" },
      { id: '13', text: "Medicare" },
    ],
  },
  // conditions apply below (only if no)
  {
    id: '3',
    // add "final_question" to the final question which will trigger DataIngestion
    final_question: true,
    question: [`¿Tienes un trabajo?`],
    options: [
      { id: '5', text: "Si" },
      { id: '6', text: "No" },
    ],
  },
  {
    id: 'CALL_CLICK',
    question: [`¡Felicidades! 🤩🤩🥳🥳🥳`,
      `Estás precalificado para recibir hasta $6200 en subsidios fiscales para el seguro médico.`,
      `Puedes utilizar los ahorros para necesidades como comestibles, gasolina y alquiler.`, `Toca el botón de abajo para llamar ahora y reclamar tus beneficios, solo te llevará de 2 a 3 minutitos.`
    ],
    options: [
      { id: 'ooo', text: "833-506-3463" },
    ],
  },
];


export default QandA;