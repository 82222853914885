import React from "react";
import { useUserClicks } from "../../../../Hooks/useUserClicks";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../../Hooks/useJourneyStats";
import { useContext } from "react";
import { useState, useEffect, } from "react";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { PhoneNumberConstants } from "../../../../Constants/PhoneNumberConstants";
import GTMDataLayer from "../Common/GTMDataLayer";
import { queryString } from "../../../../Utility/QueryString";
import * as EnvConstants from "../../../../../src/Constants/EnvConstants";

const Call_button = (props) => {
  const { saveUserClicks, isLoading } = useUserClicks();
  const { saveDataIngestion } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const utmName = query.get("utm_campaign");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const EnvConstant = EnvConstants.AppEnv;
  useEffect(() => {
    if (EnvConstant == "prod") {
      if (PhoneNumberConstants[utmName] == undefined || PhoneNumberConstants[utmName] == "") {
        setPhoneNumber(PhoneNumberConstants["default_SPH"]);
      } else {
        setPhoneNumber(PhoneNumberConstants[utmName]);
      }
    } else {
      setPhoneNumber(PhoneNumberConstants['default_local_NO']);
    }
  }, [utmName]);
  console.log(phoneNumber);
  useEffect(() => {
    if (phoneNumber) {
      const digits = phoneNumber.replace(/\D/g, '').slice(-10);
      if (digits.length === 10) {
        const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        setFormattedPhoneNumber(formattedNumber);
      }
    }
  }, [phoneNumber]);
  console.log(phoneNumber, utmName);

  const splitName = localStorage.getItem("split_name");

  const handleClick = async (e, name) => {
    GTMDataLayer({
      question: "Call now",
      answer: "",
    });
    const clickSubmit = await saveUserClicks(
      uuid,
      name,
      1
    );
    await saveJournyStats(
      uuid,
      "button",
      "call_click",
      "",
      "click",
      splitName
    );
    const formData = [];
    formData.lstSalutation = '';
    formData.txtFName = '';
    formData.txtLName = '';
    formData.txtEmail = '';
    formData.txtPhone = '';
    formData.page_name = 'SPR_SPH_V7_1';
    const pixelSubmit = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      "pixel_message",
      "aca_sph",
      queryString,
      visitorParameters.data,
    )
  }
  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      "SPR_SPH_V7_1"
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        "SPR_SPH_V7_1"
      );


    })();
    window.location.href = props.buttonRedirect.buttonRedirectionLink;
  }
  return (
    <div
      id="slide_thank"
      className={`slide_thk boxme animate fadeIn five ${props.showQuestionSlide}`}
    >
      <div className="form_sec text-center  mb-5">
        <p>
          <span className="tnk_head fw-bold">
            ¡Felicidades! 🤩🤩🥳🥳🥳
          </span>
        </p>
        <p className="attribute">
          Estás precalificado para recibir hasta $7200 en subsidios
          fiscales para el seguro médico.
        </p>
        <p>
          Toca el botón de abajo para llamar ahora y reclamar tus
          beneficios, solo te llevará de 2 a 3 minutitos.
        </p>

        {props.is_online == "online" && <a
          className="t2c_btn"
          id="clicktocallafterquiz"
          onClick={(e) => handleClick(e, "call_click")}
          href={`tel: ${phoneNumber}`}>

          {formattedPhoneNumber}
        </a>}
        {
          props.is_online == "offline" &&
          <a
            className="t2c_btn"
            id="clicktocallafterquiz"
            onClick={(event) => {
              event.preventDefault();
              handleRedirect(1, "call_offline");
            }}
          >
            Reclamar Beneficios
          </a>
        }
      </div>
    </div>
  )
}
export default Call_button