import React, { useReducer, useState, useEffect, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { queryString } from "../../Utility/QueryString"
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { questinnare1, questinnare2, questinnare3 } from "../../Constants/QuestionsSpanish";
import QuestionnaireRadioSlideSpanish from "../Includes/Layouts/Questionnaire/QuestionnaireRadioSlideSpanish";
import Spin from "../../assets/img/ACA_BQ/Spin.gif";
import banner_2 from "../../assets/img/ACA_BQ/banner_2.jpg";
import CallButtonSpanish from "../Includes/Layouts/Common/ACA_BQ_1/CallButtonSpanish";
import Telephone from "../../assets/img/ACA_BQ/telephone.png";
import GTMDataLayer from "../Includes/Layouts/Common/GTMDataLayer";
import useJourneyStats from "../../Hooks/useJourneyStats";
import OfflineSpanishButtonLink from "../../Utility/OfflineSpanishButtonLink";
const FormSplit_ACA_SPH_V3 = (props) => {
    const { register, errors, trigger, getValues } =
        useForm({
            mode: "onSubmit",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });
    const initialState = {
        showQuestionSlide1: "d-block",
        showQuestionSlide2: "d-none",
        showQuestionSlide3: "d-none",
    };
    const QuestionReducer = (state, action) => {
        switch (action.type) {
            case "nextQuestionSlide": {
                if (action.payload.clickedSlide.slide == "question_1") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_2") {
                    return {
                        ...state,
                        showQuestionSlide2: "d-none",
                        showQuestionSlide3: "d-block",
                    };
                } else if (action.payload.clickedSlide.slide == "question_3") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none"

                    };
                } else {
                    return {
                        ...state,
                    };
                }
            }
            case "backQuestionSlide": {
                if (action.payload.prevSlide.slide == "back2") {
                    return {
                        ...state,
                        showQuestionSlide1: "d-block",
                        showQuestionSlide2: "d-none",
                    };
                } else if (action.payload.prevSlide.slide == "back3") {
                    return {
                        ...state,
                        showQuestionSlide3: "d-none",
                        showQuestionSlide2: "d-block",
                    };
                }
                else {
                    return {
                        ...state,
                    };
                }
            }
        }
    };
    const [state, dispatch] = useReducer(QuestionReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState();
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [prevSlide, setPrevSlide] = useState();
    const [showSlide4, setShowSlide4] = useState("d-none");
    const [showLoader, setShowLoader] = useState("d-none");
    const splitForm = useRef(null);
    const [showTime, setShowTime] = useState(false);
    const { saveDataIngestion, isLoading } = useDataIngestion();
    const saveJournyStats = useJourneyStats();
    const [buttonRedirect, setButtonRedirect] = useState();

    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const locUUID = localStorage.getItem("uuid");

    let is_online = props.response.is_online;
    let redirect = props.response.redirect_path;
    useEffect(() => {
        if (props.response) {
          const buttonofflineLink = OfflineSpanishButtonLink(redirect);
          setButtonRedirect(buttonofflineLink);
        }
      }, [props.response]);

    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
        }
    }, [clickedSlide]);

    useEffect(() => {
        if (state.showQuestionSlide1 == "d-block") {
            if (locUUID) {
                (async () => {
                    await saveJournyStats(
                        uuid,
                        "question",
                        "1",
                        "",
                        "view",
                        props.splitName
                    );
                })();
            }
        }
    }, [locUUID])

    useEffect(() => {
        if (clickedSlide) {
            if (clickedSlide.slide == "question_1") {
                (async () => {
                    await saveJournyStats(
                        uuid,
                        "question",
                        "2",
                        "",
                        "view",
                        props.splitName
                    );
                })();
            }
            else if (clickedSlide.slide == "question_2") {
                (async () => {
                    await saveJournyStats(
                        uuid,
                        "question",
                        "3",
                        "",
                        "view",
                        props.splitName
                    );
                })();
            }
            else if (clickedSlide.slide == "question_3") {
                (async () => {
                    await saveJournyStats(
                        uuid,
                        "button",
                        "call_click",
                        "",
                        "view",
                        props.splitName
                    );
                })();
            }
        }
    }, [clickedSlide])

    useEffect(() => {
        if (prevSlide) {
            dispatch({ type: "backQuestionSlide", payload: { prevSlide } });
        }
    }, [prevSlide]);
    const gtmDataLayer = (current_question, element = null) => {
        let question = "";
        let answer = "";
        switch (current_question) {
            case "question_1":
                question = "Live place";
                break;
            case "question_2":
                question = "Old";
                break;
            case "question_3":
                question = "Eligibility";
                break;
        }
        GTMDataLayer({
            question: question,
            answer: answer,
        });
    };
    const saveJournyfun = async (e, radioName) => {
        const form = splitForm.current;
        let radio_id = e.target.dataset.question_id;
        var option_id = e.target.value;
        if (radioName == "question_1" || radioName == "question_2" || radioName == "question_3") {
            await saveJournyStats(
                uuid,
                "question",
                radio_id,
                option_id,
                "click",
                props.splitName
            );
        }
    }
    const slideChange = async (e, radioName) => {
        gtmDataLayer(e.target.name, e.target);
        const form = splitForm.current;
        const values = getValues();
        window.scrollTo(0, 0);
        setClickedSlide({ slide: radioName });
        let question_id = radioName;
        let optionId = form[radioName].value;
        let radio_id = e.target.dataset.question_id;
        var option_id = e.target.value;
        var existingEntries = JSON.parse(localStorage.getItem("questionData"));
        if (existingEntries == null) existingEntries = {};
        var newEntries =
            existingEntries == null ? (existingEntries = {}) : existingEntries;
        Object.assign(newEntries, {
            [question_id]: optionId,
        });
        localStorage.setItem("questionData", JSON.stringify(newEntries));

        if (radioName == "question_1" || radioName == "question_2" || radioName == "question_3") {
            await saveJournyStats(
                uuid,
                "question",
                radio_id,
                option_id,
                "click",
                props.splitName
            );
        }

        if (radioName == "question_3") {
            const formData = values;
            formData.lstSalutation = '';
            formData.txtFName = '';
            formData.txtLName = '';
            formData.txtEmail = '';
            formData.txtPhone = '';
            formData.page_name = 'ACA_SPH_V3'
            formSubmit(formData);
            setShowLoader("d-block");
            setTimeout(() => {
                setShowLoader("d-none");
                setShowSlide4("d-block");
                setShowTime(true);
            }, 2000);

        }
    }
    const previousSlide = (e) => {
        var previous_slide = e.target.id;
        setPrevSlide({ slide: previous_slide })
    }
    const formSubmit = async (formData) => {
        const formSUbmitResult = await saveDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "split_form_submit",
            "ACA_SPH_V3",
            queryString,
            visitorParameters.data
        );

    };

    const handleRedirect = async (click_status, click_value) => {
        const ringbaSubmit = await saveDataIngestion(
            uuid,
            [click_status, click_value],
            "offline",
            props.splitName
        );
        (async () => {
            await saveJournyStats(
                uuid,
                "BUTTON",
                "CALL_CLICK",
                "",
                "click",
                props.splitName
            );
        })();
        window.location.href = buttonRedirect.buttonRedirectionLink;
    };

    return (
        <>
            <section className="text-center slide_sec">
                <div className="container ">
                    <div className="slide_bg1 col-lg-10 offset-lg-1 col-md-12">
                        <form ref={splitForm}>
                            <QuestionnaireRadioSlideSpanish
                                showQuestionSlide={state.showQuestionSlide1}
                                questionnaire={questinnare1}
                                previousSlide={previousSlide}
                                slideChange={slideChange}
                                backBtn={false}
                                validation={register}
                                validationMsg={errors}
                                trigger={trigger}
                            />
                            <QuestionnaireRadioSlideSpanish
                                showQuestionSlide={state.showQuestionSlide2}
                                questionnaire={questinnare2}
                                previousSlide={previousSlide}
                                slideChange={slideChange}
                                setModalBody={props.setModalBody}
                                saveJournyfun={saveJournyfun}
                                backBtn={false}
                                validation={register}
                                validationMsg={errors}
                                trigger={trigger}
                            />
                            <QuestionnaireRadioSlideSpanish
                                showQuestionSlide={state.showQuestionSlide3}
                                questionnaire={questinnare3}
                                previousSlide={previousSlide}
                                slideChange={slideChange}
                                backBtn={false}
                                validation={register}
                                validationMsg={errors}
                                trigger={trigger}
                            />
                        </form>
                        <div id="myDiv" className={`space text-center ${showLoader}`}>
                            <img src={Spin} alt="" style={{ width: "150px", margintop: "30px" }} />
                            <h3 className="p-0 text11">Verificando la disponibilidad del agente.</h3>
                        </div>
                        <div id="slide_4" className={showSlide4}>
                            <div className="form_sec">
                                <img className="slid_img1" src={banner_2} alt="" />
                                <h4 className="mt-3">
                                    ¡Actúa rápido! Un agente está esperando tu llamada.
                                </h4>
                                <p className="mt-3 slid_txt1">Toca el botón naranja debajo para verificar los beneficios disponibles.</p>

                                {is_online === "online" && (
                                    <CallButtonSpanish splitName={props.splitName} telephone={Telephone} />
                                )}
                                {is_online === "offline" && (
                                    <div>
                                        <a className="call icon_option_num icon_option_num_a text-white" onClick={(event) => {
                                            event.preventDefault();
                                            handleRedirect(1, "call_offline");
                                        }}>Reclamar Beneficios
                                        </a>
                                    </div>
                                )}
                                <p className="slid_txt2">Al realizar una llamada a nuestro negocio, das tu consentimiento para recibir llamadas y mensajes de texto de marketing y no relacionados con marketing de nosotros, nuestros agentes y afiliados, utilizando un sistema automático de marcado telefónico y/o voz pregrabada, incluso si no proporcionas tu número de teléfono o estás en un registro gubernamental de "no llamar". No estás obligado/a a dar tu consentimiento para recibir llamadas como condición para hacer negocios con nosotros. Si en algún momento deseas dejar de recibir llamadas, contáctanos utilizando la información de contacto proporcionada en nuestro sitio web.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )

}
export default FormSplit_ACA_SPH_V3;