export const SplitFaq_V84_V6B = [
    {
        heading : "¿Qué es Obamacare?",
        content : "<p>Obamacare, conocido oficialmente como la Ley de Cuidado de Salud a Bajo Precio (ACA por sus siglas en inglés), es una ley integral de reforma del sistema de salud promulgada en marzo de 2010 durante la presidencia de Barack Obama. Los objetivos principales de la ACA son aumentar la calidad y asequibilidad del seguro de salud, reducir la tasa de personas sin seguro mediante la expansión de la cobertura de seguro y disminuir los costos de la atención médica para los individuos y el gobierno.</p>"
    },
    {
        heading: "¿Cuáles son los requisitos de inscripción de Obamacare?",
        content: "<p className='mb-0'>Tener un estatus migratorio en los Estados Unidos, o estar en proceso de obtenerlo.<br/> No ser beneficiario de Medicare, Medicaid o CHIP.<br/> Cumplir al menos con el ingreso mínimo establecido.<br/> No estar detenido en prisión.</p>"
      },
    {
        heading : "¿Cuáles son los requisitos de inscripción de Obamacare?",
        content : '<p>Cuando seas elegible para un plan del Mercado de Seguros Médicos, el gobierno ofrece un crédito fiscal o subsidio. Esto ayuda a reducir una parte o la totalidad de la "Prima Mensual" de tu póliza de seguro de salud. El monto del crédito fiscal depende de:</p><ul class="list-items"><li>La composición de tu unidad familiar según tu declaración de impuestos.</li><li>La edad de cada individuo que busca cobertura médica.</li><li>Los ingresos anuales de todos los miembros de la familia.</li><li>Tu código postal.</li></ul>'
    },
    {
        heading : "¿Puede negársele el seguro de salud debido a una condición preexistente?",
        content : '<p>Esto depende del año de inscripción de la póliza de seguro médico. Si la inscripción ocurrió en 2010 o después, por ley, el asegurador está prohibido de negar la cobertura o aumentar las primas debido a condiciones preexistentes. Sin embargo, si la inscripción ocurrió antes de 2010, el individuo puede tener un plan "abuelo". Tales planes podrían suspender la cobertura o aumentar las tarifas debido a condiciones preexistentes.</p>'
    }
];