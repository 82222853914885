import React, { useState } from 'react';

const ContentModal = ({ showContentModal, setShowContentModal, buttonText }) => {
  return (
    <>
      <div
        className={`modal fade unqlifiedmodal-pop ${showContentModal === false ? 'hide' : 'show'}`}
        tabIndex={-1}
        role='dialog'
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
        <div
          className='modal-dialog modal-dialog-centered modal-md'
          role='document'>
          <div className='modal-content bor-b5'>
            {/* <div className='modal-header noborder' /> */}
            <div className='modal-body'>
              <p className='text-start fw-normal mt-1'>
                ¿Cómo puedo ayudarle hoy?
                Eh, hola, estoy llamando cerca del subsidio de salud.
              </p>
              <p className='text-start fw-normal mt-1'>
                Sí, el subsidio 6800 para cubrir el seguro médico es real. Así que puedo ayudarte a calificar para él rápidamente. Primero tendremos que repasar un par de preguntas. No debería llevar más de unos minutos. ¿Está bien?
                Sí, ¿es claro, todo bien. Es cierto que voy a tener dinero para la gasolina, para los comestibles y también para el altler.
              </p>
              <p className='text-start fw-normal mt-1'>
                Eso es cierto para casi cualquier persona que aún no esté en Obamacare y tenga permiso para trabajar en los Estados Unidos, o esté en proceso de inmigración. Terminará pudiendo calificar para un subsidio de 6800 para cubrir el seguro médico, por lo que seguramente tendrá más dinero para gastar en alimentos y alquiler. ¿No? ¿Alguna otra pregunta antes de comenzar?<br />
                Eh, ok ok, pero me das tengo a mi hermana aquí conmigo. ¿Será que ella también puede obtener este subsidio de salud?
              </p>
              <p className='text-start fw-normal mt-1'>
                Por supuesto, le proporcionaremos su propio subsidio de 6800. Entonces, vamos a prepararlos ahora.
              </p>
            </div>
            <div className='modal-footer justify-content-center'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => setShowContentModal(false)}
              >
                {buttonText}
              </button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ContentModal;
