import React, { useEffect, useState } from "react";


const Time = (props) =>{
    const [milSec, setMilSec] = useState(99);
    const [second, setSecond] = useState(59);
    const [minute, setMinute] = useState(props.minuteLoad);
    useEffect(()=>{
        if(props.timeLoad == true){
            setInterval(() => {
                setMilSec((milSec)=>milSec - 1);
            }, 10);
            setInterval(() => {
                setMilSec(99);
                setSecond((second)=>second - 1);
            }, 1000);
            setInterval(()=>{
                setSecond(59);
            },60000);
            setInterval(()=>{
               setMinute((minute)=>minute - 1); 
            },60000)
           setInterval(()=>{
                setMinute(1);
           },120000)
        }
    },[props.timeLoad]);

    return(
        <>
        { props.timeLoad && <>{ minute<10 ? `0${minute}` : `${minute}` }:{ second<10 ?`0${second}`: `${second}`}</>}
        </>
    )
}
export default Time;