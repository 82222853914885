import React, { useEffect, useState } from 'react'
import Header from '../../Includes/Layouts/SPR_V10/Header'
import trustpilot from '../../../assets/img/SPR_V10/trustpilot.png'
import checked from '../../../assets/img/SPR_V10/checked.png'
import arrow from '../../../assets/img/SPR_V10/arrow.png'

import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import Accordion_V10_v11 from '../../Includes/Layouts/Common/Accordion_V10_v11';

const Landing_page = ({
  setPageJourney,
  journeyStatus,
  saveUser,
  uuid,
  splitName,
  pageJourney,

  is_online,
  buttonRedirect,
  phoneNumber,
  formattedPhoneNumber
}) => {
  const { saveDataIngestion } = useDataIngestion();
  const saveJournyStats = useJourneyStats();

  useEffect(() => {
    if (pageJourney === 1) {
      journeyStatus({
        slide_type: 'button',
        slide_question: 'CALL_CLICK',
        slide_value: '',
        type: 'view',
      });
      journeyStatus({
        slide_type: 'subsidy_button',
        slide_question: 'Claim_Subsidy',
        slide_value: '',
        type: 'view',
      });
    }
  }, [uuid, pageJourney]);


  const handleOptionSelect = (option) => {
    if (option === "CALL_CLICK") {
      journeyStatus({
        slide_type: 'button',
        slide_question: 'CALL_CLICK',
        slide_value: '',
        type: 'click',
      });
      saveUser('CALL_CLICK', 1);
    }
  };
  const handleRedirect = async (click_status, click_value) => {
    const ringbaSubmit = await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      splitName
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        splitName
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };

  const nextPageBtn = () => {
    journeyStatus({
      slide_type: 'subsidy_button',
      slide_question: 'Claim_Subsidy',
      slide_value: '',
      type: 'click',
    });

    const query = new URLSearchParams(window.location.search);
    query.set('currentPage', 'chatbot');
    window.history.replaceState({}, '', `${window.location.pathname}?${query.toString()}`);

    // Cleanup function to revert the URL on component unmount, if needed
    // return () => {
    //   query.delete('currentPage');
    //   window.history.replaceState({}, '', `${window.location.pathname}?${query.toString()}`);
    // };

    setPageJourney((prevPage) => prevPage + 1)
  }
  return (
    <>
      <div className="__SEGUROhome">
        <Header
          phoneNumber={phoneNumber}
          formattedPhoneNumber={formattedPhoneNumber}
          handleOptionSelect={handleOptionSelect}
          handleRedirect={handleRedirect}
          is_online={is_online}
        />
        <section className="cs_land_paGe">
          <div className="container-fluid p-0">
            <div className="bg_Br_vw">
              <div className="row tke_txT_Dwn">
                <div className="col-lg-8 col-md-8 col-12 txt_notice">
                  <div className="content">
                    <h2 className="lts_put_HeD">
                      ¿Ya ha obtenido su subsidio de $7200 para cubrir los costos del seguro de salud para el 2024?
                    </h2>
                    <p className="sub_bttm">
                      Muchos latinos que viven en Estados Unidos no se dan cuenta de que pueden calificar para una cobertura de salud de $0 al mes, incluso si aún no han obtenido su ciudadanía. Únete a más de 35 millones de estadounidenses que están reclamando este beneficio en el 2024.
                    </p>
                    <div className="dont_frgt_BTn">
                      <button
                        id="myButton"
                        className="btn button btn_trnOn efct_filed"
                        onClick={() => nextPageBtn()}
                      >
                        Reclamar Subsidio <img src={arrow} alt="" />
                      </button>
                    </div>
                  </div>
                  <div className="nite_kTm_hv">
                    <img src={trustpilot} alt="" />
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-12 ">
                  <div className="hr_tips_menu px-3">
                    Podría ser elegible para:
                  </div>
                </div>
                <div className="col-lg-9 col-12 pt-4 pb-4">
                  <ul className="pnts_By_PntS px-3">
                    <li className="pin_Here">
                      <img src={checked} alt="" />
                      <p>
                        <span>Atención primaria</span>
                        <br />
                        <span>ilimitada por $0</span>
                      </p>
                    </li>
                    <li className="pin_Here">
                      <img src={checked} alt="" />
                      <p>
                        <span>Atención urgente virtual</span>
                        <br />
                        <span>ilimitada por $0</span>
                      </p>
                    </li>
                    <li className="pin_Here">
                      <img src={checked} alt="" />
                      <p>
                        <span>Recetas médicas por</span>
                        <br />
                        <span>$3 en Walgreens</span>
                      </p>
                    </li>
                    <li className="pin_Here">
                      <img src={checked} alt="" />
                      <p>
                        <span>Gane recompensas por cuidar</span>
                        <br />
                        <span>de su salud, hasta $100.</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Accordion_V10_v11 />
      </div>
    </>
  )
}

export default Landing_page