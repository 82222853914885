import React, { useEffect, useState } from "react";

const ChatBotTimer = (props) => {
  const [seconds, setSeconds] = useState(90);
  const [millisecond, setMilliSecond] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (millisecond > 0) {
        setMilliSecond((millisec) => millisec - 1);
      }
      else
        if (seconds > 0) {
          setSeconds(prevSeconds => prevSeconds - 1);
          setMilliSecond(90);
        }
        else {
          setSeconds(90);
          setMilliSecond(0)
        }
    }, 10);

    return () => clearInterval(intervalId);
  }, [seconds, millisecond]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <>
      {/* { props.timeLoad && <span className="txt_clr" id="time" >{ minute<10 ? `0${minute}` : `${minute}` }:{ second<10 ?`0${second}`: `${second}`}:{milSec<10 ? `0${milSec}`:`${milSec}`}</span>} */}

      {props.timeLoad == true && <div><p className="text-start fw-bold mb-0"> <div className="fst-italic me-2">1 agente disponible</div><div className="fst-italic me-2">

        Cuenta regresiva desde:   <span className="ms-2 text-danger">{minutes < 10 ? "0" : ""}{minutes}:{remainingSeconds < 10 ? "0" : ""}

          {remainingSeconds}

        </span></div>  </p></div>}
    </>
  )
}
export default ChatBotTimer;