import React, { useState } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Footer from "../../Includes/Layouts/Common/Footer";
import Header from "../../Includes/Layouts/SPR_V1/Header";
import FormSplitSPR_V1 from "../../Forms/FormSplitSPR_V1";
import SimpleModal_SPR_V1 from "../SPR_V1/SimpleModal_SPR_V1";
import Spin from "../../../assets/img/ACA_BQ/Spin.gif";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import UnqualifiedRedirectionLinkSpanish from "../../../Utility/UnqualifiedRedirectionLinkSpanish";
import RingbaApi from "../../../Utility/RingbaApi";
import "../../../../src/assets/css/ACA_BQ_2.scss";
import FbViewPixel from "../../../Utility/FbViewPixel";

const SPR_V1 = () => {
    const [modalBody, setModalBody] = useState('');
    const [showLoader1, setShowLoader1] = useState("d-none");
    const { saveDataIngestion } = useDataIngestion();
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const campaign_name = "spanish";
    const { response } = RingbaApi(campaign_name);

    const ModalViewers = async (click_status) => {
        const viewersSubmit = await saveDataIngestion(
            uuid,
            [click_status],
            "unqualify_modal",
            "SPR_V1",
        );
        window.location = UnqualifiedRedirectionLinkSpanish('').redirectionLink;
    }

    return (
        <div className="ACA_BQ_2">
            <AdtopiaLoadLP pageType="LP" splitName="SPR_V1" />
            <Header/>
            <FbViewPixel/>
            {showLoader1 === 'd-none' ? (
                <>
                    <FormSplitSPR_V1 splitName="SPR_V1" setModalBody={setModalBody} response = {response} />
                    <Footer />
                    <SimpleModal_SPR_V1
                        modalBody={modalBody}
                        setModalBody={setModalBody}
                        setShowLoader1={setShowLoader1}
                        ModalViewers={ModalViewers}

                    />
                </>
            ) : (
                <>
                    <div id="myDiv" className={`space text-center ${showLoader1}`}>
                        <img className="loader-gif" src={Spin} alt="" style={{ width: "150px", margintop: "30px" }} />
                    </div>
                </>
            )}

        </div>
    )
}
export default SPR_V1;