import React, { useEffect, useState } from "react";

const Time = () => {
  const [milSec, setMilSec] = useState(0);
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(2); // Set the initial minute value to 2
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    if (timerActive) {
      const timer = setInterval(() => {
        if (milSec === 0 && second === 0 && minute === 0) {
          clearInterval(timer);
          setTimerActive(false);
        } else {
          if (milSec === 0) {
            if (second === 0) {
              setMinute((prevMinute) => prevMinute - 1);
              setSecond(59);
            } else {
              setSecond((prevSecond) => prevSecond - 1);
            }
            setMilSec(99);
          } else {
            setMilSec((prevMilSec) => prevMilSec - 1);
          }
        }
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
  }, [timerActive, milSec, second, minute]);

  const formatTime = (value) => (value < 10 ? `0${value}` : value);

  return (
    <>
      {timerActive && (
        <span className="txt_clr" id="time">
          {`${formatTime(minute)}:${formatTime(second)}`}
        </span>
      )}
    </>
  );
};

export default Time;
