import React, { useState } from "react";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import AdvHeader from "../../Includes/Layouts/SSPR_ADV1/AdvHeader";
import "../../../../src/assets/css/SSPR_ADV1/SSPR_ADV1.scss";
import AdvFooter from "../../Includes/Layouts/SSPR_ADV1/AdvFooter";
import AnchorAdv from "../../UI/AnchorAdv";
import Footer from "../../Includes/Layouts/SPR_V9/Footer";


const ADV_1 = () => {

    return (
        <>
            <AdtopiaLoadAP pageType="AP" splitName="SSPR_ADV1" />
            <div className="SSPR_ADV1">
                <AdvHeader />
                <section className="paralatinos">
                    <div className="elementory_sec container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h5>
                                    <b>Aquí te mostramos cómo obtener...</b>
                                </h5>
                                <div className="col-6 m-auto">
                                    <h5 className="dlr">
                                        $6400
                                    </h5>
                                </div>
                            </div>
                            <div className="col-12">
                                <p>
                                    ¿No tienes seguro de salud porque es demasiado caro y necesitas ese dinero para cosas importantes como alimentos y alquiler?
                                </p>
                                <h5 className="resuelto">
                                    <b>Problema Resuelto</b>
                                </h5>
                            </div>
                            <div className="col-12">
                                <p>
                                    Millones de latinos califican para un plan de $0/mes,   <AnchorAdv href="#" className="age-btn" dataId="MID_PARA1" ><u><b>¡solo necesitan llamar a este número para calificar!</b></u></AnchorAdv> Únete a los 35 millones de personas  que están reclamando este beneficio en 2024.
                                </p>
                                <h5 className="pb-1">
                                    <b>Para Calificar Debes:</b>
                                </h5>
                                <div className="calificar">
                                    <p>
                                        1. Tener permiso para trabajar en los Estados Unidos o estar en proceso de inmigración
                                    </p>
                                </div>
                                <div className="calificar">
                                    <p>
                                        2. NO estar ya inscrito en Seguro de Salud, Medicare o Medicaid
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 btn-div">
                                <h5 className="text-center py-2">
                                    <b>Selecciona Tu Edad:</b>
                                </h5>
                                <div className="row px-3 pb-3">
                                    <div className="col-4">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM1" >
                                            <button className="br-50">
                                                25-34
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-4">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM2">
                                            <button className="br-50">
                                                35-44
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-4">
                                        <AnchorAdv href="#" dataId="PARA_NUM3" className="age-btn">
                                            <button className="br-50">
                                                45-60
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 Cómo mt-4">
                                <h5>
                                    <b>Cómo Funciona el Programa del Gobierno 'Ley de Cuidado de Salud Asequible':</b>
                                </h5>
                                <p>
                                    1. <AnchorAdv href="#" className="age-btn" dataId="MID_PARA2" ><b><u>Llama a ESTE número</u></b></AnchorAdv> para hablar con un especialista que te calificará
                                </p>
                                <p>
                                    2. El especialista te inscribirá con un proveedor de seguro de salud
                                </p>
                                <p>
                                    3. El Gobierno 'subsidia' tu costo. (Ellos pagan a tu compañía de seguros por ti)
                                </p>
                                <p>
                                    4. Usa el dinero que ahorras en cosas importantes como las facturas del hogar, el entretenimiento, los viajes y el ocio!
                                </p>
                                <p>
                                    <b>
                                        Este programa está <span className="green-text">financiado con tus dólares ganados con esfuerzo.</span> ¡No cometas el error de perder este nuevo programa de estímulo para ti y tu familia!
                                    </b>
                                </p>
                            </div>
                            <div className="col-12 btn-div">
                                <h5 className="text-center py-2">
                                    <b>Para Obtener Este Beneficio, Toca Tu Edad:</b>
                                </h5>

                                <div className="mb-3 px-3">
                                    <AnchorAdv href="#" dataId="MID_PARA_NUM1" className="age-btn">
                                        <button>
                                            25-34
                                        </button>
                                    </AnchorAdv>
                                </div>
                                <div className="mb-3 px-3">
                                    <AnchorAdv href="#" dataId="MID_PARA_NUM2" className="age-btn">

                                        <button>
                                            35-44
                                        </button>
                                    </AnchorAdv>
                                </div>
                                <div className="mb-3 px-3">
                                    <AnchorAdv href="#" dataId="MID_PARA_NUM3" className="age-btn">
                                        <button>
                                            45-60
                                        </button>
                                    </AnchorAdv>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AdvFooter />
                <Footer/>
            </div>

        </>
    )

};

export default ADV_1;