import React from "react";
import { useUserClicks } from "../../../../Hooks/useUserClicks";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import { useContext } from "react";
import { queryString } from "../../../../Utility/QueryString";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import GTMDataLayer from "../Common/GTMDataLayer";
import useJourneyStats from "../../../../Hooks/useJourneyStats";
import RingbaPhoneNumberSpanish from "../../../../Utility/RingbaPhoneNumberSpanish";
import telephone_img from "../../../../assets/img/SPR_V5_1/Call.png";
const CallButtonSpanish_SPR_V5_1 = ({ telephone }, props) => {
  const { saveUserClicks, isLoading } = useUserClicks();
  const { saveDataIngestion } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const splitName = localStorage.getItem("split_name");
  const utmName = query.get("utm_campaign");
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumberSpanish(utmName);
  console.log(phoneNumber,formattedPhoneNumber,utmName,123);

  const handleClick = async (e, name) => {
    // if (name == "call_click") {
    //     window.location.href = `tel:${phoneNumber}`;
    // }
    GTMDataLayer({
      question: "outbound click",
      answer: "",
    });
    const clickSubmit = await saveUserClicks(
      uuid,
      name,
      1
    );
    await saveJournyStats(
      uuid,
      "button",
      "call_click",
      "",
      "click",
      splitName
    );
    const formData = [];
    formData.lstSalutation = '';
    formData.txtFName = '';
    formData.txtLName = '';
    formData.txtEmail = '';
    formData.txtPhone = '';
    formData.page_name = 'SPR_V5_1';
    const pixelSubmit = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      "pixel_message",
      "spr",
      queryString,
      visitorParameters.data,
    )
  }
  return (
    <>
      {/* <a className="t2c_btn  call icon_option_num icon_option_num_a text-white" onClick={(e) => { handleClick(e, "call_click") }} href={`tel: ${phoneNumber}`}><img className="me-2" src={telephone_img} alt="" />{formattedPhoneNumber}</a> */}
      <a className="t2c_btn  call icon_option_num icon_option_num_a text-white" onClick={(e) => { handleClick(e, "call_click") }} href={`tel: ${phoneNumber}`}>  <div className="p_relative d-flex justify-content-center" >  
                       <div className="p_relative pe-2">  
                       <img className="me-2 animate-shake" src={telephone_img} alt="" />
                        <span class="animate-ping p_absolute"> 
                        </span>
                       </div>
                        {formattedPhoneNumber}
                       </div></a>
    </>
  )
}
export default CallButtonSpanish_SPR_V5_1;