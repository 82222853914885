const questionnaire1 = {
  questionId: 12,
  question: "Ya tienes Medicare o Medicaid?",
  answers: [
    {
      label: "No",
      value: "26"
    },
    {
      label: "Sí",
      value: "27",

    }
  ]
};
const questionnaire2 = {
  questionId: 9,
  question: "¿Cuál de los siguientes tienes?",
  answers: [
    {
      label: "Medicare",
      value: "18",
      modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
    },
    {
      label: "Medicaid",
      value: "19",
      modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
    },
    {
      label: "Medicare & Medicaid",
      value: "20",
      modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
    },
    {
      label: "VA",
      value: "21",
      modalShow: "¡Buenas noticias! Calificas para algunas ofertas fantásticas.Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳",
    },
  ]
};
const questionnaire3 = {
  questionId: 10,
  question: "Tienes un permiso para trabajar en EE.UU?",
  answers: [
    {
      label: "Sí",
      value: "22"
    },
    {
      label: "No",
      value: "23"
    }
  ]
};


export {
  questionnaire1,
  questionnaire2,
  questionnaire3,
};
