import React, { useState } from 'react';
import loader from '../../../../assets/img/SPR_V3/loader.gif';

const Modal_SPR_V3 = ({ modalBody, setModalLoader, ModalViewers, modalLoader }) => {
  const modalFunction = () => {
    ModalViewers(1);
  }
  return (
    <>
      <div
        className={`modal fade unqlifiedmodal-pop ${modalBody === false ? 'hide' : 'show'}`}
        tabIndex={-1}
        role='dialog'
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}>
        <div
          className='modal-dialog modal-dialog-centered modal-md'
          role='document'>
          <div className='modal-content bor-b5'>
            {/* <div className='modal-header noborder' /> */}
            <div className='modal-body'>
              <p className='unqualify-modal-p text-center fw-bold mt-4'>
                ¡Buenas noticias! Calificas para algunas ofertas fantásticas.

                Toca el botón de abajo para desbloquearlas, ¡y asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳
              </p>
            </div>
            <div className='modal-footer justify-content-center'>
              {modalLoader === true ? (
                <span className='msg-loader'>
                  <img
                    src={loader}
                    alt=''
                  />
                </span>
              ) :
                <button
                  type='button'
                  className='btn btn-secondary btn-anime btn_reClaim'
                  data-dismiss='modal'
                  onClick={() => modalFunction()}>
                  Reclamar Beneficios
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal_SPR_V3;
