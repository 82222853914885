import React from "react";
import { Helmet } from "react-helmet";
// import logo from "../../../../../assets/img/ACA_BQ/logo.png";
const Header = () => {
    return (
        <>
            <Helmet>
                <script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script>
            </Helmet>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <img src={logo} alt=""/> */}
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header;