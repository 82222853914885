


import React from "react";
const QuestionnaireRadioSlideSpanish = (props) => {
  const question_id = props.questionnaire.questionId;
  const radioName = 'question_' + question_id;
  const answerBlock = props.questionnaire.answers.map((item, index) => {
    return (

      <React.Fragment key={index}>

       
  
          <input tabIndex="1"
            type="radio"
            className=" next01 sctop1 demo_12"
            id={'question_' + question_id + '_' + item.value}
            data-direction="next"
            name={radioName}
            data-question_id = {question_id}
            value={item.value} data-answertext={item.label}
            autoComplete="off"
            ref={props.validation({ required: "Please Select" })}
            // onClick={(e) => props.slideChange(e, radioName)}
            onClick={(e) => {
              if (item.modalShow) {
                props.setModalBody(item.modalShow);
                props.saveJournyfun(e, radioName);
              } else {
                props.slideChange(e, radioName);
              }
            }}
          />

<label className="icon_option text-white" htmlFor={'question_' + question_id + '_' + item.value} key={index}>
        
          <span>{item.label}</span>
        </label>
   </React.Fragment>

    )
  })
  return (
    <>
      <div id={'slide_1' + question_id} className={`${props.showQuestionSlide}`}>
        <div className="form_sec">
          {question_id == '1' &&
            <h5>Responde algunas preguntas para ver qué Beneficios de Salud están disponibles para ti.</h5>}
          <h2>{props.questionnaire.question}</h2>
          {answerBlock}
          {(question_id == '2' || question_id == '3' || question_id == '4') &&
            <input type="button" className="btn back_btn back02" id={`back${question_id}`} data-direction="prev" value="<< Atrás" name={`back${question_id}`} onClick={props.previousSlide} />}
        </div>
      </div>
    </>
  )
}
export default QuestionnaireRadioSlideSpanish;