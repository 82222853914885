import React, { useEffect, useState } from "react";

const Time = (props) => {
  const [milSec, setMilSec] = useState(99);
  const [second, setSecond] = useState(59);
  const [minute, setMinute] = useState(props.minuteLoad);

  useEffect(() => {
    let interval;

    if (props.timeLoad) {
      interval = setInterval(() => {
        if (milSec > 0) {
          setMilSec((ms) => ms - 1);
        } else {
          if (second > 0) {
            setSecond((s) => s - 1);
            setMilSec(99);
          } else {
            if (minute > 0) {
              setMinute((m) => m - 1);
              setSecond(59);
              setMilSec(99);
            } else {
              // Timer reached 00:00, reset to 01:30
              setMinute(props.minuteLoad);
              setSecond(59);
              setMilSec(99);
            }
          }
        }
      }, 10);
    }

    return () => clearInterval(interval);
  }, [props.timeLoad, milSec, second, minute]);

  return (
    <>
      {props.timeLoad && (
        <>
          {minute < 10 ? `0${minute}` : `${minute}`}:
          {second < 10 ? `0${second}` : `${second}`}
        </>
      )}
    </>
  );
};

export default Time;
