import Alabama from "../assets/img/SPH_CDV1/Country/Alabama.png";
import Alaska from "../assets/img/SPH_CDV1/Country/Alaska.png";
import Arizona from "../assets/img/SPH_CDV1/Country/Arizona.png";
import Arkansas from "../assets/img/SPH_CDV1/Country/Arkansas.png";
import California from "../assets/img/SPH_CDV1/Country/California.png";
import Colorado from "../assets/img/SPH_CDV1/Country/Colorado.png";
import Connecticut from "../assets/img/SPH_CDV1/Country/Connecticut.png";
import Delaware from "../assets/img/SPH_CDV1/Country/Delaware.png";
import Florida from "../assets/img/SPH_CDV1/Country/Florida.png";
import Georgia from "../assets/img/SPH_CDV1/Country/Georgia.png";
import Hawaii from "../assets/img/SPH_CDV1/Country/Hawaii.png";
import Idaho from "../assets/img/SPH_CDV1/Country/Idaho.png";
import Illinois from "../assets/img/SPH_CDV1/Country/Illinois.png";
import Indiana from "../assets/img/SPH_CDV1/Country/Indiana.png";
import Iowa from "../assets/img/SPH_CDV1/Country/Iowa.png";
import Kansas from "../assets/img/SPH_CDV1/Country/Kansas.png";
import Kentucky from "../assets/img/SPH_CDV1/Country/Kentucky.png";
import Louisiana from "../assets/img/SPH_CDV1/Country/Louisiana.png";
import Maine from "../assets/img/SPH_CDV1/Country/Maine.png";
import Maryland from "../assets/img/SPH_CDV1/Country/Maryland.png";
import Massachusetts from "../assets/img/SPH_CDV1/Country/Massachusetts.png";
import Michigan from "../assets/img/SPH_CDV1/Country/Michigan.png";
import Minnesota from "../assets/img/SPH_CDV1/Country/Minnesota.png";
import Mississippi from "../assets/img/SPH_CDV1/Country/Mississippi.png";
import Missouri from "../assets/img/SPH_CDV1/Country/Missouri.png";
import Montana from "../assets/img/SPH_CDV1/Country/Montana.png";
import Nebraska from "../assets/img/SPH_CDV1/Country/Nebraska.png";
import Nevada from "../assets/img/SPH_CDV1/Country/Nevada.png";
import New_Hampshire from "../assets/img/SPH_CDV1/Country/New_Hampshire.png";
import New_Jersey from "../assets/img/SPH_CDV1/Country/New_Jersey.png";
import New_Mexico from "../assets/img/SPH_CDV1/Country/New_Mexico.png";
import New_York from "../assets/img/SPH_CDV1/Country/New_York.png";
import North_Carolina from "../assets/img/SPH_CDV1/Country/North_Carolina.png";
import North_Dakota from "../assets/img/SPH_CDV1/Country/North_Dakota.png";
import Ohio from "../assets/img/SPH_CDV1/Country/Ohio.png";
import Oklahoma from "../assets/img/SPH_CDV1/Country/Oklahoma.png";
import Oregon from "../assets/img/SPH_CDV1/Country/Oregon.png";
import Pennsylvania from "../assets/img/SPH_CDV1/Country/Pennsylvania.png";
import Rhode_Island from "../assets/img/SPH_CDV1/Country/Rhode_Island.png";
import South_Carolina from "../assets/img/SPH_CDV1/Country/South_Carolina.png";
import South_Dakota from "../assets/img/SPH_CDV1/Country/South_Dakota.png";
import Tennessee from "../assets/img/SPH_CDV1/Country/Tennessee.png";
import Texas from "../assets/img/SPH_CDV1/Country/Texas.png";
import Utah from "../assets/img/SPH_CDV1/Country/Utah.png";
import Vermont from "../assets/img/SPH_CDV1/Country/Vermont.png";
import Virginia from "../assets/img/SPH_CDV1/Country/Virginia.png";
import Washington from "../assets/img/SPH_CDV1/Country/Washington.png";
import West_Virginia from "../assets/img/SPH_CDV1/Country/West_Virginia.png";
import Wisconsin from "../assets/img/SPH_CDV1/Country/Wisconsin.png";
import Wyoming from "../assets/img/SPH_CDV1/Country/Wyoming.png";


const country = 
    [{
"": { Image : California  , Price :"$476"},
"default":  {  Image : California  , Price :"$476"},
"Alabama":  { Image : Alabama , Price :"$713"},
"Alaska": { Image : Alaska  , Price :"$695"},
"Arizona":  { Image : Arizona , Price :"$449"},
"Arkansas": { Image : Arkansas  , Price :"$452"},
"California": { Image : California  , Price :"$476"},
"Colorado": { Image : Colorado  , Price :"$374"},
"Connecticut":  { Image : Connecticut , Price :"$657"},
"Delaware": { Image : Delaware  , Price :"$607"},
"Florida":  { Image : Florida , Price :"$553"},
"Georgia":  { Image : Georgia , Price :"$466"},
"Hawaii": { Image : Hawaii  , Price :"$576"},
"Idaho":  { Image : Idaho , Price :"$454"},
"Illinois": { Image : Illinois  , Price :"$509"},
"Indiana":  { Image : Indiana , Price :"$459"},
"Iowa": { Image : Iowa  , Price :"$598"},
"Kansas": { Image : Kansas  , Price :"$532"},
"Kentucky": { Image : Kentucky  , Price :"$485"},
"Louisiana":  { Image : Louisiana , Price :"$658"},
"Maine":  { Image : Maine , Price :"$471"},
"Maryland": { Image : Maryland  , Price :"$375"},
"Massachusetts":  { Image : Massachusetts , Price :"$377"},
"Michigan": { Image : Michigan  , Price :"$392"},
"Minnesota":  { Image : Minnesota , Price :"$343"},
"Mississippi":  { Image : Mississippi , Price :"$570"},
"Missouri": { Image : Missouri  , Price :"$548"},
"Montana":  { Image : Montana , Price :"$501"},
"Nebraska": { Image : Nebraska  , Price :"$615"},
"Nevada": { Image : Nevada  , Price :"$435"},
"New Hampshire":  { Image : New_Hampshire , Price :"$337"},
"New Jersey": { Image : New_Jersey  , Price :"$490"},
"New Mexico": { Image : New_Mexico  , Price :"$472"},
"New York": { Image : New_York  , Price :"$362"},
"North Carolina": { Image : North_Carolina  , Price :"$581"},
"North Dakota": { Image : North_Dakota  , Price :"$452"},
"Ohio": { Image : Ohio  , Price :"$439"},
"Oklahoma": { Image : Oklahoma  , Price :"$559"},
"Oregon": { Image : Oregon  , Price :"$491"},
"Pennsylvania": { Image : Pennsylvania  , Price :"$500"},
"Rhode Island": { Image : Rhode_Island  , Price :"$402"},
"South Carolina": { Image : South_Carolina  , Price :"$513"},
"South Dakota": { Image : South_Dakota  , Price :"$642"},
"Tennessee":  { Image : Tennessee , Price :"$545"},
"Texas":  { Image : Texas , Price :"$504"},
"Utah": { Image : Utah  , Price :"$370"},
"Vermont":  { Image : Vermont , Price :"$566"},
"Virginia": { Image : Virginia  , Price :"$508"},
"Washington": { Image : Washington  , Price :"$421"},
"West Virginia":  { Image : West_Virginia , Price :"$1,007"},
"Wisconsin":  { Image : Wisconsin , Price :"$531"},
"Wyoming":  { Image : Wyoming , Price :"$531"}

}]
export default country;
    
