import React, { useReducer, useState, useEffect, useRef, useContext } from "react";
import arrow_down from "../../assets/img/SPR_V5/arrow-down.png";
import { useForm } from "react-hook-form";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import { queryString } from "../../Utility/QueryString"
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { questionnaire1, questionnaire2, questionnaire4 } from "../../Constants/QuestionsSpanish_SPR_V5";
import CallButtonSpanishSprV5 from "../Includes/Layouts/SPR_V5/CallButtonSpanish_SPR_V5";
import GTMDataLayer from "../Includes/Layouts/Common/GTMDataLayer";
import useJourneyStats from "../../Hooks/useJourneyStats";
import QuestionnaireRadioSlideSpanishSprV5 from "../Includes/Layouts/Questionnaire/QuestionnaireRadioSlideSpanish_SPR_V5";
import Loader from "../Includes/Layouts/SPR_V5/Loader";
import Header from "../Includes/Layouts/SPR_V5/Header";
import OfflineSpanishButtonLink from "../../Utility/OfflineSpanishButtonLink";
const FormSplitSPR_V5 = (props) => {
  const [hideHeader, setHideHeader] = useState("d-block");


  const { register, errors, trigger, getValues } =

    useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const initialState = {
    showQuestionSlide1: "d-block",
    showQuestionSlide2: "d-none",
    showQuestionSlide3: "d-none",
    loader: "d-none",
    thank_you: "d-none",
  };

  const QuestionReducer = (state, action) => {
    const { clickedSlide } = action.payload;
    switch (action.type) {
      case "nextQuestionSlide": {
        const { slide, value } = clickedSlide;
        const updatedState = {
          showQuestionSlide1: "d-none",
          showQuestionSlide2: "d-none",
          showQuestionSlide3: "d-none",
          loader: "d-none",
          thank_you: "d-none",
        };

        if (slide === "question_12" && value === "26") updatedState.showQuestionSlide3 = "d-block";
        else if (slide === "question_12" && value === "27") updatedState.showQuestionSlide2 = "d-block";
        else if (slide === "question_9") updatedState.loader = "d-block";
        else if (slide === "question_10") {
          updatedState.loader = "d-block";
          setHideHeader("d-none")
        }
        else if (slide === "loader") updatedState.thank_you = "d-block";

        return { ...state, ...updatedState };
      }

      default: {
        return state;
      }
    }
  };
  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const splitForm = useRef(null);
  const { saveDataIngestion } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const query = new URLSearchParams(window.location.search);
  const uuid = query.get("uuid");
  const locUUID = localStorage.getItem("uuid");
  const [buttonRedirect, setButtonRedirect] = useState();
  let is_online = props.response.is_online;
  let redirect = props.response.redirect_path;
  useEffect(() => {
    if (props.response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [props.response]);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "nextQuestionSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (state.showQuestionSlide1 == "d-block") {
      if (locUUID) {
        (async () => {
          await saveJournyStats(
            uuid,
            "question",
            "12",
            "",
            "view",
            props.splitName
          );
        })();
      }
    }
  }, [locUUID])

  useEffect(() => {
    let val = null;
    if (clickedSlide) {
      if (clickedSlide.slide === "question_12" && clickedSlide.value === "26") {
        val = { type: "question", id: "10" }
      } else if (clickedSlide.slide === "question_12" && clickedSlide.value === "27") {
        val = { type: "question", id: "9" }
      }

    }

    const slideActions = {
      question_12: val,
      question_9: { type: "button", id: "call_click" },
      question_10: { type: "button", id: "call_click" },
    };

    if (clickedSlide && slideActions[clickedSlide.slide]) {
      const { type, id } = slideActions[clickedSlide.slide];

      (async () => {
        await saveJournyStats(uuid, type, id, "", "view", props.splitName);
      })();
    }
  }, [clickedSlide]);

  const gtmDataLayer = (current_question, element = null) => {
    let question = "";
    let answer = "";
    switch (current_question) {
      case "question_12":
        question = "Ya tienes Medicare o Medicaid?";
        break;
      case "question_9":
        question = "¿Cuál de los siguientes tienes?";
        break;
      case "question_10":
        question = "Tienes un permiso para trabajar en EE.UU?";
        break;
    }
    GTMDataLayer({
      question: question,
      answer: answer,
    });
  };

  const saveJournyfun = async (e, radioName) => {
    //const form = splitForm.current;
    let radio_id = e.target.dataset.question_id;
    var option_id = e.target.value;
    if (radioName == "question_12" || radioName == "question_9" || radioName == "question_10") {
      await saveJournyStats(
        uuid,
        "question",
        radio_id,
        option_id,
        "click",
        props.splitName
      );
    }
  }

  const slideChange = async (e, radioName) => {
    if (e === null) {
      setClickedSlide({ slide: radioName, value: "" });
    } else {
      gtmDataLayer(e.target.name, e.target);
      const form = splitForm.current;
      const values = getValues();
      window.scrollTo(0, 145);
      setClickedSlide({ slide: radioName, value: e.target.value });
      let question_id = radioName;
      let optionId = form[radioName].value;
      let radio_id = e.target.dataset.question_id;
      var option_id = e.target.value;
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = {};
      var newEntries =
        existingEntries == null ? (existingEntries = {}) : existingEntries;
      Object.assign(newEntries, {
        [question_id]: optionId,
      });
      localStorage.setItem("questionData", JSON.stringify(newEntries));
      if (radioName == "question_12" || radioName == "question_9" || radioName == "question_10") {
        await saveJournyStats(
          uuid,
          "question",
          radio_id,
          option_id,
          "click",
          props.splitName
        );
      }

      if (radioName == "question_10" || radioName == "question_9") {
        const formData = values;
        formData.lstSalutation = '';
        formData.txtFName = '';
        formData.txtLName = '';
        formData.txtEmail = '';
        formData.txtPhone = '';
        formData.page_name = props.splitName;
        formSubmit(formData);
 
      }
    }
  }

  const formSubmit = async (formData) => {
    await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      "split_form_submit",
      props.splitName,
      queryString,
      visitorParameters.data
    );
  };
  const handleRedirect = async (click_status, click_value) => {
    await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      props.splitName
    );
    (async () => {
      await saveJournyStats(
        uuid,
        "BUTTON",
        "CALL_CLICK",
        "",
        "click",
        props.splitName
      );
    })();
    window.location.href = buttonRedirect.buttonRedirectionLink;
  };
  return (
    <>
      <Header
        hideHeader={hideHeader}
      />
      <section className="container-fluid form-bg">
        <form ref={splitForm} className="form_slides">
          <div className="col-lg-6 col-12">
            <div className={`animated-arrow col-6 ${hideHeader}`}>
              <img
                src={arrow_down}
                alt=""
                className="animated-arrow-img animg"
              />
            </div>
            <QuestionnaireRadioSlideSpanishSprV5
              showQuestionSlide={state.showQuestionSlide1}
              questionnaire={questionnaire1}
              setModalBody={props.setModalBody}
              saveJournyfun={saveJournyfun}
              slideChange={slideChange}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />
            <QuestionnaireRadioSlideSpanishSprV5
              showQuestionSlide={state.showQuestionSlide2}
              questionnaire={questionnaire2}
              setModalBody={props.setModalBody}
              saveJournyfun={saveJournyfun}
              slideChange={slideChange}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />
            <QuestionnaireRadioSlideSpanishSprV5
              showQuestionSlide={state.showQuestionSlide3}
              questionnaire={questionnaire4}
              setModalBody={props.setModalBody}
              saveJournyfun={saveJournyfun}
              slideChange={slideChange}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
            />

            {state.loader === "d-block" ? <Loader slideChange={slideChange} /> : null}

            {/* ============ THANKYOU SLIDE PAGE =========== */}
            <div
              id="slide_thank"
              className={`slide_thk boxme animate fadeIn five ${state.thank_you}`}
            >
              <div className="form_sec text-center mb-5">
                <p>
                  <span className="tnk_head fw-bold">
                    ¡Felicidades! 🤩🤩🥳🥳🥳
                  </span>
                </p>
                <p className="attribute">
                  Estás precalificado para recibir hasta $7200 en subsidios
                  fiscales para el seguro médico.
                </p>
                <p>
                  Toca el botón de abajo para llamar ahora y reclamar tus
                  beneficios, solo te llevará de 2 a 3 minutitos.
                </p>
                {is_online === "online" && (
                  <CallButtonSpanishSprV5 splitName={props.splitName} />
                )}
                {is_online === "offline" && (
                  <div>
                    <a className="t2c_btn" onClick={(event) => {
                      event.preventDefault();
                      handleRedirect(1, "call_offline");
                    }}>Reclamar Beneficios
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  )
}

export default FormSplitSPR_V5